import { getter } from "@progress/kendo-data-query";
import { getSelectedState } from "@progress/kendo-react-data-tools";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getViewRequest } from "../../userStore/action/viewRequestAction";
import PdfModal from "../modals/PdfModal";
import StonePreviewModal from "../modals/StonePreviewModal";
import StoneVideoPreviewModal from "../modals/StoneVideoPreviewModal";

const DATA_ITEM_KEY = "id";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

function ViewRequestTable(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [imgShow, setImgShow] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [videoShow, setVideoShow] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState("");
  const [certificateShow, setCertificateShow] = useState(false);
  const [selectedCert, setSelectedCert] = useState("");
  const profileData = props.profileReducer.data;

  const [state, setState] = useState({
    items: [],
    // pageable: {
    //   buttonCount: 5,
    //   info: true,
    //   type: "numeric",
    //   pageSizes: true,
    //   previousNext: true,
    // },
    // total: 0,
    // skip: 0,
    // pageSize: 10,
  });

  const [selectedState, setSelectedState] = useState({});

  // const dayList = [
  //   {
  //     id: 0,
  //     value: "Sunday",
  //   },
  //   {
  //     id: 1,
  //     value: "Monday",
  //   },
  //   {
  //     id: 2,
  //     value: "Tuesday",
  //   },
  //   {
  //     id: 3,
  //     value: "Wednesday",
  //   },
  //   {
  //     id: 4,
  //     value: "Thursday",
  //   },
  //   {
  //     id: 5,
  //     value: "Friday",
  //   },
  //   {
  //     id: 6,
  //     value: "Saturday",
  //   },
  // ];

  const [dataState, setDataState] = React.useState(
    state.items.map((dataItem) =>
      Object.assign(
        {
          selected: false,
        },
        dataItem
      )
    )
  );

  useEffect(() => {
    const list = state.items.map((dataItem) =>
      Object.assign(
        {
          selected: false,
        },
        dataItem
      )
    );
    setDataState([...list]);
  }, [state.items]);

  useEffect(() => {
    props.getViewRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.getViewRequestReducer.success) {
      const data = props.getViewRequestReducer.data;
      const list = data.map((e, i) => {
        return {
          image:
            !!e.product?.image && e.product?.image !== ""
              ? e.product?.image
              : "N/A",
          video:
            !!e.product?.video && e.product?.video !== ""
              ? e.product?.video
              : "N/A",
          cert_url:
            !!e.product?.cert_url && e.product?.cert_url !== ""
              ? e.product?.cert_url
              : "N/A",
          stone_id:
            !!e.product?.stone_id && e.product?.stone_id !== ""
              ? e.product?.stone_id
              : "N/A",
          cert:
            !!e.product?.cert && e.product?.cert !== ""
              ? e.product?.cert
              : "N/A",

          shape:
            !!e.product?.shape_name && e.product?.shape_name !== ""
              ? e.product?.shape_name
              : "N/A",
          color:
            !!e.product?.color_name && e.product?.color_name !== ""
              ? e.product?.color_name
              : "N/A",
          // fancy_color:
          //   !!e.product.colors_name && e.product.colors_name !== ""
          //     ? e.product.colors_name
          //     : "N/A",
          Overtone:
            !!e.product.overtone_name && e.product.overtone_name !== ""
              ? e.product.overtone_name
              : "N/A",
          intensity:
            !!e.product.intensity_name && e.product.intensity_name !== ""
              ? e.product.intensity_name
              : "N/A",
          clarity:
            !!e.product?.clarity_name && e.product?.clarity_name !== ""
              ? e.product?.clarity_name
              : "N/A",
          cut:
            !!e.product?.cut_name && e.product?.cut_name !== ""
              ? e.product?.cut_name
              : "N/A",
          polish:
            !!e.product?.polish_name && e.product?.polish_name !== ""
              ? e.product?.polish_name
              : "N/A",
          symmetry:
            !!e.product?.symmetry_name && e.product?.symmetry_name !== ""
              ? e.product?.symmetry_name
              : "N/A",
          rapRate:
            !!e.product?.rapo_rate && e.product?.rapo_rate !== ""
              ? e.product?.rapo_rate
              : "N/A",
          disc:
            !!e.product?.discount && e.product?.discount !== ""
              ? e.product?.discount
              : "N/A",
          carat:
            !!e.product?.carat && e.product?.carat !== ""
              ? e.product?.carat
              : "N/A",
          rate:
            !!e.product.rate && e.product.rate !== "" ? e.product.rate : "N/A",
          amount:
            !!e.product?.amount && e.product?.amount !== ""
              ? e.product?.amount
              : "N/A",
          measurement:
            !!e.product?.measurement && e.product?.measurement !== ""
              ? e.product?.measurement
              : "N/A",
          ratio:
            !!e.product?.ratio && e.product?.ratio !== ""
              ? e.product?.ratio
              : "N/A",
          tabel_per:
            !!e.product?.table_per && e.product?.table_per !== ""
              ? e.product?.table_per
              : "N/A",
          depth_per:
            !!e.product?.depth_per && e.product?.depth_per !== ""
              ? e.product?.depth_per
              : "N/A",
          bgm:
            !!e.product?.bgm_name && e.product?.bgm_name !== ""
              ? e.product?.bgm_name
              : "N/A",
          fluorescence:
            !!e.product?.fluorescence_name &&
              e.product?.fluorescence_name !== ""
              ? e.product?.fluorescence_name
              : "N/A",
          fancy_color:
            !!e.product?.colors_name && e.product?.colors_name !== ""
              ? e.product?.colors_name
              : "N/A",
          key_to_symbols:
            !!e.product?.key_to_symbols && e.product?.key_to_symbols !== ""
              ? e.product?.key_to_symbols
              : "N/A",
          cert_no:
            !!e.product?.cert_no && e.product?.cert_no !== ""
              ? e.product?.cert_no
              : "N/A",
          country:
            !!e.product?.country && e.product?.country !== ""
              ? e.product?.country
              : "N/A",
          growth_type:
            !!e.product?.growth_type && e.product?.growth_type !== ""
              ? e.product?.growth_type
              : "N/A",
          product_id: e.product_id,
          id: e.id,
          selected: false,

          // // SrNo: i + 1,
          // stone_id: e.product.stone_id ? e.product.stone_id : "N/A",
          // cert: e.product.cert ? e.product.cert : "N/A",
          // cert_no: e.product.cert_no ? e.product.cert_no : "N/A",
          // day: dayList?.find((day) => parseInt(day?.id) === e?.slot?.day)
          //   ?.value,
          // from_time: e?.slot?.from_time ? e.slot.from_time : "N/A",
          // to_time: e?.slot?.to_time ? e.slot.to_time : "N/A",
          // cert_url: e.product.cert_url ? e.product.cert_url : "N/A",
          // diamond_type_name: e.product.diamond_type_name
          //   ? e.product.diamond_type_name
          //   : "N/A",
          // image: e.product.image ? e.product.image : "N/A",
          // video: e.product.video ? e.product.video : "N/A",
          // amount: e.product.amount ? e.product.amount : "N/A",
          // rapRate: e.product.rapo_rate ? e.product.rapo_rate : "N/A",
          // disc: e.product.discount ? e.product.discount : "N/A",
          // shape: e.product.shape_name ? e.product.shape_name : "N/A",
          // product_id: e.product_id ? e.product_id : "N/A",
          // id: e.id,
          // selected: false,

          // // size: e.product.size_name ? e.product.size_name : "N/A",
          // carat: e.product.carat ? e.product.carat : "N/A",
          // color: e.product.color_name ? e.product.color_name : "N/A",

          // clarity: e.product.clarity_name ? e.product.clarity_name : "N/A",
          // cut: e.product.cut_name ? e.product.cut_name : "N/A",
          // polish: e.product.polish_name ? e.product.polish_name : "N/A",
          // symmetry: e.product.symmetry_name ? e.product.symmetry_name : "N/A",
          // fluorescence: e.product.fluorescence_name
          //   ? e.product.fluorescence_name
          //   : "N/A",
          // Table: e.product.table ? e.product.table : "N/A",
          // depth: e.product.depth ? e.product.depth : "N/A",
          // measurement: e.product.measurement ? e.product.measurement : "N/A",
          // ratio: e.product.ratio ? e.product.ratio : "N/A",
          // length: e.product.length ? e.product.length : "N/A",
          // width: e.product.width ? e.product.width : "N/A",
          // table: e.product.table_per ? e.product.table_per : "N/A",
          // depth_pr: e.product.depth_per ? e.product.depth_per : "N/A",
          // city: e.product.city ? e.product.city : "N/A",
          // country: e.product.country ? e.product.country : "N/A",
          // rapamount: e.product.rapo_amount ? e.product.rapo_amount : "N/A",
        };
      });
      setState((pre) => ({
        ...pre,
        items: list,
      }));
    }
  }, [props.getViewRequestReducer]);

  useEffect(() => {
    props.setSelected(selectedState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedState]);

  useEffect(() => {
    if (props.deleteViewRequestReducer.success) {
      setSelectedState({});
    }
  }, [props.deleteViewRequestReducer]);

  const CustomCell = (cellProps) => {
    return (
      <td
        className="k-grid-content-sticky"
        style={{ left: "50px", right: "0px" }}
      >
        <button
          className="btn btn-sm p-0 me-2"
          disabled={cellProps.dataItem.image === "N/A"}
          onClick={() => {
            setSelectedImage(cellProps.dataItem.image);
            setImgShow(true);
          }}
        >
          <i className="fa-solid fa-camera-retro"></i>
        </button>
        <button
          className="btn btn-sm p-0 me-2"
          disabled={cellProps.dataItem.video === "N/A"}
          onClick={() => {
            setSelectedVideo(cellProps.dataItem.video);
            setVideoShow(true);
          }}
        >
          <i className="fa-solid fa-video"></i>
        </button>
        <button
          className="btn btn-sm p-0 me-2"
          disabled={cellProps.dataItem.cert_url === "N/A"}
          onClick={() => {
            setSelectedCert(cellProps.dataItem.cert_url);
            setCertificateShow(true);
          }}
        >
          <i className="fa-solid fa-certificate"></i>
        </button>
        <div
          className="btn btn-sm p-0"
          onClick={() => {
            navigate(`/detail/${cellProps.dataItem.product_id}`, {
              state: {
                path: location.pathname,
              },
            });
          }}
        >
          <i className="fa-solid fa-eye"></i>
        </div>
      </td>
    );
  };

  const MyCustomCell = (props) => <CustomCell {...props} />;

  // const pageChange = (event) => {
  //   setState((pre) => ({
  //     ...pre,
  //     items: pre.items.slice(
  //       event.page.skip,
  //       event.page.skip + event.page.take
  //     ),
  //     skip: event.page.skip,
  //     pageSize: event.page.take,
  //   }));
  // };

  const onSelectionChange = React.useCallback(
    (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });
      setSelectedState(newSelectedState);
    },
    [selectedState]
  );
  const onHeaderSelectionChange = React.useCallback((event) => {
    const checkboxElement = event.syntheticEvent.target;
    const checked = checkboxElement.checked;
    const newSelectedState = {};
    event.dataItems.forEach((item) => {
      newSelectedState[idGetter(item)] = checked;
    });
    setSelectedState(newSelectedState);
  }, []);

  const grid = (
    <Grid
      style={{
        height: "70vh",
      }}
      data={dataState.map((item) => ({
        ...item,
        [SELECTED_FIELD]: selectedState[idGetter(item)],
      }))}
      // skip={state.skip}
      // take={state.take}
      // total={state.total}
      // pageable={state.pageable}
      // pageSize={state.pageSize}
      // onPageChange={pageChange}
      dataItemKey={DATA_ITEM_KEY}
      selectedField={SELECTED_FIELD}
      selectable={{
        enabled: true,
        drag: false,
        cell: false,
        mode: "multiple",
      }}
      onSelectionChange={onSelectionChange}
      onHeaderSelectionChange={onHeaderSelectionChange}
    >
      {/* <GridColumn field="view">sldkjklf</GridColumn> */}
      <GridColumn
        locked={true}
        field={SELECTED_FIELD}
        width="50"
        headerSelectionValue={
          dataState.length === 0
            ? false
            : dataState.findIndex((item) => !selectedState[idGetter(item)]) ===
            -1
        }
      />
      <GridColumn
        field="Action"
        width="101"
        locked={true}
        cell={MyCustomCell}
      />

      <GridColumn field="stone_id" title="Stone ID" width="75" />
      <GridColumn field="cert_no" title="Certificate No" width="105" />

      <GridColumn field="shape" title="Shape" width="55" />
      <GridColumn field="carat" title="Carat" width="45" />
      <GridColumn field="color" title="Color" width="45" />
      <GridColumn field="fancy_color" title="Fancy Color" width="90" />
      <GridColumn field="intensity" title="Intensity" width="70" />
      <GridColumn field="Overtone" title="Overtone" width="70" />
      <GridColumn field="clarity" title="Clarity" width="55" />
      <GridColumn field="cut" title="Cut" width="40" />
      <GridColumn field="polish" title="Poilish" width="55" />
      <GridColumn field="symmetry" title="Symmetry" width="80" />
      <GridColumn field="rapRate" title="Rap Rate" width="70" />
      <GridColumn field="disc" title="Discount" width="70" />
      <GridColumn field="rate" title="$/CT" width="45" />
      <GridColumn field="amount" title="$ Total" width="65" />
      <GridColumn field="measurement" title="Measurement" width="105" />
      <GridColumn field="ratio" title="Ratio" width="50" />
      <GridColumn field="depth_per" title="Depth Per" width="78" />
      <GridColumn field="tabel_per" title="Table Per" width="70" />
      <GridColumn field="bgm" title="BGM" width="50" />
      <GridColumn field="fluorescence" title="Fluorescence" width="100" />
      {!!profileData && profileData.diamond_type === 1 && (
        <GridColumn field="key_to_symbols" title="Key of Symbols" width="150" />
      )}
      <GridColumn field="cert" title=" Certificate" width="80" />
      <GridColumn field="country" title="Country" width="65" />
      {!!profileData && profileData.diamond_type === 2 && (
        <GridColumn field="growth_type" title="Growth Type" width="95" />
      )}

      {/* <GridColumn field="SrNo" title="SrNo" width="75" /> 
       <GridColumn field="cert" title=" Certificate" width="100" />
      <GridColumn field="stone_id" title="Stone ID" width="110" />
      <GridColumn field="cert_no" title="Certificate No" width="130" />
       <GridColumn field="size" title="Size" width="90" /> 
      <GridColumn field="diamond_type_name" title="Diamond Type" width="130" />
      <GridColumn field="carat" title="Carat" width="70" />
      <GridColumn field="shape" title="Shape" width="80" />
      <GridColumn field="color" title="Color" width="80" />
      <GridColumn field="fancy_color" title="Fancy Color" width="110" />
      
      <GridColumn field="clarity" title="Clarity" width="80" />
      <GridColumn field="cut" title="Cut" width="80" />
      <GridColumn field="polish" title="Poilish" width="80" />
      <GridColumn field="symmetry" title="Symmetry" width="100" />
      <GridColumn field="fluorescence" title="Fluorescence" width="120" />
      <GridColumn field="rapRate" title="Rap Rate" width="100" />
      <GridColumn field="disc" title="Discount" width="100" />
      <GridColumn field="amount" title="Amount" width="90" />
      <GridColumn field="Table" title="table" width="80" />
      <GridColumn field="depth" title="Depth" width="80" />
      <GridColumn field="measurement" title="Measurement" width="140" />
      <GridColumn field="ratio" title="Ratio" width="80" />
      <GridColumn field="length" title="Length" width="80" />
      <GridColumn field="width" title="Width" width="80" />
      <GridColumn field="table" title="Table %" width="80" />
      <GridColumn field="depth_pr" title="Depth %" width="100" />
      <GridColumn field="city" title="City" width="80" />
      <GridColumn field="country" title="Country" width="90" />
      <GridColumn field="rapamount" title="Rap Amount" width="120" />
      <GridColumn field="day" title="Day" width="80" />
      <GridColumn field="from_time" title="From" width="80" />
      <GridColumn field="to_time" title="To" width="80" />*/}
    </Grid>
  );

  return (
    <>
      {grid}
      {imgShow && (
        <StonePreviewModal
          show={imgShow}
          onHide={() => {
            setImgShow(false);
            setSelectedImage("");
          }}
          details={selectedImage}
        />
      )}
      {videoShow && (
        <StoneVideoPreviewModal
          show={videoShow}
          onHide={() => {
            setVideoShow(false);
            setSelectedVideo("");
          }}
          details={selectedVideo}
        />
      )}
      {certificateShow && (
        <PdfModal
          show={certificateShow}
          onHide={() => {
            setCertificateShow(false);
            setSelectedCert("");
          }}
          details={selectedCert}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  getViewRequestReducer: state.viewRequestReducer.getViewRequest,
  deleteViewRequestReducer: state.viewRequestReducer.deleteViewRequest,
  profileReducer: state.authReducer.profile,
});
const mapDispatchToProps = (dispatch) => ({
  getViewRequest: () => dispatch(getViewRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewRequestTable);
