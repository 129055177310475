import axios from "axios";
import { starmeleeCarat_Url, starmeleeDiamond_Url, starmeleeInquairyList_Url, starmeleeInquairy_Url, starmeleePrice_Url } from "../common/apiSheet";
import { ADD_STARMELEE_CARAT_ERROR, ADD_STARMELEE_CARAT_SUCCESS, ADD_STARMELEE_INQUAIRY_ERROR, ADD_STARMELEE_INQUAIRY_SUCCESS, ADD_STARMELEE_PRICE_ERROR, ADD_STARMELEE_PRICE_SUCCESS, GET_STARMELEE_DIAMONDS_ERROR, GET_STARMELEE_DIAMONDS_SUCCESS, GET_STARMELEE_INQUAIRY_LIST_ERROR, GET_STARMELEE_INQUAIRY_LIST_SUCCESS } from "../common/constant";

export const getstarmeleeDiamondAction = () => {
    return (dispatch) => {
      const requestOptions = {
        method: "GET",
        url: starmeleeDiamond_Url,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
      }
      axios(requestOptions)
        .then((resp) => {
          // localStorage.setItem('token', resp?.data.data.token)
          dispatch({
            type: GET_STARMELEE_DIAMONDS_SUCCESS,
            response: resp.data.data.result,
          });
        })
        .catch((error) => {
          let data = error.response;
          if (!error.response) {
            dispatch({
              type: GET_STARMELEE_DIAMONDS_ERROR,
              msg: error,
            });
          } else if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: GET_STARMELEE_DIAMONDS_ERROR,
              msg: validationError,
            });
          }
        });
    };
  };

  
export const starmeleecaratAction = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      url: starmeleeCarat_Url,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          data:resp.data.data.result,
          type: ADD_STARMELEE_CARAT_SUCCESS,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: ADD_STARMELEE_CARAT_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.error.message;
          dispatch({
            type: ADD_STARMELEE_CARAT_ERROR,
            msg: validationError,
          });
        }
      });
  };
};


export const starmeleepriceAction = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      url: starmeleePrice_Url,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          data:resp.data.data.result,
          type: ADD_STARMELEE_PRICE_SUCCESS,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: ADD_STARMELEE_PRICE_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.error.message;
          dispatch({
            type: ADD_STARMELEE_PRICE_ERROR,
            msg: validationError,
          });
        }
      });
  };
};


export const starmeleeinquairyAction = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      url: starmeleeInquairy_Url,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          data:resp.data.data.result,
          type: ADD_STARMELEE_INQUAIRY_SUCCESS,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: ADD_STARMELEE_INQUAIRY_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.error.message;
          dispatch({
            type: ADD_STARMELEE_INQUAIRY_ERROR,
            msg: validationError,
          });
        }
      });
  };
};

export const starmeleeinquairyListAction = () => {
  return (dispatch) => {
    const requestOptions = {
      method: "GET",
      url: starmeleeInquairyList_Url,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      }
    }
    axios(requestOptions)
      .then((resp) => {
        // localStorage.setItem('token', resp?.data.data.token)
        dispatch({
          type: GET_STARMELEE_INQUAIRY_LIST_SUCCESS,
          response: resp.data.data.result,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_STARMELEE_INQUAIRY_LIST_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: GET_STARMELEE_INQUAIRY_LIST_ERROR,
            msg: validationError,
          });
        }
      });
  };
};