import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { connect, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { deleteConfirmStoneList, getConfirmStoneList } from "../userStore/action/confirmStoneAction";
import { exportExcel, exportPdf } from "../userStore/action/exportFileAction";
import { DELETE_CONFIRM_STONE_LIST_RESET, EXPORT_EXCEL_RESET, EXPORT_PDF_RESET } from "../userStore/common/constant";
import ConfirmStoneTable from "./Tables/ConfirmStoneTable";
import Footer from "./userLayout/Footer";
import Header from "./userLayout/Header";
import { abbreviateNumber } from "../userStore/common/helper";
import { Button } from "react-bootstrap";
import { Tooltip } from 'react-tooltip'
const ConfirmStone = (props) => {
  const [selected, setSelected] = useState({});
  const [details, setDetails] = useState([]);

  const diamondType = useSelector(
    (state) => state?.authReducer?.profile?.data?.diamond_type
  );

  useEffect(() => {
    if (Object.keys(selected).length !== 0) {
      const data = props.ConfirmStoneListReducer.data;
      const idSelected = Object.keys(selected).filter((e) => {
        if (selected[e] === true) {
          return e;
        } else return false
      });
      const _selected = idSelected.map((e) => {
        const list = data.find((data) => parseInt(data.id) === parseInt(e));
        return list;
      });
      setDetails([..._selected]);
    } else {
      setDetails([]);
    }
  }, [selected, props.ConfirmStoneListReducer.data]);

  const handleExportPdf = () => {
    if (props.ConfirmStoneListReducer.success) {
      const data = props.ConfirmStoneListReducer.data;
      const idSelected = Object.keys(selected).filter((e) => {
        if (selected[e] === true) {
          return e;
        } else return false
      });
      const stonIds = idSelected.map((e) => {
        const list = data.find((data) => parseInt(data.id) === parseInt(e))
          .product.stone_id;
        return list;
      });
      if (stonIds.length !== 0) {
        props.exportPdf({
          details: stonIds,
          dt: diamondType,
        });
      } else {
        toast.error("Please Select list..");
      }
      props.resetExportPdf();
    }
  };

  useEffect(() => {
    if (props.exportPdfReducer.success) {
      // const data = props.exportPdfReducer.data;
    }
  }, [props.exportPdfReducer]);

  const handleExportExcel = () => {
    if (props.ConfirmStoneListReducer.success) {
      const data = props.ConfirmStoneListReducer.data;
      const idSelected = Object.keys(selected).filter((e) => {
        if (selected[e] === true) {
          return e;
        } else return false
      });
      const stonIds = idSelected.map((e) => {
        const list = data.find((data) => parseInt(data.id) === parseInt(e))
          .product.stone_id;
        return list;
      });
      if (stonIds.length !== 0) {
        props.exportExcel({
          details: stonIds,
          dt: diamondType,
        });
      } else {
        toast.error("Please Select list..");
      }
      props.resetExportExcel();
    }
  };
  return (
    <>
      <Header />
      <main className="userMain">
        <div className="d-flex justify-content-between  mt-5 mb-4 flex-wrap">
          <h2 className="heading mb-0"> Confirm Stone List</h2>
        </div>

        <div className="content-block">
          {/* Info blocks */}
          <div className="row g-1 g-lg-3 mb-4 mt-lg-0 mt-3">
            <div className="col-lg-3 col-md-6">
              <div className="info-block">
                <h6 className="mb-lg-3 text-primary"> Total Cts</h6>
                <h2 className="mb-0">
                  {details?.length === 0
                    ? 0
                    : details
                      .map((e) => e.product.carat)
                      .reduce((a, b) => a + b)
                      .toFixed(2)}
                </h2>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="info-block">
                <h6 className="mb-lg-3 text-primary"> $/Carat</h6>
                <h2 className="mb-0">
                  {details.length === 0
                    ? 0
                    : `$
                      ${abbreviateNumber(
                      (
                        details
                          .map((e) => e.product.amount)
                          .reduce((a, b) => parseFloat(a) + parseFloat(b)) /
                        details
                          .map((e) => e.product.carat)
                          .reduce((a, b) => parseFloat(a) + parseFloat(b))
                      ).toFixed(2)
                    )}`}
                </h2>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="info-block">
                <h6 className="mb-lg-3 text-primary"> Average Discount</h6>
                <h2 className="mb-0">
                  {details.length === 0
                    ? 0
                    : `
                      ${(
                      details
                        .map((e) => e.product.discount)
                        .reduce((a, b) => parseFloat(a) + parseFloat(b)) /
                      details.length
                    ).toFixed(2)} %`}
                </h2>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="info-block ">
                <h6 className="mb-lg-3 text-primary"> Total Amount</h6>
                <h2 className="mb-0">
                  {details.length === 0
                    ? 0
                    : `$
                      ${abbreviateNumber(
                      details
                        .map((e) => e.product.amount)
                        .reduce((a, b) => parseFloat(a) + parseFloat(b))
                        .toFixed(2)
                    )} `}
                </h2>
              </div>
            </div>
          </div>
          <div className="mb-3 d-flex justify-content-end align-items-center navigate-buttons">

            <Button
              variant="secondary"
              className="me-sm-2 me-1 tooltip-labels"
              data-tooltip-content="Export PDF"
              onClick={handleExportPdf}
            >
              <i className="fa-solid fa-file-pdf"></i>{" "}
            </Button>
            <Button
              variant="secondary"
              className="me-sm-2 me-1 tooltip-labels"
              data-tooltip-content="Export Excel"
              onClick={handleExportExcel}
            >
              <i className="fa-solid fa-file-excel"></i>
            </Button>
            <Tooltip anchorSelect=".tooltip-labels" />
          </div>
          <ConfirmStoneTable setSelected={setSelected} />
          {/* <InquiryTable /> */}
        </div>
      </main>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => ({
  deleteConfirmStoneReducer: state.confirmStoneReducer.deleteConfirmStone,
  ConfirmStoneListReducer: state.confirmStoneReducer.ConfirmStoneList,
  exportPdfReducer: state.exportFileReducer.exportPdf,
});
const mapDispatchToProps = (dispatch) => ({
  getConfirmStoneList: () => dispatch(getConfirmStoneList()),
  deleteConfirmStoneList: (details) =>
    dispatch(deleteConfirmStoneList(details)),
  exportPdf: (details) => dispatch(exportPdf(details)),
  exportExcel: (details) => dispatch(exportExcel(details)),
  resetDeleteConfirmStone: () =>
    dispatch({ type: DELETE_CONFIRM_STONE_LIST_RESET }),
  resetExportPdf: () => dispatch({ type: EXPORT_PDF_RESET }),
  resetExportExcel: () => dispatch({ type: EXPORT_EXCEL_RESET }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmStone);
