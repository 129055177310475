import { ADD_CART_LIST_ERROR, ADD_CART_LIST_RESET, ADD_CART_LIST_SUCCESS, DELETE_CART_LIST_ERROR, DELETE_CART_LIST_RESET, DELETE_CART_LIST_SUCCESS, GET_CART_LIST_ERROR, GET_CART_LIST_RESET, GET_CART_LIST_SUCCESS } from "../common/constant";

let initialState = {
  cartList: {
    error: false,
    data: null,
    errors: null,
    success: false,
  },
  createCart: {
    error: false,
    errors: null,
    success: false,
    msg: null,
    count: 0
  },
  deleteCart: {
    error: false,
    errors: null,
    success: false,
  }
};

export default function cartReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CART_LIST_SUCCESS:
      return { ...state, cartList: { ...state.cartList, success: true, error: false, errors: null, data: action.response }, };

    case GET_CART_LIST_ERROR:
      return { ...state, cartList: { ...state.cartList, success: false, error: true, errors: action.msg } };

    case GET_CART_LIST_RESET:
      return { ...state, cartList: { ...state.cartList, success: false, error: false, errors: null } };

    //  add

    case ADD_CART_LIST_SUCCESS:
      return { ...state, createCart: { ...state.createCart, success: true, error: false, errors: null, msg: action.msg, count: action.count }, };

    case ADD_CART_LIST_ERROR:
      return { ...state, createCart: { ...state.createCart, success: false, error: true, errors: action.msg } };

    case ADD_CART_LIST_RESET:
      return { ...state, createCart: { ...state.createCart, success: false, error: false, errors: null } };



    //  delete

    case DELETE_CART_LIST_SUCCESS:
      return { ...state, deleteCart: { ...state.deleteCart, success: true, error: false, errors: null }, };

    case DELETE_CART_LIST_ERROR:
      return { ...state, deleteCart: { ...state.deleteCart, success: false, error: true, errors: action.msg } };

    case DELETE_CART_LIST_RESET:
      return { ...state, deleteCart: { ...state.deleteCart, success: false, error: false, errors: null } };

    default:
      return state;
  }
}