import React, { useEffect, useState } from "react";
import { getSelectedState, Grid, GridColumn } from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-data-query";
import { connect } from "react-redux";
import {  deleteAppointemnt,  getAppointemnt} from "../../userStore/action/appointmentAction";
import { Button, Modal, } from "react-bootstrap";
import { toast } from "react-toastify";
const DATA_ITEM_KEY = "id";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const AppointmentTable = (props) => {
  const [isDelete, setIsDelete] = useState(false);
  const [isDeleteId, setIsDeleteId] = useState(null);
  const [state, setState] = useState({
    items: [],
    pageable: {
      buttonCount: 5,
      info: true,
      type: "numeric",
      pageSizes: true,
      previousNext: true,
    },
    total: 0,
    skip: 0,
    pageSize: 10,
  });
  const [selectedState, setSelectedState] = useState({});
  const [dataState, setDataState] = React.useState(
    state.items.map((dataItem) =>
      Object.assign(
        {
          selected: false,
        },
        dataItem
      )
    )
  );

  useEffect(() => {
    const list = state.items.map((dataItem) =>
      Object.assign(
        {
          selected: false,
        },
        dataItem
      )
    );
    setDataState([...list]);
  }, [state.items]);

  useEffect(() => {
    if (!props.getAppointmentReducer.success) {
      props.getAppointemnt();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.getAppointmentReducer.success) {
      const data = props.getAppointmentReducer.data;
      const list = data.map((e, i) => {
        return {
          // SrNo: i + 1,
          date: e.date,
          time: e.time,
          remark: e.remark,
          id: e.id,
          selected: false,
        };
      });
      setState((pre) => ({
        ...pre,
        items: list.slice(pre.skip, pre.skip + pre.pageSize),
        total: list.length,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.getAppointmentReducer]);

  useEffect(() => {
    props.setSelected(selectedState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedState]);

  const CustomCell = (cellProps) => {
    return (
      <td
        className="k-grid-content-sticky"
        style={{ left: "50px", right: "0px" }}
      >
        <button
          className="btn btn-sm p-0 me-2"
          onClick={() => {
            setIsDeleteId([cellProps.dataItem.id]);
            setIsDelete(true);
          }}
        >
          <i className="fa-solid fa-trash"></i>
        </button>
      </td>
    );
  };

  const MyCustomCell = (props) => <CustomCell {...props} />;

  const pageChange = (event) => {
    setState((pre) => ({
      ...pre,
      items: pre.items.slice(
        event.page.skip,
        event.page.skip + event.page.take
      ),
      skip: event.page.skip,
      pageSize: event.page.take,
    }));
  };

  const onSelectionChange = React.useCallback(
    (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });
      setSelectedState(newSelectedState);
    },
    [selectedState]
  );
  const onHeaderSelectionChange = React.useCallback((event) => {
    const checkboxElement = event.syntheticEvent.target;
    const checked = checkboxElement.checked;
    const newSelectedState = {};
    event.dataItems.forEach((item) => {
      newSelectedState[idGetter(item)] = checked;
    });
    setSelectedState(newSelectedState);
  }, []);

  const handleDelete = () => {
    props.deleteAppointemnt({
      appointment_id: JSON.stringify(isDeleteId),
    });
  };

  useEffect(() => {
    if (props.deleteAppointmentReducer.success) {
      props.getAppointemnt();
      toast.success("Selected appointment deleted Successfully..");
      setIsDelete(false);
      setIsDeleteId(null);
      // props?.resetDeleteAppointment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.deleteAppointmentReducer]);

  const grid = (
    <Grid
      style={{
        height: "70vh",
      }}
      data={dataState.map((item) => ({
        ...item,
        [SELECTED_FIELD]: selectedState[idGetter(item)],
      }))}
      skip={state.skip}
      take={state.take}
      total={state.total}
      pageable={state.pageable}
      pageSize={state.pageSize}
      onPageChange={pageChange}
      dataItemKey={DATA_ITEM_KEY}
      selectedField={SELECTED_FIELD}
      selectable={{
        enabled: true,
        drag: false,
        cell: false,
        mode: "multiple",
      }}
      onSelectionChange={onSelectionChange}
      onHeaderSelectionChange={onHeaderSelectionChange}
    >
      {/* <GridColumn field="view">sldkjklf</GridColumn> */}
      <GridColumn
        locked={true}
        field={SELECTED_FIELD}
        width="50px"
        headerSelectionValue={
          dataState.length === 0
            ? false
            : dataState.findIndex((item) => !selectedState[idGetter(item)]) ===
            -1
        }
      />
      <GridColumn field="Action" width="90" locked={true} cell={MyCustomCell} />
      {/* <GridColumn field="date" title="Date" width="200" />
      <GridColumn field="time" title=" Time" width="200" /> */}
      <GridColumn field="date" title="Date" width="500" />
      <GridColumn field="time" title=" Time" width="500" />
      <GridColumn field="remark" title="Remark" width="500" />
    </Grid>
  );

  return (
    <>
      {grid}
      <Modal
        show={isDelete}
        size="md"
        backdrop="static"
        onHide={() => setIsDelete(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="p-4">
          <div className="">
            <div className="text-center my-5">
              <h5>Are you sure you want to delete appointment ?</h5>
            </div>
            <Modal.Footer className="mt-3">
              <Button
                className="py-2 px-4 border rounded pointer bg-secondary text-white mx-2 pointer"
                onClick={() => {
                  setIsDelete(false);
                  setIsDeleteId(null);
                }}
              >
                Cancel
              </Button>
              <Button
                className="py-2 px-4 border rounded pointer bg-primary text-white mx-2 pointer"
                onClick={handleDelete}
              >
                Delete Appointment
              </Button>
            </Modal.Footer>
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  getAppointmentReducer: state.appointmentReducer.getAppointment,
  deleteAppointmentReducer: state.appointmentReducer.deleteAppointment,
});
const mapDispatchToProps = (dispatch) => ({
  getAppointemnt: () => dispatch(getAppointemnt()),
  deleteAppointemnt: (details) => dispatch(deleteAppointemnt(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentTable);
