import {
  GET_SEARCH_STONE_ERROR,
  GET_SEARCH_STONE_RESET,
  GET_SEARCH_STONE_SUCCESS,
} from "../common/constant";

let initialState = {
  Search: {
    error: false,
    data: null,
    errors: null,
    success: false,
  },
};

export default function SerachStoneReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SEARCH_STONE_SUCCESS:
      return {
        ...state,
        Search: {
          ...state.Search,
          success: true,
          error: false,
          errors: null,
          data: action.data,
        },
      };

    case GET_SEARCH_STONE_ERROR:
      return {
        ...state,
        Search: {
          ...state.Search,
          success: false,
          error: true,
          errors: action.msg,
        },
      };

    case GET_SEARCH_STONE_RESET:
      return {
        ...state,
        Search: {
          ...state.Search,
          success: false,
          error: false,
          errors: null,
        },
      };

    default:
      return state;
  }
}
