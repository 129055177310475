import React, { useEffect, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import videoUrl from "../assets/production ID_5106444.mp4";
import { connect } from "react-redux";
import {
  forgotPassword,
  updateForgotPassword,
  verifyOtp,
} from "../userStore/action/authAction";
import { toast } from "react-toastify";
import {
  FORGOT_PASSWORD_RESET,
  UPDATE_FORGOT_PASSWORD_RESET,
  VERIFY_OTP_RESET,
} from "../userStore/common/constant";
import OTPInput from "react-otp-input";

const ForgotPassword = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    email: "",
    otp: "",
    password: "",
    confirm_password: "",
  });
  const [error, setError] = useState({
    email: false,
    otp: false,
    password: false,
    confirm_password: false,
  });

  const [isOtpGet, setIsOtpGet] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [showConPass, setShowConPass] = useState(false);
  const [isSetPassword, setIsSetPassword] = useState(false);

  useEffect(() => {
    if (props.forgotPassReducer.success) {
      setIsOtpGet(true);
      setLoading(false);
      setIsSetPassword(false);
    }
    if (props.forgotPassReducer.error) {
      toast.error(props.forgotPassReducer.errors);
      setError((prevState) => ({
        ...prevState,
        email: "Enter Valid Email",
      }));
      setLoading(false);
    }
  }, [props.forgotPassReducer]);

  useEffect(() => {
    if (props.verifyOtpReducer.success) {
      setLoading(false);
      setIsOtpGet(false);
      setIsSetPassword(true);
    } else if (props.verifyOtpReducer.error) {
      toast.error(props.verifyOtpReducer.errors);
      setError((prevState) => ({
        ...prevState,
        otp: "Enter Valid OTP",
      }));
      setLoading(false);
    }
  }, [props.verifyOtpReducer]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const validEmailRegex = RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));

    switch (name) {
      case "email":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            email: "Required",
          }));
        } else if (!validEmailRegex.test(value)) {
          setError((prevState) => ({
            ...prevState,
            email: "Enter Valid Email",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            email: false,
          }));
        }
        break;

      case "otp":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            otp: "Required",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            otp: false,
          }));
        }
        break;

      case "password":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            password: "Required",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            password: false,
          }));
        }
        break;

      case "confirm_password":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            confirm_password: "Required",
          }));
        } else if (value !== state.password) {
          setError((prevState) => ({
            ...prevState,
            confirm_password: "Password not matched",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            confirm_password: false,
          }));
        }
        break;

      default:
        break;
    }
  };

  const handleSendOtp = (e) => {
    e.preventDefault();
    if (state.email === "") {
      setError((prevState) => ({
        ...prevState,
        email: "Enter email",
      }));
    }

    if (state.email !== "" && !error.email) {
      props.forgotPassword({ email: state.email });
      setLoading(true);
    }
  };

  const handleVerifyOtp = (e) => {
    e.preventDefault();

    if (state.email === "") {
      setError((prevState) => ({
        ...prevState,
        email: "Enter email",
      }));
    } else if (state.otp === "") {
      setError((prevState) => ({
        ...prevState,
        otp: "Enter Otp",
      }));
    }

    if (state.email !== "" && state.otp !== "" && !error.email && !error.otp) {
      const details = {
        email: state.email,
        otp: state.otp,
      };
      props.verifyOtp(details);
      setLoading(true);
    }
  };

  const handleUpdatePassword = (e) => {
    e.preventDefault();
    if (state.password === "") {
      setError((prevState) => ({
        ...prevState,
        password: "Enter Password",
      }));
    } else if (state.confirm_password === "") {
      setError((prevState) => ({
        ...prevState,
        confirm_password: "Enter Confirm password",
      }));
    }

    if (state.confirm_password !== state.password) {
      toast.error("Password and Confirm Password not match");
      setError((prevState) => ({
        ...prevState,
        confirm_password: "Confirm Password Not Match",
      }));
    } else {
      if (
        state.email !== "" &&
        state.otp !== "" &&
        state.password !== "" &&
        !error.email &&
        !error.otp &&
        !error.password
      ) {
        const details = {
          email: state.email,
          password: state.password,
        };
        props.updateForgotPassword(details);
        setLoading(true);
      }
    }
  };

  useEffect(() => {
    if (props.updateForgotPassReducer.success) {
      setLoading(false);
      toast.success("Password reset Successfully...");
      navigate("/login");
      props.resetupdatepass();
      props.resetVerifyOtp();
      props.resetForgotPass();
    }
  }, [props.updateForgotPassReducer]);

  return (
    <div className="auth-bg">
      <video src={videoUrl} autoPlay muted loop />
      <div className="auth-form">
        <Form autoComplete="off">
          {!isOtpGet && !isSetPassword && (
            <Form.Group className="mb-3">
              <h2 className="text-center">Forgot Your Password?</h2>
              <p>
                Please enter your email address. <br />
                You will receive a link to create new password via email.
              </p>
              <Form.Label className="mb-0">Email *</Form.Label>
              <Form.Control
                type="email"
                value={state.email}
                name="email"
                className="w-100"
                placeholder=""
                onChange={handleChange}
              />
              {error.email && <span className="form-error">{error.email}</span>}
            </Form.Group>
          )}

          {isOtpGet && !isSetPassword && (
            <Form.Group className="mb-3">
              {/* <Form.Label className="mb-0">OTP *</Form.Label>
              <Form.Control
                type="text"
                value={state.otp}
                name="otp"
                className="w-100"
                placeholder=""
                onChange={handleChange}
              />
              {error.otp && <span className="form-error">{error.otp}</span>} */}
              <h2 className="text-center">OTP Verification</h2>
              <p>
                Enter 4 digit verification code <br />
                sent to your Email Address.
              </p>
              <OTPInput
                value={state.otp}
                onChange={(value) =>
                  setState((prev) => ({
                    ...prev,
                    otp: value,
                  }))
                }
                numInputs={4}
                inputType="number"
                inputStyle="optInputs form-control"
                renderInput={(props) => <input {...props} />}
              />
            </Form.Group>
          )}

          {/* passworc */}
          {isSetPassword && !isOtpGet && (
            <Form.Group className="mb-3">
              <h2 className="text-center">Reset Password</h2>
              <p>Please enter New Password</p>
              <Form.Label className="mb-0">Password *</Form.Label>
              <div className="position-relative">
                <Form.Control
                  type={showPass ? "text" : "password"}
                  value={state.password}
                  name="password"
                  className="w-100"
                  placeholder=""
                  onChange={handleChange}
                />
                <i
                  className={`eye-btn position-absolute fa-regular ${
                    showPass ? "fa-eye-slash" : "fa-regular fa-eye"
                  }`}
                  onClick={() => setShowPass(!showPass)}
                  style={{
                    cursor: "pointer",
                    top: "17px",
                    right: "20px",
                  }}
                ></i>
              </div>
              {error.password && (
                <span className="form-error">{error.password}</span>
              )}
            </Form.Group>
          )}
          {isSetPassword && !isOtpGet && (
            <Form.Group className="mb-3">
              <Form.Label className="mb-0">Confirm Password *</Form.Label>
              <div className="position-relative">
                <Form.Control
                  type={showConPass ? "text" : "password"}
                  value={state.confirm_password}
                  name="confirm_password"
                  className="w-100"
                  placeholder=""
                  onChange={handleChange}
                />
                <i
                  className={`eye-btn position-absolute fa-regular ${
                    showConPass ? "fa-eye-slash" : "fa-regular fa-eye"
                  }`}
                  onClick={() => setShowConPass(!showConPass)}
                  style={{
                    cursor: "pointer",
                    top: "17px",
                    right: "20px",
                  }}
                ></i>
              </div>
              {error.confirm_password && (
                <span className="form-error">{error.confirm_password}</span>
              )}
            </Form.Group>
          )}

          {!isOtpGet && !isSetPassword && (
            <Button className="mt-5" onClick={handleSendOtp}>
              {loading ? (
                <Spinner
                  as="span"
                  animation="border"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "send OTP"
              )}
            </Button>
          )}
          {isOtpGet && !isSetPassword && (
            <Button className="mt-5" onClick={handleVerifyOtp}>
              {loading ? (
                <Spinner
                  as="span"
                  animation="border"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "verify OTP"
              )}
            </Button>
          )}
          {!isOtpGet && isSetPassword && (
            <Button className="mt-5" onClick={handleUpdatePassword}>
              {loading ? (
                <Spinner
                  as="span"
                  animation="border"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "reset Password"
              )}
            </Button>
          )}
        </Form>
        <div className="text-center small border-top mt-4 pt-3">
          <div
            className="text-primary ms-2 fw-semibold"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/login");
              setIsSetPassword(false);
              setIsOtpGet(false);
              props.resetupdatepass();
              props.resetVerifyOtp();
              props.resetForgotPass();
            }}
          >
            Back to Login
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  forgotPassReducer: state.authReducer.forgotPass,
  verifyOtpReducer: state.authReducer.verifyOtp,
  updateForgotPassReducer: state.authReducer.updateForgotPass,
});
const mapDispatchToProps = (dispatch) => ({
  forgotPassword: (details) => dispatch(forgotPassword(details)),
  verifyOtp: (details) => dispatch(verifyOtp(details)),
  updateForgotPassword: (details) => dispatch(updateForgotPassword(details)),
  resetForgotPass: (details) => dispatch({ type: FORGOT_PASSWORD_RESET }),
  resetVerifyOtp: (details) => dispatch({ type: VERIFY_OTP_RESET }),
  resetupdatepass: (details) =>
    dispatch({ type: UPDATE_FORGOT_PASSWORD_RESET }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
