import React from "react";
import { Container } from "react-bootstrap";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";

const PrivacyPolicy = () => {
  return (
    <>
      <Header />
      <Container fluid className="banner">
        <Container>
          <h2
            className="pageName"
            data-aos="zoom-out-up"
            data-aos-duration="1000"
          >
            Privacy Policy
          </h2>
        </Container>
      </Container>
      <section className="container">
        <p data-aos="zoom-out-up" data-aos-duration="1000" className="mb-5">
          Delight Diamonds,collects information from our users through our web
          site. This information is used solely for administrative purposes and
          the promotion of Delight Diamonds. We will not sell, share, or rent
          this information to any other organization or individual. We request
          information from users on our order forms. For example, this can
          include contact information such as customers' names and shipping
          addresses. This information is used for billing purposes, to fill
          customers' orders, and to contact users if we have any trouble
          processing their orders.
        </p>

        <p data-aos="zoom-out-up" data-aos-duration="1000">
          We will share aggregated demographic information with our partners and
          advertisers. This is not linked to any personal information that can
          identify any individual person. These companies do not retain, share,
          store, or use personally identifiable information for any secondary
          purposes. We partner with another party to provide specific services.
          We share names, or other contact information as is necessary for the
          third party to provide these services. The third party is not allowed
          to use personally identifiable information except for the purpose of
          providing these services.
        </p>
      </section>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
