import { ADD_CONFIRM_STONE_LIST_ERROR, ADD_CONFIRM_STONE_LIST_RESET, ADD_CONFIRM_STONE_LIST_SUCCESS, ADD_VIEW_REQUEST_ERROR, ADD_VIEW_REQUEST_RESET, ADD_VIEW_REQUEST_SUCCESS, DELETE_CONFIRM_STONE_LIST_ERROR, DELETE_CONFIRM_STONE_LIST_RESET, DELETE_CONFIRM_STONE_LIST_SUCCESS, DELETE_VIEW_REQUEST_ERROR, DELETE_VIEW_REQUEST_RESET, DELETE_VIEW_REQUEST_SUCCESS, GET_CONFIRM_STONE_LIST_ERROR, GET_CONFIRM_STONE_LIST_RESET, GET_CONFIRM_STONE_LIST_SUCCESS, GET_VIEW_REQUEST_ERROR, GET_VIEW_REQUEST_RESET, GET_VIEW_REQUEST_SUCCESS } from "../common/constant";

let initialState = {
 getViewRequest: {
  error: false,
  data: null,
  errors: null,
  success: false,
 },
 createViewRequest: {
  error: false,
  errors: null,
  success: false,
 },
 deleteViewRequest: {
  error: false,
  errors: null,
  success: false,
 }
};

export default function viewRequestReducer(state = initialState, action) {
 switch (action.type) {
  case GET_VIEW_REQUEST_SUCCESS:
   return { ...state, getViewRequest: { ...state.getViewRequest, success: true, error: false, errors: null, data: action.response }, };

  case GET_VIEW_REQUEST_ERROR:
   return { ...state, getViewRequest: { ...state.getViewRequest, success: false, error: true, errors: action.msg } };

  case GET_VIEW_REQUEST_RESET:
   return { ...state, getViewRequest: { ...state.getViewRequest, success: false, error: false, errors: null } };

  //  add

  case ADD_VIEW_REQUEST_SUCCESS:
   return { ...state, createViewRequest: { ...state.createViewRequest, success: true, error: false, errors: null }, };

  case ADD_VIEW_REQUEST_ERROR:
   return { ...state, createViewRequest: { ...state.createViewRequest, success: false, error: true, errors: action.msg } };

  case ADD_VIEW_REQUEST_RESET:
   return { ...state, createViewRequest: { ...state.createViewRequest, success: false, error: false, errors: null } };



  //  delete

  case DELETE_VIEW_REQUEST_SUCCESS:
   return { ...state, deleteViewRequest: { ...state.deleteViewRequest, success: true, error: false, errors: null }, };

  case DELETE_VIEW_REQUEST_ERROR:
   return { ...state, deleteViewRequest: { ...state.deleteViewRequest, success: false, error: true, errors: action.msg } };

  case DELETE_VIEW_REQUEST_RESET:
   return { ...state, deleteViewRequest: { ...state.deleteViewRequest, success: false, error: false, errors: null } };

  default:
   return state;
 }
}