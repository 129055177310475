import React from "react";
import { Container } from "react-bootstrap";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";

const AboutUs = () => {
      return (
            <>
                  <Header/>
                  <Container fluid className="banner">
                        <Container>
                              <h2 className="pageName" data-aos="zoom-out"  data-aos-duration="1000">About Us</h2>
                        </Container>
                  </Container>

                  <main>
                        <section>
                              <Container>
                                    <h2 className="heading" data-aos="fade-right"  data-aos-duration="1000">Our Company</h2>
                                    <p className="mb-5" data-aos="zoom-in" data-aos-duration="1000">We Delight Diamonds are a Part of "Excel creation Pvt Ltd have been in the Gem & Jewelry industry for more than 15 years. Having extensive knowledge and experience in the manufacturing and distribution of ethically sourced mined diamonds. We have a state of the art diamond polishing factory with world class latest technology and equipment in Surat, Gujarat. Our Master Diamond cutters have years of experience to cut and polish rough stones into beautiful and Sparkling Lab Gown Polished Diamonds. A few years ago we were approached to Labgrown Diamonds For beautiful gem quality diamonds which would have a guaranteed conflict-free origin, free of the tag "Blood Diamonds. " Excel Creation Pvt Ltd was established in 2014, where along with our partners we have invested strongly over the years in advanced/disruptive technologies to successfully supplying Lab Grown Diamonds to the World.</p>
                                    
                                    <h2 className="heading" data-aos="fade-right" data-aos-duration="1000">Product Vision</h2>
                                    <p className="mb-5"  data-aos="zoom-in" data-aos-duration="1000">We Delight Diamonds are a Part of "Excel creation Pvt Ltd have been in the Gem & Jewelry industry for more than 15 years. Having extensive knowledge and experience in the manufacturing and distribution of ethically sourced mined diamonds. We have a state of the art diamond polishing factory with world class latest technology and equipment in Surat, Gujarat. Our Master Diamond cutters have years of experience to cut and polish rough stones into beautiful and Sparkling Lab Gown Polished Diamonds. A few years ago we were approached to Labgrown Diamonds For beautiful gem quality diamonds which would have a guaranteed conflict-free origin, free of the tag "Blood Diamonds. " Excel Creation Pvt Ltd was established in 2014, where along with our partners we have invested strongly over the years in advanced/disruptive technologies to successfully supplying Lab Grown Diamonds to the World.</p>
                                    
                                    <h2 className="heading" data-aos="fade-right" data-aos-duration="1000">Customer Commitment</h2>
                                    <p data-aos="zoom-in" data-aos-duration="1000">At Delight Diamonds, Our customers always come first. We want to make Our Clients Stress Free and Enjoyable. We also offer the convenience of shopping online, with Best prices Practice Diamond Works Technology combines unmatched chemical vapor deposition (CVD) crystal growth process expertise with proven, repeatable commercial-scale operational know-how. The company is well positioned to meet the growing demand for high quality lab-grown diamonds. Works Technology combines unmatched chemical vapor deposition (CVD) crystal growth process expertise with proven, repeatable commercial-scale operational know-how. The company is well positioned to meet the growing demand for high quality lab-grown diamonds. Diamond Works Technology combines unmatched chemical vapor deposition (CVD) crystal growth process expertise with proven, repeatable commercial-scale operational know-how. The company is well positioned to meet the growing demand for high quality lab-grown diamonds.</p>
                              </Container>
                        </section>
                  </main>

                  <Footer />
            </>
      )
}

export default AboutUs;