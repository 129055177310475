import { SEARCH_DIAMONDS_ERROR, SEARCH_DIAMONDS_RESET, SEARCH_DIAMONDS_SUCCESS } from "../common/constant";

let initialState = {
  searchDiamonds: {
    error: false,
    data: null,
    errors: null,
    success: false,
    count: 0,
    details: null
  },
};

export default function labGrownReducer(state = initialState, action) {
  switch (action.type) {
    case SEARCH_DIAMONDS_SUCCESS:
      return { ...state, searchDiamonds: { ...state.searchDiamonds, success: true, error: false, errors: null, data: action.response, count: action.count, details: action.details }, };

    case SEARCH_DIAMONDS_ERROR:
      return { ...state, searchDiamonds: { ...state.searchDiamonds, success: false, error: true, errors: action.msg, } };

    case SEARCH_DIAMONDS_RESET:
      return { ...state, searchDiamonds: { ...state.searchDiamonds, success: false, error: false, errors: null } };

    default:
      return state;
  }
}