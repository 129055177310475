export const COOKIE_EXPIRED = 60 * 60 * 24 * 365;
export const RESET_STORE = "RESET_STORE";
//  country

export const GET_COUNTRY = "GET_COUNTRY";
export const ERROR_COUNTRY = "ERROR_COUNTRY";

//  login
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_RESET = "LOGIN_RESET";

//  logut
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "LOGOUT_ERROR";
export const LOGOUT_RESET = "LOGOUT_RESET";

//  register
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_ERROR = "REGISTER_ERROR";
export const REGISTER_RESET = "REGISTER_RESET";

//  forgot pass
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const FORGOT_PASSWORD_RESET = "FORGOT_PASSWORD_RESET";

export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_ERROR = "VERIFY_OTP_ERROR";
export const VERIFY_OTP_RESET = "VERIFY_OTP_RESET";

export const UPDATE_FORGOT_PASSWORD_SUCCESS = "UPDATE_FORGOT_PASSWORD_SUCCESS";
export const UPDATE_FORGOT_PASSWORD_ERROR = "UPDATE_FORGOT_PASSWORD_ERROR";
export const UPDATE_FORGOT_PASSWORD_RESET = "UPDATE_FORGOT_PASSWORD_RESET";

//  dashboard list
export const GET_DASHBOARD_DETAILS_SUCCESS = "GET_DASHBOARD_DETAILS_SUCCESS";
export const GET_DASHBOARD_DETAILS_ERROR = "GET_DASHBOARD_DETAILS_ERROR";
export const GET_DASHBOARD_DETAILS_RESET = "GET_DASHBOARD_DETAILS_RESET";

//  profile
export const PROFILE_SUCCESS = "PROFILE_SUCCESS";
export const PROFILE_ERROR = "PROFILE_ERROR";
export const PROFILE_RESET = "PROFILE_RESET";

//  update profile
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_ERROR = "UPDATE_PROFILE_ERROR";
export const UPDATE_PROFILE_RESET = "UPDATE_PROFILE_RESET";

//  change password
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";
export const CHANGE_PASSWORD_RESET = "CHANGE_PASSWORD_RESET";

//  master
export const GET_MASTERS_LIST_SUCCESS = "GET_MASTERS_LIST_SUCCESS";
export const GET_MASTERS_LIST_ERROR = "GET_MASTERS_LIST_ERROR";
export const GET_MASTERS_LIST_RESET = "GET_MASTERS_LIST_RESET";
//  slot
export const GET_SLOT_SUCCESS = "GET_SLOT_SUCCESS";
export const GET_SLOT_ERROR = "GET_SLOT_ERROR";
export const GET_SLOT_RESET = "GET_SLOT_RESET";

//  getDemandList
export const GET_DEMAND_LIST_SUCCESS = "GET_DEMAND_LIST_SUCCESS";
export const GET_DEMAND_LIST_ERROR = "GET_DEMAND_LIST_ERROR";
export const GET_DEMAND_LIST_RESET = "GET_DEMAND_LIST_RESET";

//  addDemandList
export const ADD_DEMAND_LIST_SUCCESS = "ADD_DEMAND_LIST_SUCCESS";
export const ADD_DEMAND_LIST_ERROR = "ADD_DEMAND_LIST_ERROR";
export const ADD_DEMAND_LIST_RESET = "ADD_DEMAND_LIST_RESET";

//  deleteDemandList
export const DELETE_DEMAND_LIST_SUCCESS = "DELETE_DEMAND_LIST_SUCCESS";
export const DELETE_DEMAND_LIST_ERROR = "DELETE_DEMAND_LIST_ERROR";
export const DELETE_DEMAND_LIST_RESET = "DELETE_DEMAND_LIST_RESET";

//  getCart List
export const GET_CART_LIST_SUCCESS = "GET_CART_LIST_SUCCESS";
export const GET_CART_LIST_ERROR = "GET_CART_LIST_ERROR";
export const GET_CART_LIST_RESET = "GET_CART_LIST_RESET";

//  addCart List
export const ADD_CART_LIST_SUCCESS = "ADD_CART_LIST_SUCCESS";
export const ADD_CART_LIST_ERROR = "ADD_CART_LIST_ERROR";
export const ADD_CART_LIST_RESET = "ADD_CART_LIST_RESET";

//  deleteCart List
export const DELETE_CART_LIST_SUCCESS = "DELETE_CART_LIST_SUCCESS";
export const DELETE_CART_LIST_ERROR = "DELETE_CART_LIST_ERROR";
export const DELETE_CART_LIST_RESET = "DELETE_CART_LIST_RESET";

//  diamond details
export const DIAMOND_DETAILS_SUCCESS = "DIAMOND_DETAILS_SUCCESS";
export const DIAMOND_DETAILS_ERROR = "DIAMOND_DETAILS_ERROR";
export const DIAMOND_DETAILS_RESET = "DIAMOND_DETAILS_RESET";

//  getInquiry List
export const GET_INQUIRY_LIST_SUCCESS = "GET_INQUIRY_LIST_SUCCESS";
export const GET_INQUIRY_LIST_ERROR = "GET_INQUIRY_LIST_ERROR";
export const GET_INQUIRY_LIST_RESET = "GET_INQUIRY_LIST_RESET";

//  addInquiry List
export const ADD_INQUIRY_LIST_SUCCESS = "ADD_INQUIRY_LIST_SUCCESS";
export const ADD_INQUIRY_LIST_ERROR = "ADD_INQUIRY_LIST_ERROR";
export const ADD_INQUIRY_LIST_RESET = "ADD_INQUIRY_LIST_RESET";

//  deleteInquiry list
export const DELETE_INQUIRY_LIST_SUCCESS = "DELETE_INQUIRY_LIST_SUCCESS";
export const DELETE_INQUIRY_LIST_ERROR = "DELETE_INQUIRY_LIST_ERROR";
export const DELETE_INQUIRY_LIST_RESET = "DELETE_INQUIRY_LIST_RESET";

// export pdf
export const EXPORT_PDF_SUCCESS = "EXPORT_PDF_SUCCESS";
export const EXPORT_PDF_ERROR = "EXPORT_PDF_ERROR";
export const EXPORT_PDF_RESET = "EXPORT_PDF_RESET";

// export Excel
export const EXPORT_EXCEL_SUCCESS = "EXPORT_EXCEL_SUCCESS";
export const EXPORT_EXCEL_ERROR = "EXPORT_EXCEL_ERROR";
export const EXPORT_EXCEL_RESET = "EXPORT_EXCEL_RESET";

//  getHoldStone List
export const GET_HOLD_STONE_LIST_SUCCESS = "GET_HOLD_STONE_LIST_SUCCESS";
export const GET_HOLD_STONE_LIST_ERROR = "GET_HOLD_STONE_LIST_ERROR";
export const GET_HOLD_STONE_LIST_RESET = "GET_HOLD_STONE_LIST_RESET";

//  addHoldStone List
export const ADD_HOLD_STONE_LIST_SUCCESS = "ADD_HOLD_STONE_LIST_SUCCESS";
export const ADD_HOLD_STONE_LIST_ERROR = "ADD_HOLD_STONE_LIST_ERROR";
export const ADD_HOLD_STONE_LIST_RESET = "ADD_HOLD_STONE_LIST_RESET";

//  deleteHoldStone list
export const DELETE_HOLD_STONE_LIST_SUCCESS = "DELETE_HOLD_STONE_LIST_SUCCESS";
export const DELETE_HOLD_STONE_LIST_ERROR = "DELETE_HOLD_STONE_LIST_ERROR";
export const DELETE_HOLD_STONE_LIST_RESET = "DELETE_HOLD_STONE_LIST_RESET";

//  getconfirmStone List
export const GET_CONFIRM_STONE_LIST_SUCCESS = "GET_CONFIRM_STONE_LIST_SUCCESS";
export const GET_CONFIRM_STONE_LIST_ERROR = "GET_CONFIRM_STONE_LIST_ERROR";
export const GET_CONFIRM_STONE_LIST_RESET = "GET_CONFIRM_STONE_LIST_RESET";

//  addconfirmStone List
export const ADD_CONFIRM_STONE_LIST_SUCCESS = "ADD_CONFIRM_STONE_LIST_SUCCESS";
export const ADD_CONFIRM_STONE_LIST_ERROR = "ADD_CONFIRM_STONE_LIST_ERROR";
export const ADD_CONFIRM_STONE_LIST_RESET = "ADD_CONFIRM_STONE_LIST_RESET";

//  deleteconfirmStone list
export const DELETE_CONFIRM_STONE_LIST_SUCCESS =
  "DELETE_CONFIRM_STONE_LIST_SUCCESS";
export const DELETE_CONFIRM_STONE_LIST_ERROR =
  "DELETE_CONFIRM_STONE_LIST_ERROR";
export const DELETE_CONFIRM_STONE_LIST_RESET =
  "DELETE_CONFIRM_STONE_LIST_RESET";

//  view Request list
export const GET_VIEW_REQUEST_SUCCESS = "GET_VIEW_REQUEST_SUCCESS";
export const GET_VIEW_REQUEST_ERROR = "GET_VIEW_REQUEST_ERROR";
export const GET_VIEW_REQUEST_RESET = "GET_VIEW_REQUEST_RESET";

//  add view Request List
export const ADD_VIEW_REQUEST_SUCCESS = "ADD_VIEW_REQUEST_SUCCESS";
export const ADD_VIEW_REQUEST_ERROR = "ADD_VIEW_REQUEST_ERROR";
export const ADD_VIEW_REQUEST_RESET = "ADD_VIEW_REQUEST_RESET";

//  delete view Request list
export const DELETE_VIEW_REQUEST_SUCCESS = "DELETE_VIEW_REQUEST_SUCCESS";
export const DELETE_VIEW_REQUEST_ERROR = "DELETE_VIEW_REQUEST_ERROR";
export const DELETE_VIEW_REQUEST_RESET = "DELETE_VIEW_REQUEST_RESET";

//  appointment
export const GET_APPOINYMENT_SUCCESS = "GET_APPOINYMENT_SUCCESS";
export const GET_APPOINYMENT_ERROR = "GET_APPOINYMENT_ERROR";
export const GET_APPOINYMENT_RESET = "GET_APPOINYMENT_RESET";

//  add appointment
export const ADD_APPOINYMENT_SUCCESS = "ADD_APPOINYMENT_SUCCESS";
export const ADD_APPOINYMENT_ERROR = "ADD_APPOINYMENT_ERROR";
export const ADD_APPOINYMENT_RESET = "ADD_APPOINYMENT_RESET";

//  delete appointment
export const DELETE_APPOINYMENT_SUCCESS = "DELETE_APPOINYMENT_SUCCESS";
export const DELETE_APPOINYMENT_ERROR = "DELETE_APPOINYMENT_ERROR";
export const DELETE_APPOINYMENT_RESET = "DELETE_APPOINYMENT_RESET";

//  search diamonds
export const SEARCH_DIAMONDS_SUCCESS = "SEARCH_DIAMONDS_SUCCESS";
export const SEARCH_DIAMONDS_ERROR = "SEARCH_DIAMONDS_ERROR";
export const SEARCH_DIAMONDS_RESET = "SEARCH_DIAMONDS_RESET";

//  get Search list
export const SAVE_SEARCH_LIST_SUCCESS = "SAVE_SEARCH_LIST_SUCCESS";
export const SAVE_SEARCH_LIST_ERROR = "SAVE_SEARCH_LIST_ERROR";
export const SAVE_SEARCH_LIST_RESET = "SAVE_SEARCH_LIST_RESET";

//  add save search
export const ADD_SAVE_SEARCH_SUCCESS = "ADD_SAVE_SEARCH_SUCCESS";
export const ADD_SAVE_SEARCH_ERROR = "ADD_SAVE_SEARCH_ERROR";
export const ADD_SAVE_SEARCH_RESET = "ADD_SAVE_SEARCH_RESET";

// delete save search
export const DELETE_SAVE_SEARCH_SUCCESS = "DELETE_SAVE_SEARCH_SUCCESS";
export const DELETE_SAVE_SEARCH_ERROR = "DELETE_SAVE_SEARCH_ERROR";
export const DELETE_SAVE_SEARCH_RESET = "DELETE_SAVE_SEARCH_RESET";

//  get new Arravial list
export const NEW_ARRIVAL_LIST_SUCCESS = "NEW_ARRIVAL_LIST_SUCCESS";
export const NEW_ARRIVAL_LIST_ERROR = "NEW_ARRIVAL_LIST_ERROR";
export const NEW_ARRIVAL_LIST_RESET = "NEW_ARRIVAL_LIST_RESET";

// view stone
export const GET_SEARCH_STONE_SUCCESS = "GET_SEARCH_STONE_SUCCESS";
export const GET_SEARCH_STONE_ERROR = "GET_SEARCH_STONE_ERROR";
export const GET_SEARCH_STONE_RESET = "GET_SEARCH_STONE_RESET";

//  addDemandList
export const GET_POLICY_SUCCESS = "GET_POLICY_SUCCESS";
export const GET_POLICY_ERROR = "GET_POLICY_ERROR";
export const GET_POLICY_RESET = "GET_POLICY_RESET";

//  add Terms
export const CHECK_TERMS_SUCCESS = "CHECK_TERMS_SUCCESS";
export const CHECK_TERMS_ERROR = "CHECK_TERMS_ERROR";
export const CHECK_TERMS_RESET = "CHECK_TERMS_RESET";

//  add Terms
export const EMAIL_EXCEL_SUCCESS = "EMAIL_EXCEL_SUCCESS";
export const EMAIL_EXCEL_ERROR = "EMAIL_EXCEL_ERROR";
export const EMAIL_EXCEL_RESET = "EMAIL_EXCEL_RESET";

//  Email Activation
export const EMAIL_ACTIVAT_SUCCESS = "EMAIL_ACTIVAT_SUCCESS";
export const EMAIL_ACTIVAT_ERROR = "EMAIL_ACTIVAT_ERROR";
export const EMAIL_ACTIVAT_RESET = "EMAIL_ACTIVAT_RESET";

//  Marketing User
export const MARKETING_USER_SUCCESS = "MARKETING_USER_SUCCESS";
export const MARKETING_USER_ERROR = "MARKETING_USER_ERROR";
export const MARKETING_USER_RESET = "MARKETING_USER_RESET";

//Check Diamonds Type

export const CHECK_DIAMONDS_TYPE_SUCCESS = "CHECK_DIAMONDS_TYPE_SUCCESS";
export const CHECK_DIAMONDS_TYPE_ERROR = "CHECK_DIAMONDS_TYPE_ERROR";
export const CHECK_DIAMONDS_TYPE_RESET = "CHECK_DIAMONDS_TYPE_RESET";

//star melee

export const GET_STARMELEE_DIAMONDS_SUCCESS = "GET_STARMELEE_DIAMONDS_SUCCESS";
export const GET_STARMELEE_DIAMONDS_ERROR = "GET_STARMELEE_DIAMONDS_ERROR";
export const GET_STARMELEE_DIAMONDS_RESET = "GET_STARMELEE_DIAMONDS_RESET";

//star melee carat

export const ADD_STARMELEE_CARAT_SUCCESS = "ADD_STARMELEE_CARAT_SUCCESS";
export const ADD_STARMELEE_CARAT_ERROR = "ADD_STARMELEE_CARAT_ERROR";
export const ADD_STARMELEE_CARAT_RESET = "ADD_STARMELEE_CARAT_RESET";

//star melee carat

export const ADD_STARMELEE_PRICE_SUCCESS = "ADD_STARMELEE_PRICE_SUCCESS";
export const ADD_STARMELEE_PRICE_ERROR = "ADD_STARMELEE_PRICE_ERROR";
export const ADD_STARMELEE_PRICE_RESET = "ADD_STARMELEE_PRICE_RESET";

//star melee carat

export const ADD_STARMELEE_INQUAIRY_SUCCESS = "ADD_STARMELEE_INQUAIRY_SUCCESS";
export const ADD_STARMELEE_INQUAIRY_ERROR = "ADD_STARMELEE_INQUAIRY_ERROR";
export const ADD_STARMELEE_INQUAIRY_RESET = "ADD_STARMELEE_INQUAIRY_RESET";

//star melee Inquairy List

export const GET_STARMELEE_INQUAIRY_LIST_SUCCESS = "GET_STARMELEE_INQUAIRY_LIST_SUCCESS";
export const GET_STARMELEE_INQUAIRY_LIST_ERROR = "GET_STARMELEE_INQUAIRY_LIST_ERROR";
export const GET_STARMELEE_INQUAIRY_LIST_RESET = "GET_STARMELEE_INQUAIRY_LIST_RESET";


//vendor country
export const GET_VENDOR_COUNTRY_LIST_SUCCESS = "GET_VENDOR_COUNTRY_LIST_SUCCESS";
export const GET_VENDOR_COUNTRY_LIST_ERROR = "GET_VENDOR_COUNTRY_LIST_ERROR";
export const GET_VENDOR_COUNTRY_LIST_RESET = "GET_VENDOR_COUNTRY_LIST_RESET";

//vendor register
export const VENDOR_REGISTER_SUCCESS = "VENDOR_REGISTER_SUCCESS";
export const VENDOR_REGISTER_ERROR = "VENDOR_REGISTER_ERROR";
export const VENDOR_REGISTER_RESET = "VENDOR_REGISTER_RESET";


// vendor Email Activation
export const VENDOR_EMAIL_ACTIVAT_SUCCESS = "VENDOR_EMAIL_ACTIVAT_SUCCESS";
export const VENDOR_EMAIL_ACTIVAT_ERROR = "VENDOR_EMAIL_ACTIVAT_ERROR";
export const VENDOR_EMAIL_ACTIVAT_RESET = "VENDOR_EMAIL_ACTIVAT_RESET";



//MERCHANT country
export const GET_MERCHANT_COUNTRY_LIST_SUCCESS = "GET_MERCHANT_COUNTRY_LIST_SUCCESS";
export const GET_MERCHANT_COUNTRY_LIST_ERROR = "GET_MERCHANT_COUNTRY_LIST_ERROR";
export const GET_MERCHANT_COUNTRY_LIST_RESET = "GET_MERCHANT_COUNTRY_LIST_RESET";

//MERCHANT register
export const MERCHANT_REGISTER_SUCCESS = "MERCHANT_REGISTER_SUCCESS";
export const MERCHANT_REGISTER_ERROR = "MERCHANT_REGISTER_ERROR";
export const MERCHANT_REGISTER_RESET = "MERCHANT_REGISTER_RESET";

// merchant Email Activation
export const MERCHANT_EMAIL_ACTIVAT_SUCCESS = "MERCHANT_EMAIL_ACTIVAT_SUCCESS";
export const MERCHANT_EMAIL_ACTIVAT_ERROR = "MERCHANT_EMAIL_ACTIVAT_ERROR";
export const MERCHANT_EMAIL_ACTIVAT_RESET = "MERCHANT_EMAIL_ACTIVAT_RESET";

// paired diamond list
export const GET_PAIRED_STONE_LIST_SUCCESS = "GET_PAIRED_STONE_LIST_SUCCESS";
export const GET_PAIRED_STONE_LIST_ERROR = "GET_PAIRED_STONE_LIST_ERROR";
export const GET_PAIRED_STONE_LIST_RESET = "GET_PAIRED_STONE_LIST_RESET";
