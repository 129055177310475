import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { connect, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { exportExcel, exportPdf } from "../userStore/action/exportFileAction";
import {  deleteHoldListList,  getHoldStoneList} from "../userStore/action/holdStoneAction";
import {  DELETE_HOLD_STONE_LIST_RESET,  EXPORT_EXCEL_RESET,  EXPORT_PDF_RESET} from "../userStore/common/constant";
import HoldStoneTable from "./Tables/HoldStoneTable";
import Footer from "./userLayout/Footer";
import Header from "./userLayout/Header";
import { Modal, Button } from "react-bootstrap";
import { abbreviateNumber } from "../userStore/common/helper";
import { Tooltip } from 'react-tooltip'
const HoldedStone = (props) => {
  const [selected, setSelected] = useState({});
  const [details, setDetails] = useState([]);
  const [IsDeleteCart, setIsDeleteCart] = useState(false);
  const diamondType = useSelector(
    (state) => state?.authReducer?.profile?.data?.diamond_type
  );
  const handleDelete = () => {
    const ids = Object.keys(selected).filter((e) => {
      if (selected[e] === true) {
        return e;
      } else return false
    });

    props.deleteHoldListList({
      hold_id: JSON.stringify([...ids]),
    });
    setIsDeleteCart(false);
  };

  useEffect(() => {
    if (props.deleteHoldStoneReducer.success) {
      props.getHoldStoneList();
      setSelected({});
      toast.success("Holded item deleted successfully...");
      props.resetDeleteHoldStone();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.deleteHoldStoneReducer]);

  useEffect(() => {
    if (Object.keys(selected).length !== 0) {
      const data = props.HoldStoneListReducer.data;
      const idSelected = Object.keys(selected).filter((e) => {
        if (selected[e] === true) {
          return e;
        } else return false
      });
      const _selected = idSelected.map((e) => {
        const list = data.find((data) => parseInt(data.id) === parseInt(e));
        return list;
      });
      setDetails([..._selected]);
    } else {
      setDetails([]);
    }
  }, [selected, props.HoldStoneListReducer.data]);

  const handleExportPdf = () => {
    if (props.HoldStoneListReducer.success) {
      const data = props.HoldStoneListReducer.data;
      const idSelected = Object.keys(selected).filter((e) => {
        if (selected[e] === true) {
          return e;
        } else return false
      });
      const stonIds = idSelected.map((e) => {
        const list = data.find((data) => parseInt(data.id) === parseInt(e))
          .product?.stone_id;
        return list;
      });
      if (stonIds.length !== 0) {
        props.exportPdf({
          details: stonIds,
          dt: diamondType,
        });
      } else {
        toast.error("Please Select list..");
      }
      props.resetExportPdf();
    }
  };

  const handleExportExcel = () => {
    if (props.HoldStoneListReducer.success) {
      const data = props.HoldStoneListReducer.data;
      const idSelected = Object.keys(selected).filter((e) => {
        if (selected[e] === true) {
          return e;
        } else return false
      });
      const stonIds = idSelected.map((e) => {
        const list = data.find((data) => parseInt(data.id) === parseInt(e))
          .product?.stone_id;
        return list;
      });
      if (stonIds.length !== 0) {
        props.exportExcel({
          details: stonIds,
          dt: diamondType,
        });
      } else {
        toast.error("Please Select list..");
      }
      props.resetExportExcel();
    }
  };
  return (
    <>
      <Header />
      <main className="userMain">
        <div className="d-flex justify-content-between  mt-5 mb-4 flex-wrap">
          <h2 className="heading mb-0"> Hold Stone List</h2>
        </div>

        <div className="content-block">
          {/* Info blocks */}
          <div className="row g-1 g-lg-3 mb-4 mt-lg-0 mt-3">
            <div className="col-lg-3 col-md-6">
              <div className="info-block">
                <h6 className="mb-lg-3 text-primary"> Total Cts</h6>
                <h2 className="mb-0">
                  {details.length === 0
                    ? 0
                    : details
                        .map((e) => e.product?.carat)
                        .reduce((a, b) => a + b)
                        ?.toFixed(2)}
                </h2>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="info-block">
                <h6 className="mb-lg-3 text-primary"> $/Carat</h6>
                <h2 className="mb-0">
                  {details.length === 0
                    ? 0
                    : `$
                      ${abbreviateNumber(
                        (
                          details
                            .map((e) => e.product?.amount)
                            .reduce((a, b) => parseFloat(a) + parseFloat(b)) /
                          details
                            .map((e) => e.product?.carat)
                            .reduce((a, b) => parseFloat(a) + parseFloat(b))
                        )?.toFixed(2)
                      )}`}
                </h2>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="info-block">
                <h6 className="mb-lg-3 text-primary"> Average Discount</h6>
                <h2 className="mb-0">
                  {details.length === 0
                    ? 0
                    : `
                      ${(
                        details
                          .map((e) => e.product?.discount)
                          .reduce((a, b) => parseFloat(a) + parseFloat(b)) /
                        details.length
                      )?.toFixed(2)} %`}
                </h2>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="info-block ">
                <h6 className="mb-lg-3 text-primary"> Total Amount</h6>
                <h2 className="mb-0">
                  {details.length === 0
                    ? 0
                    : `$
                      ${abbreviateNumber(
                        details
                          .map((e) => e.product?.amount)
                          .reduce((a, b) => parseFloat(a) + parseFloat(b))
                          ?.toFixed(2)
                      )} `}
                </h2>
              </div>
            </div>
          </div>
          <div className="mb-3 d-flex justify-content-end align-items-center">
        
              <Button
                variant="secondary"
                className="me-sm-2 me-1 tooltip-labels"
                data-tooltip-content="Export PDF"
                onClick={handleExportPdf}
              >
                <i className="fa-solid fa-file-pdf"></i>{" "}
              </Button>
          
              <Button
                variant="secondary"
                className="me-sm-2 me-1 tooltip-labels"
                data-tooltip-content="Export Excel"
                onClick={handleExportExcel}
              >
                <i className="fa-solid fa-file-excel"></i>
              </Button>
           
            <Button
              variant="secondary"
              className="me-sm-2 me-1"
              onClick={() => {
                const ids = Object.keys(selected).filter((e) => {
                  if (selected[e] === true) {
                    return e;
                  } else return false
                });
                if (ids.length !== 0) {
                  setIsDeleteCart(true);
                } else {
                  toast.error("Select stone for release..");
                }
              }}
            >
              Release Hold Stone
            </Button>
          <Tooltip anchorSelect=".tooltip-labels" />
          </div>
          <HoldStoneTable setSelected={setSelected} />
          {/* <InquiryTable /> */}
        </div>
      </main>
      <Footer />
      <Modal
        show={IsDeleteCart}
        size="lg"
        backdrop="static"
        onHide={() => setIsDeleteCart(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="p-4">
          <div className="">
            <div className="text-center my-5">
              <h5>Are you sure you want to delete this Data ?</h5>
            </div>
            <Modal.Footer className="mt-3">
              <Button
                className="py-2 px-4 border rounded pointer bg-secondary text-white mx-2 pointer"
                onClick={() => {
                  setIsDeleteCart(false);
                }}
              >
                Cancel
              </Button>
              <Button
                className="py-2 px-4 border rounded pointer bg-primary text-white mx-2 pointer"
                onClick={handleDelete}
              >
                Release hold stone
              </Button>
            </Modal.Footer>
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  deleteHoldStoneReducer: state.holdStoneReducer.deleteHoldStone,
  HoldStoneListReducer: state.holdStoneReducer.HoldStoneList,
});
const mapDispatchToProps = (dispatch) => ({
  getHoldStoneList: () => dispatch(getHoldStoneList()),
  deleteHoldListList: (details) => dispatch(deleteHoldListList(details)),
  exportPdf: (details) => dispatch(exportPdf(details)),
  exportExcel: (details) => dispatch(exportExcel(details)),
  resetDeleteHoldStone: () => dispatch({ type: DELETE_HOLD_STONE_LIST_RESET }),
  resetExportPdf: () => dispatch({ type: EXPORT_PDF_RESET }),
  resetExportExcel: () => dispatch({ type: EXPORT_EXCEL_RESET }),
});

export default connect(mapStateToProps, mapDispatchToProps)(HoldedStone);
