import React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import SearchTable from "./searchTable";
import Footer from "./userLayout/Footer";
import Header from "./userLayout/Header";

const RecentSearch = () => {
  return (
    <>
      <Header />
      <main className="userMain">
        <div className="d-flex justify-content-between  mt-5 mb-4 flex-wrap">
          <h2 className="heading mb-0">
            <Link to="/dashboard" className="text-primary">
              <i className="fa-solid fa-arrow-left"></i>
            </Link>{" "}
            Recent Search
          </h2>
          <div className="text-end mt-3 mt-sm-0 ms-auto navigate-buttons">
            {/* Modify search */}
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip">Modify Search</Tooltip>}
            >
              <Button variant="secondary" className="me-sm-2 me-1">
                <i className="fa-solid fa-magnifying-glass"></i>
              </Button>
            </OverlayTrigger>

            {/* Add to cart */}
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip">Add to Cart</Tooltip>}
            >
              <Button variant="secondary" className="me-sm-2 me-1">
                <i className="fa-solid fa-cart-shopping"></i>
              </Button>
            </OverlayTrigger>

            {/* Send Inquiry */}
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip">Send Inquiry</Tooltip>}
            >
              <Button variant="secondary" className="me-sm-2 me-1">
                <i className="fa-solid fa-clipboard-question"></i>
              </Button>
            </OverlayTrigger>

            {/* Add to watch list */}
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip">Add to Watch List</Tooltip>}
            >
              <Button variant="secondary" className="me-sm-2 me-1">
                <i className="fa-solid fa-clock"></i>
              </Button>
            </OverlayTrigger>

            {/* Show selected */}
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip">Show selected</Tooltip>}
            >
              <Button variant="secondary" className="me-sm-2 me-1">
                <i className="fa-solid fa-clipboard-list"></i>
              </Button>
            </OverlayTrigger>

            {/* Stone Comparison */}
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip">Stone Comparison</Tooltip>}
            >
              <Button variant="secondary" className="me-sm-2 me-1">
                <i className="fa-solid fa-scale-balanced"></i>
              </Button>
            </OverlayTrigger>

            {/* Print PDF */}
            {/* <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Print PDF</Tooltip>}>
                                    <Button variant="secondary" className="me-2"><i className="fa-solid fa-file-pdf"></i></Button>
                              </OverlayTrigger>    */}

            {/* Download Excel */}
            {/* <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Download Excel</Tooltip>}>
                                    <Button variant="secondary" className="me-2"><i className="fa-solid fa-file-excel"></i></Button>
                              </OverlayTrigger> */}

            {/* Email Excel */}
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip">Email Excel</Tooltip>}
            >
              <Button variant="secondary" className="me-sm-2 me-1">
                <i className="fa-solid fa-envelope-open-text"></i>
              </Button>
            </OverlayTrigger>
          </div>
        </div>

        <div className="content-block">
          {/* Info blocks */}
          <div className="row g-1 g-lg-3 mb-4 mt-lg-0 mt-3">
            <div className="col-md-3 col-sm-6">
              <div className="info-block">
                <h6 className="mb-lg-3 text-primary"> Total Cts</h6>
                <h2 className="mb-0">2.250</h2>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="info-block">
                <h6 className="mb-lg-3 text-primary"> $/Carat</h6>
                <h2 className="mb-0">$1430.00</h2>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="info-block">
                <h6 className="mb-lg-3 text-primary"> Average Discount</h6>
                <h2 className="mb-0">
                  {" "}
                  93.50 <sub>%</sub>
                </h2>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 ">
              <div className="info-block ">
                <h6 className="mb-lg-3 text-primary"> Total Amount</h6>
                <h2 className="mb-0">$ 1615.90</h2>
              </div>
            </div>
          </div>
          <SearchTable />
        </div>
      </main>
      <Footer />
    </>
  );
};

export default RecentSearch;
