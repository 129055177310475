import axios from "axios";
import { EMAIL_ACTIVAT_ERROR, EMAIL_ACTIVAT_SUCCESS, MERCHANT_EMAIL_ACTIVAT_ERROR, MERCHANT_EMAIL_ACTIVAT_RESET, MERCHANT_EMAIL_ACTIVAT_SUCCESS, VENDOR_EMAIL_ACTIVAT_ERROR, VENDOR_EMAIL_ACTIVAT_RESET, VENDOR_EMAIL_ACTIVAT_SUCCESS } from "../common/constant";
import { email_Activat_Url, merchantEmailActivation_Url, vendorEmailActivation_Url } from "../common/apiSheet";

export const EmailActivationAction = (ID) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      url: `${email_Activat_Url}/${ID}`,
      // headers: {
      //   'Authorization': `Bearer ${localStorage.getItem("token")}`,
      // },
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: EMAIL_ACTIVAT_SUCCESS,
          response: resp.data.data.result,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: EMAIL_ACTIVAT_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: EMAIL_ACTIVAT_ERROR,
            msg: validationError,
          });
        }
      });
  };
};

export const VendorEmailActivationFunc = (ID) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      url: `${vendorEmailActivation_Url}/${ID}`,
      // headers: {
      //   'Authorization': `Bearer ${localStorage.getItem("token")}`,
      // },
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: VENDOR_EMAIL_ACTIVAT_SUCCESS,
          response: resp.data.data.result,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: VENDOR_EMAIL_ACTIVAT_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: VENDOR_EMAIL_ACTIVAT_RESET,
            msg: validationError,
          });
        }
      });
  };
};

export const MerchantEmailActivationFunc = (ID) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      url: `${merchantEmailActivation_Url}/${ID}`,
      // headers: {
      //   'Authorization': `Bearer ${localStorage.getItem("token")}`,
      // },
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: MERCHANT_EMAIL_ACTIVAT_SUCCESS,
          response: resp.data.data.result,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: MERCHANT_EMAIL_ACTIVAT_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: MERCHANT_EMAIL_ACTIVAT_RESET,
            msg: validationError,
          });
        }
      });
  };
};