import React from "react";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { deleteDemandList } from "../userStore/action/demandAction";
import AddDemandModal from "./modals/AddDemandModal";
import DemandTable from "./Tables/DemandTable";
import Footer from "./userLayout/Footer";
import Header from "./userLayout/Header";

const DemandList = (props) => {
  const [addDemand, setAddDemand] = useState(false);
  const [selected, setSelected] = useState({});
  const [IsDeleteCart, setIsDeleteCart] = useState(false);

  const handleDelete = () => {
    const ids = Object.keys(selected).filter((e) => {
      if (selected[e] === true) {
        return e;
      } else return false
    });

    props.deleteDemandAction({
      demand_id: JSON.stringify([...ids]),
    });
    setIsDeleteCart(false);
  };

  // const handleDelete = () => {
  //   const ids = Object.keys(selected).filter((e) => {
  //     if (selected[e] === true) {
  //       return e;
  //     }
  //   });

  //   if(ids.length !== 0) {
  //     props.deleteDemandAction({
  //     demand_id: JSON.stringify([...ids]),
  //   });
  //   } else {
  //     toast.error('Select demand list for delete...')
  //   }

  // };
  return (
    <>
      <Header />
      <main className="userMain">
        <div className="d-flex justify-content-between  mt-5 mb-4 flex-wrap">
          <h2 className="heading mb-0"> Demand List</h2>
          <div>
            <Button
              variant="secondary"
              className="me-sm-2 me-1"
              onClick={() => {
                const ids = Object.keys(selected).filter((e) => {
                  if (selected[e] === true) {
                    return e;
                  } else return false
                });

                if (ids.length !== 0) {
                  setIsDeleteCart(true);
                } else {
                  toast.error("Select demand list for delete...");
                }
              }}
            >
              Delete Demand
            </Button>
            <Button
              variant="secondary"
              className="me-sm-2 me-1"
              onClick={() => {
                setAddDemand(true);
              }}
            >
              <i className="fa-solid fa-plus me-2"></i>Add Demand
            </Button>
          </div>
        </div>
        <div className="content-block">
          <DemandTable setSelected={setSelected} />
        </div>
      </main>
      <Footer />
      {addDemand && (
        <AddDemandModal
          show={addDemand}
          onHide={() => setAddDemand(false)}
          setIsDemand={setAddDemand}
        />
      )}
      <Modal
        show={IsDeleteCart}
        size="lg"
        backdrop="static"
        onHide={() => setIsDeleteCart(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="p-4">
          <div className="">
            <div className="text-center my-5">
              <h5>Are you sure you want to delete this Data ?</h5>
            </div>
            <Modal.Footer className="mt-3">
              <Button
                className="py-2 px-4 border rounded pointer bg-secondary text-white mx-2 pointer"
                onClick={() => {
                  setIsDeleteCart(false);
                }}
              >
                Cancel
              </Button>
              <Button
                className="py-2 px-4 border rounded pointer bg-primary text-white mx-2 pointer"
                onClick={handleDelete}
              >
                Delete Cart
              </Button>
            </Modal.Footer>
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  deleteDemandAction: (details) => dispatch(deleteDemandList(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DemandList);
