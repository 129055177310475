import React from 'react';
import ReactDOM from 'react-dom/client';
import '@progress/kendo-theme-default/dist/all.css';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import 'react-tooltip/dist/react-tooltip.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './assets/css/index.scss';
import './assets/css/App.scss';
import './assets/css/remixicon.css';
import './assets/css/style.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from "react-redux";
import store from './userStore/store';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from 'react-toastify';
import axios from 'axios';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import "jspdf/dist/polyfills.es.js"

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
axios.defaults.headers.common['Content-Type'] = "application/json";
const token = localStorage.getItem('token');
if (token !== null) {
  axios.defaults.headers.common['Authorization'] = "Bearer " + token;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
const CloseButton = ({ closeToast }) => <i onClick={closeToast} className="fa fa-close notifications-close text-danger" />
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
      <ToastContainer
        autoClose={3000}
        hideProgressBar
        closeOnClick
        closeButton={<CloseButton />}
      />
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
