import { ADD_INQUIRY_LIST_ERROR, ADD_INQUIRY_LIST_RESET, ADD_INQUIRY_LIST_SUCCESS, DELETE_INQUIRY_LIST_ERROR, DELETE_INQUIRY_LIST_RESET, DELETE_INQUIRY_LIST_SUCCESS, GET_INQUIRY_LIST_ERROR, GET_INQUIRY_LIST_RESET, GET_INQUIRY_LIST_SUCCESS } from "../common/constant";

let initialState = {
 InquiryList: {
  error: false,
  data: null,
  errors: null,
  success: false,
 },
 createInquiry: {
  error: false,
  errors: null,
  success: false,
 },
 deleteInquiry: {
  error: false,
  errors: null,
  success: false,
 }
};

export default function inquiryReducer(state = initialState, action) {
 switch (action.type) {
  case GET_INQUIRY_LIST_SUCCESS:
   return { ...state, InquiryList: { ...state.InquiryList, success: true, error: false, errors: null, data: action.response }, };

  case GET_INQUIRY_LIST_ERROR:
   return { ...state, InquiryList: { ...state.InquiryList, success: false, error: true, errors: action.msg } };

  case GET_INQUIRY_LIST_RESET:
   return { ...state, InquiryList: { ...state.InquiryList, success: false, error: false, errors: null } };

  //  add

  case ADD_INQUIRY_LIST_SUCCESS:
   return { ...state, createInquiry: { ...state.createInquiry, success: true, error: false, errors: null }, };

  case ADD_INQUIRY_LIST_ERROR:
   return { ...state, createInquiry: { ...state.createInquiry, success: false, error: true, errors: action.msg } };

  case ADD_INQUIRY_LIST_RESET:
   return { ...state, createInquiry: { ...state.createInquiry, success: false, error: false, errors: null } };



  //  delete

  case DELETE_INQUIRY_LIST_SUCCESS:
   return { ...state, deleteInquiry: { ...state.deleteInquiry, success: true, error: false, errors: null }, };

  case DELETE_INQUIRY_LIST_ERROR:
   return { ...state, deleteInquiry: { ...state.deleteInquiry, success: false, error: true, errors: action.msg } };

  case DELETE_INQUIRY_LIST_RESET:
   return { ...state, deleteInquiry: { ...state.deleteInquiry, success: false, error: false, errors: null } };

  default:
   return state;
 }
}