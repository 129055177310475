import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom/dist";
import videoUrl from "../assets/production ID_5106444.mp4";
import {
  getMerchantCountryList,
  merchantRegister,
} from "../userStore/action/authAction";
import { MERCHANT_REGISTER_RESET } from "../userStore/common/constant";
import { MultiSelect } from "react-multi-select-component";
import { toast } from "react-toastify";
// { submitRegForm, regsubmit, countryList, countries }
const MerchantRegister = (props) => {
  const navigate = useNavigate();

  const [spinner, setSpinner] = useState(false);
  // const [showPass, setShowPass] = useState(false);
  // const [showConPass, setShowConPass] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [bussinessTypeList, setBussinessTypeList] = useState([]);
  const [buyingGroupList, setBuyingGroupList] = useState([]);

  const [state, setState] = useState({
    username: "",
    email: "",
    // password: "",
    // conPassword: "",
    first_name: "",
    last_name: "",
    company: "",
    website: "",
    other_phone: "",
    mobileNo: "",
    about: "",
    job_title: "",
    business_type: [],
    buying_group: [],
    group_title: "",
    address: "",
    city: "",
    state: "",
    pincode: "",
    country_id: "",
  });

  const [error, setError] = useState({
    username: false,
    email: false,
    // password: false,
    // conPassword: false,
    other_phone: false,
    mobileNo: false,
    first_name: false,
    last_name: false,
    company: false,
    website: false,
    about: false,
    job_title: false,
    business_type: false,
    buying_group: false,
    group_title: false,
    address: false,
    city: false,
    state: false,
    pincode: false,
    country_id: false,
  });

  useEffect(() => {
    props.getMerchantCountryList();
  }, []);

  useEffect(() => {
    if (props.merchantCountryListReducer) {
      const data = props.merchantCountryListReducer.data;
      const country = !!data?.country ? data?.country : [];
      const bussinessType = !!data?.BusinessType ? data?.BusinessType : {};
      const buingGroup = !!data?.ByuingGroup ? data?.ByuingGroup : {};
      setCountryList([...country]);
      setBussinessTypeList(
        Object.keys(bussinessType).map((e) => {
          return { label: bussinessType[e], value: e };
        })
      );
      setBuyingGroupList(
        Object.keys(buingGroup).map((e) => {
          return { label: buingGroup[e], value: e };
        })
      );
    }
  }, [props.merchantCountryListReducer]);

  useEffect(() => {
    if (props.merchantRegisterReducer.success) {
      navigate("/");
      toast.success("Merchant account registered...");
      props.resetRegister();
    } else if (props.merchantRegisterReducer.error) {
      const errorMsg = props.merchantRegisterReducer.errors;
      if ("username" in errorMsg) {
        setError((prev) => ({
          ...prev,
          username: errorMsg["username"][0],
        }));
      }
      if ("email" in errorMsg) {
        setError((prev) => ({
          ...prev,
          email: errorMsg["email"][0],
        }));
      }
      props.resetRegister();
    }
  }, [props.merchantRegisterReducer]);

  const handleChange = (e) => {
    const { id, value, name } = e.target;
    const validEmailRegex = RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );
    const validUrlExpression = new RegExp(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~# &=]*)/g
    );
    const contactValidation = RegExp(/^(?!0+\s+,?$)\d{10}\s*,?$/);

    if (name === "mobileNo" || name === "other_phone") {
      if (value.length <= 10) {
        setState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      } else return;
    } else {
      setState((prev) => ({
        ...prev,
        [name]: value,
      }));
    }

    switch (name) {
      case "username":
        if (value === "") {
          error["username"] = "Required";
        } else {
          error["username"] = false;
        }
        break;

      case "email":
        if (value === "") {
          error["email"] = "Required";
        } else if (!validEmailRegex.test(value)) {
          error["email"] = "Invalid Email";
        } else {
          error["email"] = false;
        }
        break;

      // case "password":
      //   if (value === "") {
      //     error["password"] = "Required";
      //   } else {
      //     error["password"] = false;
      //   }
      //   break;

      // case "conPassword":
      //   if (value === "") {
      //     error["conPassword"] = "Required";
      //   } else if (value !== state.password) {
      //     error["conPassword"] = "Confirm password not matched";
      //   } else {
      //     error["conPassword"] = false;
      //   }
      //   break;

      case "first_name":
        if (value === "") {
          error["first_name"] = "Required";
        } else {
          error["first_name"] = false;
        }
        break;

      case "last_name":
        if (value === "") {
          error["last_name"] = "Required";
        } else {
          error["last_name"] = false;
        }
        break;

      case "company":
        if (value === "") {
          error["company"] = "Required";
        } else {
          error["company"] = false;
        }
        break;

      case "website":
        if (value === "") {
          error["website"] = "Required";
        } else if (!validUrlExpression.test(value)) {
          error["website"] = "Invalid website URL";
        } else {
          error["website"] = false;
        }
        break;

      case "about":
        if (value === "") {
          error["about"] = "Required";
        } else {
          error["about"] = false;
        }
        break;

      case "job_title":
        if (value === "") {
          error["job_title"] = "Required";
        } else {
          error["job_title"] = false;
        }
        break;

      case "group_title":
        if (value === "") {
          error["group_title"] = "Required";
        } else {
          error["group_title"] = false;
        }
        break;

      case "address":
        if (value === "") {
          error["address"] = "Required";
        } else {
          error["address"] = false;
        }
        break;

      case "city":
        if (value === "") {
          error["city"] = "Required";
        } else {
          error["city"] = false;
        }
        break;

      case "state":
        if (value === "") {
          error["state"] = "Required";
        } else {
          error["state"] = false;
        }
        break;

      case "mobileNo":
        if (value === "") {
          error["mobileNo"] = "Required";
        } else if (!contactValidation.test(value)) {
          error["mobileNo"] = "Enter valid mobile no.";
        } else {
          error["mobileNo"] = false;
        }
        break;

      case "other_phone":
        if (!contactValidation.test(value)) {
          error["other_phone"] = "Enter valid mobile no.";
        } else {
          error["other_phone"] = false;
        }
        break;

      case "pincode":
        if (value === "") {
          error["pincode"] = "Required";
        } else {
          error["pincode"] = false;
        }
        break;

      case "country_id":
        if (value === "") {
          error["country_id"] = "Required";
        } else {
          error["country_id"] = false;
        }
        break;

      default:
        break;
    }
  };

  const handleMultiSelectChange = (name, value) => {
    setState((pre) => ({
      ...pre,
      [name]: value,
    }));

    switch (name) {
      case "business_type":
        if (value.length === 0) {
          error["business_type"] = "Required";
        } else {
          error["business_type"] = false;
        }
        break;

      case "buying_group":
        if (value.length === 0) {
          error["buying_group"] = "Required";
        } else {
          error["buying_group"] = false;
        }
        break;
      default:
        break;
    }
  };

  const submitForm = () => {
    if (state.username === "") {
      setError((pre) => ({
        ...pre,
        username: "Required",
      }));
    }
    if (state.email === "") {
      setError((pre) => ({
        ...pre,
        email: "Required",
      }));
    }
    // if (state.password === "") {
    //   setError((pre) => ({
    //     ...pre,
    //     password: "Required",
    //   }));
    // }
    // if (state.conPassword === "") {
    //   setError((pre) => ({
    //     ...pre,
    //     conPassword: "Required",
    //   }));
    // }
    if (state.mobileNo === "") {
      setError((pre) => ({
        ...pre,
        mobileNo: "Required",
      }));
    }
    if (state.first_name === "") {
      setError((pre) => ({
        ...pre,
        first_name: "Required",
      }));
    }
    if (state.last_name === "") {
      setError((pre) => ({
        ...pre,
        last_name: "Required",
      }));
    }
    if (state.company === "") {
      setError((pre) => ({
        ...pre,
        company: "Required",
      }));
    }
    if (state.website === "") {
      setError((pre) => ({
        ...pre,
        website: "Required",
      }));
    }
    if (state.about === "") {
      setError((pre) => ({
        ...pre,
        about: "Required",
      }));
    }
    if (state.job_title === "") {
      setError((pre) => ({
        ...pre,
        job_title: "Required",
      }));
    }
    if (state.business_type.length === 0) {
      setError((pre) => ({
        ...pre,
        business_type: "Required",
      }));
    }
    if (state.buying_group.length === 0) {
      setError((pre) => ({
        ...pre,
        buying_group: "Required",
      }));
    }
    if (state.group_title === "") {
      setError((pre) => ({
        ...pre,
        group_title: "Required",
      }));
    }
    if (state.address === "") {
      setError((pre) => ({
        ...pre,
        address: "Required",
      }));
    }
    if (state.city === "") {
      setError((pre) => ({
        ...pre,
        city: "Required",
      }));
    }
    if (state.state === "") {
      setError((pre) => ({
        ...pre,
        state: "Required",
      }));
    }
    if (state.pincode === "") {
      setError((pre) => ({
        ...pre,
        pincode: "Required",
      }));
    }
    if (state.country_id === "") {
      setError((pre) => ({
        ...pre,
        country_id: "Required",
      }));
    }

    if (
      state.username !== "" &&
      state.email !== "" &&
      // state.password !== "" &&
      // state.conPassword !== "" &&
      state.mobileNo !== "" &&
      state.first_name !== "" &&
      state.last_name !== "" &&
      state.company !== "" &&
      state.website !== "" &&
      state.about !== "" &&
      state.job_title !== "" &&
      state.business_type !== "" &&
      state.buying_group !== "" &&
      state.group_title !== "" &&
      state.address !== "" &&
      state.city !== "" &&
      state.state !== "" &&
      state.pincode !== "" &&
      state.country_id !== "" &&
      !error.email &&
      !error.mobileNo &&
      !error.other_phone
      // state.password === state.conPassword
    ) {
      props.merchantRegister({
        ...state,
        business_type: state.business_type.map((e) => e.value),
        buying_group: state.buying_group.map((e) => e.value),
      });
    }
  };

  return (
    <>
      <div className="auth-bg">
        <video src={videoUrl} autoPlay muted loop />
        <div className="auth-form">
          <h2 className="text-center">Merchant Registration</h2>
          <Form>
            {/* Login information */}
            <h3 className="mb-3">Login Information</h3>
            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">username * </Form.Label>
                  <Form.Control
                    type="text"
                    value={state.username}
                    onChange={handleChange}
                    name="username"
                    id="username"
                    placeholder=""
                  />
                  {error.username && (
                    <span className="form-error">{error.username}</span>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Email *</Form.Label>
                  <Form.Control
                    type="email"
                    value={state.email}
                    onChange={handleChange}
                    name="email"
                    id="email"
                    placeholder=""
                  />
                  {error.email && (
                    <span className="form-error">{error.email}</span>
                  )}
                </Form.Group>
              </Col>
              {/* <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Password *</Form.Label>
                  <div className="position-relative">
                    <Form.Control
                      type={showPass ? "text" : "password"}
                      value={state.password}
                      onChange={handleChange}
                      name="password"
                      id="password"
                      style={{ paddingRight: "40px" }}
                      placeholder=""
                    />
                    <i
                      className={`eye-btn position-absolute fa-regular ${
                        showPass ? "fa-eye-slash" : "fa-regular fa-eye"
                      }`}
                      onClick={() => setShowPass(!showPass)}
                      style={{ cursor: "pointer", top: "17px", right: "25px" }}
                    ></i>
                  </div>
                  {error.password && (
                    <span className="form-error">{error.password}</span>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Confirm Password *</Form.Label>
                  <div className="position-relative">
                    <Form.Control
                      type={showConPass ? "text" : "password"}
                      value={state.conPassword}
                      onChange={handleChange}
                      name="conPassword"
                      id="conPassword"
                      placeholder=""
                      style={{ paddingRight: "40px" }}
                    />
                    <i
                      className={`eye-btn position-absolute fa-regular ${
                        showConPass ? "fa-eye-slash" : "fa-regular fa-eye"
                      }`}
                      onClick={() => setShowConPass(!showConPass)}
                      style={{ cursor: "pointer", top: "17px", right: "25px" }}
                    ></i>
                  </div>
                  {error.conPassword && (
                    <span className="form-error">{error.conPassword}</span>
                  )}
                </Form.Group>
              </Col> */}
            </Row>

            {/* Personal information */}
            <h3 className="mt-5 mb-3">Personal Information</h3>
            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">First Name *</Form.Label>
                  <Form.Control
                    type="text"
                    value={state.first_name}
                    onChange={handleChange}
                    name="first_name"
                    id="first_name"
                    placeholder=""
                  />
                  {error.first_name && (
                    <span className="form-error">{error.first_name}</span>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Last Name *</Form.Label>
                  <Form.Control
                    type="text"
                    value={state.last_name}
                    onChange={handleChange}
                    name="last_name"
                    id="last_name"
                    placeholder=""
                  />
                  {error.last_name && (
                    <span className="form-error">{error.last_name}</span>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Company *</Form.Label>
                  <Form.Control
                    type="text"
                    value={state.company}
                    onChange={handleChange}
                    name="company"
                    id="company"
                    placeholder=""
                  />
                  {error.company && (
                    <span className="form-error">{error.company}</span>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Website *</Form.Label>
                  <Form.Control
                    type="text"
                    value={state.website}
                    onChange={handleChange}
                    name="website"
                    id="website"
                    placeholder=""
                  />
                  {error.website && (
                    <span className="form-error">{error.website}</span>
                  )}
                </Form.Group>
              </Col>
              <Col sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Country *</Form.Label>
                  {/* <Form.Control type="text" value={state.country} onChange={handleChange} name="country" id="country" placeholder="" /> */}
                  <Form.Select
                    style={{
                      width: "100%",
                    }}
                    id="country_id"
                    value={state.country_id}
                    onChange={handleChange}
                    name="country_id"
                  >
                    <option>Select Country</option>
                    {countryList.length === 0 ? (
                      <option>No Country Available</option>
                    ) : (
                      countryList?.map((data, i) => {
                        return (
                          <option key={i} value={data.id}>
                            {data.name}
                          </option>
                        );
                      })
                    )}
                  </Form.Select>

                  {error.country && (
                    <span className="form-error">{error.country}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">State *</Form.Label>
                  <Form.Control
                    type="text"
                    value={state.state}
                    onChange={handleChange}
                    name="state"
                    id="state"
                    placeholder=""
                  />
                  {error.state && (
                    <span className="form-error">{error.state}</span>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">City *</Form.Label>
                  <Form.Control
                    type="text"
                    value={state.city}
                    onChange={handleChange}
                    name="city"
                    id="city"
                    placeholder=""
                  />
                  {error.city && (
                    <span className="form-error">{error.city}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Adress *</Form.Label>
                  <Form.Control
                    type="text"
                    value={state.address}
                    onChange={handleChange}
                    name="address"
                    id="address"
                    placeholder=""
                  />
                  {error.address && (
                    <span className="form-error">{error.address}</span>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Pincode *</Form.Label>
                  <Form.Control
                    type="number"
                    value={state.pincode}
                    onChange={handleChange}
                    name="pincode"
                    id="pincode"
                    placeholder=""
                  />
                  {error.pincode && (
                    <span className="form-error">{error.pincode}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Mobile No. *</Form.Label>
                  <Form.Control
                    type="number"
                    maxLength={10}
                    value={state.mobileNo}
                    onChange={handleChange}
                    name="mobileNo"
                    id="mobileNo"
                    placeholder=""
                  />
                  {error.mobileNo && (
                    <span className="form-error">{error.mobileNo}</span>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Other Mobile No. *</Form.Label>
                  <Form.Control
                    type="number"
                    maxLength={10}
                    value={state.other_phone}
                    onChange={handleChange}
                    name="other_phone"
                    id="other_phone"
                    placeholder=""
                  />
                  {error.other_phone && (
                    <span className="form-error">{error.other_phone}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <h3 className="mt-5 mb-3">Bussiness Details</h3>
            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Job Title *</Form.Label>
                  <Form.Control
                    type="text"
                    value={state.job_title}
                    onChange={handleChange}
                    name="job_title"
                    id="job_title"
                    placeholder=""
                  />
                  {error.job_title && (
                    <span className="form-error">{error.job_title}</span>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Group Title *</Form.Label>
                  <Form.Control
                    type="text"
                    value={state.group_title}
                    onChange={handleChange}
                    name="group_title"
                    id="group_title"
                    placeholder=""
                  />
                  {error.group_title && (
                    <span className="form-error">{error.group_title}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Bussiness Type *</Form.Label>
                  <MultiSelect
                    options={bussinessTypeList}
                    disableSearch
                    value={state.business_type}
                    onChange={(e) =>
                      handleMultiSelectChange("business_type", e)
                    }
                    labelledBy="business_type"
                    hasSelectAll={false}
                    className="multi-form-select"
                  />
                  {error.business_type && (
                    <span className="form-error">{error.business_type}</span>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Buying Group *</Form.Label>
                  <MultiSelect
                    options={buyingGroupList}
                    disableSearch
                    hasSelectAll={false}
                    value={state.buying_group}
                    onChange={(e) => handleMultiSelectChange("buying_group", e)}
                    className="multi-form-select"
                  />
                  {error.buying_group && (
                    <span className="form-error">{error.buying_group}</span>
                  )}
                </Form.Group>
              </Col>
              <Col sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">About *</Form.Label>
                  <Form.Control
                    type="text"
                    style={{ width: "100%" }}
                    value={state.about}
                    onChange={handleChange}
                    name="about"
                    id="about"
                    placeholder=""
                  />
                  {error.about && (
                    <span className="form-error">{error.about}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Button
              type="button"
              onClick={submitForm}
              disabled={spinner}
              className="mt-5"
            >
              {spinner ? (
                <Spinner
                  as="span"
                  animation="border"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <>Submit</>
              )}
            </Button>
          </Form>
        </div>
        <div className="reg-text">
          Back to the
          <Link to="/" className="text-primary ms-2 fw-semibold">
            Main
          </Link>{" "}
          Page
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  merchantRegisterReducer: state.authReducer.merchantRegister,
  merchantCountryListReducer: state.authReducer.merchantCountryList,
});
const mapDispatchToProps = (dispatch) => ({
  merchantRegister: (details) => dispatch(merchantRegister(details)),
  getMerchantCountryList: () => dispatch(getMerchantCountryList()),
  resetRegister: () => dispatch({ type: MERCHANT_REGISTER_RESET }),
});
export default connect(mapStateToProps, mapDispatchToProps)(MerchantRegister);
