import React, { useEffect, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import videoUrl from "../assets/production ID_5106444.mp4";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { updateForgotPass } from "../userStore/common/apiSheet";
// import { resetPassword } from "../userStore/action/authAction";
const ResetPassword = (props) => {
  const [spinner, setSpinner] = useState(false);
  const navigate = useNavigate();
  const routeData = useLocation();
  useEffect(() => {
    if (routeData.state === null) {
      navigate("/login");
    }
  }, [routeData]);
  const [state, setState] = useState({
    password: "",
    conPassword: "",
  });
  const [error, setError] = useState({
    password: false,
    conPassword: false,
  });

  const handlechange = (e) => {
    const { id, name, value } = e.target;
    setState((prevstate) => ({
      ...prevstate,
      [id]: value,
    }));
    switch (name) {
      case "password":
        if (value.length === 0) {
          setError((prevstate) => ({
            ...prevstate,
            password: "Required",
          }));
        } else {
          setError((prevstate) => ({
            ...prevstate,
            password: false,
          }));
        }
        break;
      case "conPassword":
        if (value.length === 0) {
          setError((prevstate) => ({
            ...prevstate,
            conPassword: "Required",
          }));
        } else {
          setError((prevstate) => ({
            ...prevstate,
            conPassword: false,
          }));
        }
        break;
      default:
        break;
    }
  };

  const resetPassword = () => {
    if (state.password === "") {
      setError((prevstate) => ({
        ...prevstate,
        password: "Required",
      }));
    }
    if (state.conPassword === "") {
      setError((prevstate) => ({
        ...prevstate,
        conPassword: "Required",
      }));
    } else if (state.password !== state.conPassword) {
      setError((prevstate) => ({
        ...prevstate,
        conPassword: "Passwords did not match",
      }));
    }
    if (
      state.password !== "" &&
      state.conPassword !== "" &&
      state.password === state.conPassword
    ) {
      props.resetPassword({
        email: routeData.state,
        password: state.password,
      });
      setSpinner(true);
    }
  };

  useEffect(() => {
    if (props.resetpasswordReducer.success) {
      setSpinner(false);
      navigate("/login");
    } else {
      setSpinner(false);
    }
  }, [props.resetpasswordReducer]);

  return (
    <div className="auth-bg">
      <video src={videoUrl} autoPlay muted loop />
      <div className="auth-form">
        <h2 className="text-center">Reset Password</h2>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label className="mb-0">New Password</Form.Label>
            <Form.Control
              type="password"
              value={state.password}
              name="password"
              id="password"
              onChange={handlechange}
              placeholder=""
            />
            {error.password && (
              <span className="form-error">{error.password}</span>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="mb-0">Confirm Password</Form.Label>
            <Form.Control
              type="password"
              value={state.conPassword}
              name="conPassword"
              id="conPassword"
              onChange={handlechange}
              placeholder=""
            />
            {error.conPassword && (
              <span className="form-error">{error.conPassword}</span>
            )}
          </Form.Group>
          <Button
            type="button"
            onClick={resetPassword}
            disabled={spinner}
            className="mt-5"
          >
            {spinner ? (
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <>Reset Password</>
            )}
          </Button>
        </Form>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  resetpasswordReducer: state.authReducer.resetPass,
});

const mapDispatchToProps = (dispatch) => ({
  resetPassword: (details) => dispatch(updateForgotPass(details)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
