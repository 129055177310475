import React, { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { deleteAppointemnt, getAppointemnt } from "../userStore/action/appointmentAction";
import { ADD_APPOINYMENT_RESET, DELETE_APPOINYMENT_RESET } from "../userStore/common/constant";
import AppointmentModal from "./modals/AppointmentModal";
import AppointmentTable from "./Tables/AppointmentTable";
import Footer from "./userLayout/Footer";
import Header from "./userLayout/Header";
import { Modal } from "react-bootstrap";

const BookAppointment = (props) => {
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState({});
  // const [details, setDetails] = useState([]);
  const [IsDeleteCart, setIsDeleteCart] = useState(false);
  const handleDelete = () => {
    const ids = Object.keys(selected).filter((e) => {
      if (selected[e] === true) {
        return e;
      } else return false
    });

    props.deleteAppointemnt({
      appointment_id: JSON.stringify([...ids]),
    });
    setIsDeleteCart(false);
  };

  useEffect(() => {
    if (props.deleteAppointmentReducer.success) {
      props.getAppointemnt();
      toast.success("Selected appointment deleted Successfully..");
      setSelected({});
      props.resetDeleteAppointment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.deleteAppointmentReducer]);

  useEffect(() => {
    if (props.createAppointmentReducer.success) {
      props.getAppointemnt();
      setSelected({});
      props.resetCreateAppointment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.createAppointmentReducer]);

  // useEffect(() => {
  //   if (Object.keys(selected).length !== 0) {
  //     // const data = props.getAppointmentReducer.data;
  //     const idSelected = Object.keys(selected).filter((e) => {
  //       if (selected[e] === true) {
  //         return e;
  //       } else return false
  //     });
  //     // const _selected = idSelected.map((e) => {
  //     //   const list = data.find((data) => parseInt(data.id) === parseInt(e));
  //     //   return list;
  //     // });
  //     // setDetails([..._selected]);
  //   }
  // }, [selected, props.getAppointmentReducer.data]);

  return (
    <>
      <Header />
      <main className="userMain">
        <div className="d-flex justify-content-between  mt-5 mb-4 flex-wrap">
          <h3 className="heading mb-0">Book Appointment</h3>
          <div className="text-end mt-3 mt-sm-0 ms-auto navigate-buttons">
            <Button
              variant="secondary"
              className="me-sm-2 me-1"
              onClick={() => {
                const ids = Object.keys(selected).filter((e) => {
                  if (selected[e] === true) {
                    return e;
                  } else return false
                });
                if (ids.length !== 0) {
                  setIsDeleteCart(true);
                } else {
                  toast.error("Select list for delete appointment..");
                }
              }}
            >
              Delete
            </Button>
            {/* Book Appointment */}
            <Button
              variant="primary"
              onClick={() => {
                setShow(true);
              }}
              className="text-white"
            >
              Book Appointment
            </Button>
          </div>
        </div>
        <div className="content-block">
          <AppointmentTable setSelected={setSelected} />
        </div>
      </main>
      {show && (
        <AppointmentModal
          show={show}
          onHide={() => setShow(false)}
          setShow={setShow}
        />
      )}
      <Footer />
      <Modal
        show={IsDeleteCart}
        size="md"
        backdrop="static"
        onHide={() => setIsDeleteCart(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="p-4">
          <div className="">
            <div className="text-center my-5">
              <h5>Are you sure you want to delete this Data ?</h5>
            </div>
            <Modal.Footer className="mt-3">
              <Button
                className="py-2 px-4 border rounded pointer bg-secondary text-white mx-2 pointer"
                onClick={() => {
                  setIsDeleteCart(false);
                }}
              >
                Cancel
              </Button>
              <Button
                className="py-2 px-4 border rounded pointer bg-primary text-white mx-2 pointer"
                onClick={handleDelete}
              >
                Delete Appointment
              </Button>
            </Modal.Footer>
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  deleteAppointmentReducer: state.appointmentReducer.deleteAppointment,
  createAppointmentReducer: state.appointmentReducer.createAppointment,
  getAppointmentReducer: state.appointmentReducer.getAppointment,
});
const mapDispatchToProps = (dispatch) => ({
  getAppointemnt: () => dispatch(getAppointemnt()),
  deleteAppointemnt: (details) => dispatch(deleteAppointemnt(details)),
  resetDeleteAppointment: () => dispatch({ type: DELETE_APPOINYMENT_RESET }),
  resetCreateAppointment: () => dispatch({ type: ADD_APPOINYMENT_RESET }),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookAppointment);
