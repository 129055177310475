import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import GaugeChart from "react-gauge-chart";
import imageurl from "../images";
import Footer from "./userLayout/Footer";
import Header from "./userLayout/Header";
import { connect } from "react-redux";
import { getDashboardDetails, getMastersList } from "../userStore/action/masterAction";
import Loader from "../Components/Loader";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import moment from "moment";
import { Carousel } from "react-bootstrap";
import classNames from "classnames";
import { checkDiamondsTypeAction } from "../userStore/action/authAction";
import { getProfile } from "../userStore/action/authAction";
import {  starmeleeinquairyListAction, starmeleepriceAction } from "../userStore/action/StarmeleeAction";
import { ADD_STARMELEE_INQUAIRY_RESET } from "../userStore/common/constant";
import { CHECK_DIAMONDS_TYPE_RESET } from "../userStore/common/constant";

const DATA_ITEM_KEY = "id";
const Dashboard = (props) => {
  const navigate = useNavigate();
  const chartStyle = {
    minHeight: 184,
    width: "100%",
  };
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState({});
  const [state, setState] = useState({
    items: [],
    pageable: {
      buttonCount: 5,
      info: true,
      type: "numeric",
      pageSizes: true,
      previousNext: true,
    },
    total: 0,
    skip: 0,
    pageSize: 10,
  });

  const [showForm, setShowForm] = useState(false);
  const token = localStorage.getItem("token");
  const data = props.starmeleeCaratReducer.data;

  // const validEmailRegex = RegExp(
  //   /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  // );
  // const contactValidation = RegExp(/^(?!0+\s+,?$)\d{10}\s*,?$/);
  const initialSteps = {
    step1: true,
    step2: false,
    step3: false,
    step4: false,
    step5: false,
    step6: false,
    step7: false,
  };
  const [steps, setSteps] = useState(initialSteps);
  const initialDiamonsState = {
    diamondType: "",
    color: "",
    clarity: "",
    carat: "",
    price: "",
    quantity: "",
    total: "",
    name: "",
    email: "",
    number: "",
    shape: "",
    userID: "",
  };
  const [checkDiamondsState, setcheckDiamondsState] =
    useState(initialDiamonsState);

  const initialError = {
    shape_id: false,
    size_id: false,
    color_id: false,
    clarity_id: false,
    diamondType: false,
    color: false,
    clarity: false,
    carat: false,
    price: false,
    quantity: false,
    number: false,
    name: false,
    email: false,
  };
  const [errors, setErrors] = useState(initialError);

  const profileData = props.profileReducer.data;

  const [diamondsTypeState, setdiamondsTypeState] = useState(
    profileData?.diamond_type
  );

  // const [SelectedButton, setSelectedButton] = useState("natural");
  useEffect(() => {
    props.getDashboardDetails();
    setLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!props.masterListReducer.success) {
      props.getMastersList();
      setLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.masterListReducer.success) {
      setLoading(false);
    }
    //   const data = props.masterListReducer.data;
    //   setMasters({ ...data });
    // }
  }, [props.masterListReducer]);

  useEffect(() => {
    if (props.checkdiamondstypeReducer?.success) {
      props.getProfile();
      if (parseInt(diamondsTypeState) === 1) {
        navigate("/natural/search");
      } else if (parseInt(diamondsTypeState) === 2) {
        navigate("/labGrown/search");
      }
      props.resetcheckDiamondsTypeAction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.checkdiamondstypeReducer]);

  useEffect(() => {
    if (props.profileReducer?.success) {
      setdiamondsTypeState(profileData?.diamond_type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.profileReducer]);

  useEffect(() => {
    if (
      props.getDashboardDetailsReducer.success &&
      props.masterListReducer.success
    ) {
      const data = props.getDashboardDetailsReducer.data;
      const masterData = props.masterListReducer.data;
      const listData = {
        ...data,
        DiscountMeeter: (parseFloat(data.DiscountMeeter) / 100).toFixed(2),
        SearchList: data.SearchList.map((e) => {
          return {
            ...e,
            search: JSON.parse(e.search),
          };
        }),
      };
      setDetails(listData);

      const _details = listData.SearchList.map((e, i) => {
        const _data = {
          SrNo: i + 1,
          search_name: e.name,
          search_date: moment(e.created_at).format("DD/MM/yyyy HH:mm:ss A"),
          stone_id: !!e.search.stone_id ? e.search.stone_id : "N/A",
          DiamondType:
            !e.search?.diamond_type || e.search?.diamond_type?.length === 0
              ? "N/A"
              : JSON.parse(e.search?.diamond_type)?.map((e) => {
                return masterData?.DiamondType[e];
              }),
          Shape:
            !e.search?.shape_id || JSON.parse(e.search?.shape_id)?.length === 0
              ? "N/A"
              : JSON.parse(e.search?.shape_id)?.map((e) => {
                const list = masterData?.Shape?.find(
                  (item) => item.id === parseInt(e)
                );
                return list.name;
              }),
          Size:
            !e.search?.size_id || e.search?.size_id?.length === 0
              ? "N/A"
              : JSON.parse(e.search?.size_id)?.map((e) => {
                const list = masterData?.Size?.find(
                  (item) => item.id === parseInt(e)
                );
                return list.name;
              }),
          Color:
            !e.search?.color_id || e.search?.color_id?.length === 0
              ? "N/A"
              : JSON.parse(e.search?.color_id)?.map((e) => {
                const list = masterData?.Color?.find(
                  (item) => item.id === parseInt(e)
                );
                return list.name;
              }),
          Clarity:
            !e.search?.clarity_id || e.search?.clarity_id?.length === 0
              ? "N/A"
              : JSON.parse(e.search?.clarity_id)?.map((e) => {
                const list = masterData?.Clarity?.find(
                  (item) => item.id === parseInt(e)
                );
                return list?.name;
              }),
          Cut:
            !e.search?.cut_id || e.search?.cut_id?.length === 0
              ? "N/A"
              : JSON.parse(e.search?.cut_id)?.map((e) => {
                const list = masterData?.Finish?.find(
                  (item) => item.id === parseInt(e)
                );
                return list.name;
              }),
          Polish:
            !e.search?.polish_id || e.search?.polish_id?.length === 0
              ? "N/A"
              : JSON.parse(e?.search?.polish_id)?.map((e) => {
                const list = masterData?.Finish?.find(
                  (item) => item.id === parseInt(e)
                );
                return list.name;
              }),
          Symmentry:
            !e.search?.symmetry_id || e.search?.symmetry_id?.length === 0
              ? "N/A"
              : JSON.parse(e.search?.symmetry_id)?.map((e) => {
                const list = masterData?.Finish?.find(
                  (item) => item.id === parseInt(e)
                );
                return list.name;
              }),
          // status: e.status,
          id: e.id,
        };
        return _data;
      });
      setState((prev) => ({
        ...prev,
        items: [..._details],
      }));
      setLoading(false);
    }
  }, [props.getDashboardDetailsReducer, props.masterListReducer]);

  const pageChange = (event) => {
    setState((pre) => ({
      ...pre,
      items: pre.items.slice(
        event.page.skip,
        event.page.skip + event.page.take
      ),
      skip: event.page.skip,
      pageSize: event.page.take,
    }));
  };

  const handleDiamondsType = (e, type) => {
    const { value } = e.target;
    setdiamondsTypeState(value);
    // setSelectedButton(type);
    props.checkDiamondsTypeAction({
      diamond_type: value,
    });
  };

  useEffect(() => {
    if (props.starmeleePriceReducer.success) {
      const price = props.starmeleePriceReducer.data;
      setcheckDiamondsState((preve) => ({
        ...preve,
        price: parseInt(price[0]?.price),
      }));
    }
  }, [props.starmeleePriceReducer]);

  useEffect(() => {
    if (props.starmeleeCaratReducer.success) {
      // const data = props.starmeleeCaratReducer.data;
    }
  }, [props.starmeleeCaratReducer]);

  useEffect(() => {
    if (props.starmeleeInquairyReducer?.success) {
      props.starmeleeinquairyListAction();
      setTimeout(() => {
        setcheckDiamondsState(initialDiamonsState);
        setSteps(initialSteps);
        setErrors(initialError);
        setShowForm(false);
        props.resetInquiry();
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.starmeleeInquairyReducer]);

  return (
    <>
      <Header />
      <main className="userMain pt-5">
        <div className="d-grid gap-2 d-md-block ms-4">
          <button
            className={classNames("btn me-3", {
              "btn-primary text-light border": diamondsTypeState === 1,
            })}
            style={{
              fontWeight: "600",
              border: diamondsTypeState !== 1 && "2px solid #C2B362",
              color: diamondsTypeState !== 1 && "#C2B362",
            }}
            type="button"
            name="natural"
            value={"1"}
            onClick={(e) => {
              handleDiamondsType(e, "natural");
            }}
          >
            Natural Diamonds
          </button>
          <button
            className={classNames("btn me-3", {
              "btn-primary text-light": diamondsTypeState === 2,
            })}
            style={{
              fontWeight: "600",
              border: diamondsTypeState !== 2 && "2px solid #C2B362",
              color: diamondsTypeState !== 2 && "#C2B362",
            }}
            type="button"
            name="labgrown"
            value={"2"}
            onClick={(e) => {
              handleDiamondsType(e, "labgrown");
            }}
          >
            Lab Grown Diamonds
          </button>
        </div>
        {profileData?.diamond_type === 1 && (
          <>
            <div className="content-block bg-transparent">
              <div className="row align-items-stre">
                <div className="col-xl-8">
                  <div className="row">
                    <div className="col-xl-6 col-sm-6">
                      <div
                        onClick={() => {
                          navigate("/new-arrival", {
                            state: {
                              page: "new_arrival",
                            },
                          });
                        }}
                        className="card dashblock-card"
                      >
                        <Link className="text-dark">
                          <div className="card-body">
                            <h5 className="fw-normal text-muted mb-2">
                              New Arrival Stones
                            </h5>
                            <h2 className="fw-bold mb-0">
                              {details?.NewArrival}
                            </h2>
                            <span className="dashblock-icon">
                              <i className="ri-vip-diamond-line"></i>
                            </span>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className="col-xl-6 col-sm-6">
                      <div
                        onClick={() => {
                          navigate("/pairStone-list");
                        }}
                        className="card dashblock-card"
                      >
                        <Link className="text-dark">
                          <div className="card-body">
                            <h5 className="fw-normal text-muted mb-2">
                              Paired Stones
                            </h5>
                            <h2 className="fw-bold mb-0">
                              {details?.PairStone}
                            </h2>
                            <span className="dashblock-icon">
                              <i className="ri-scales-3-line"></i>
                            </span>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className="col-xl-6 col-sm-6">
                      <Link to="#" className="card dashblock-card">
                        <div className="card-body">
                          <h5 className="fw-normal text-muted mb-2">
                            Offer At Your Price(OAYP)
                          </h5>
                          <h2 className="fw-bold mb-0">
                            {details?.OfferAtYou}
                          </h2>
                          <span className="dashblock-icon">
                            <i className="ri-currency-line"></i>
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="col-xl-6 col-sm-6">
                      <Link to="/saved-search" className="card dashblock-card">
                        <div className="card-body">
                          <h5 className="fw-normal text-muted mb-2">
                            Saved Searches
                          </h5>
                          <h2 className="fw-bold mb-0">
                            {details?.SaveSearch}
                          </h2>
                          <span className="dashblock-icon">
                            <i className="ri-save-line"></i>
                          </span>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4">
                  <div className="card mt-0 dash-card">
                    <div className="card-body">
                      <h5 className="mb-4">Discount Meter</h5>
                      <GaugeChart
                        id="gauge-chart1"
                        nrOfLevels={2}
                        arcPadding={0.1}
                        cornerRadius={2}
                        percent={details?.DiscountMeeter}
                        colors={["#FF5F6D", "#FFC371"]}
                        textColor={"black"}
                        style={chartStyle}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Begin:: Ad banner */}
              {/* <div className="adBanner mt-4">
        <img src={imageurl.adBanner} alt="Banner" className="img-fluid" />
      </div> */}
              {!!details?.Marketing && (
                <Carousel>
                  {details?.Marketing.length !== 0 ? (
                    details?.Marketing.map((e, i) => {
                      return (
                        <Carousel.Item key={i}>
                          <img
                            className="d-block w-100"
                            src={e.image}
                            alt="First slide"
                          />
                        </Carousel.Item>
                      );
                    })
                  ) : (
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={imageurl.adBanner}
                        alt="First slide"
                      />
                    </Carousel.Item>
                  )}
                </Carousel>
              )}
              {/* End:: Ad banner */}

              <div className="mt-3">
                <Grid
                  style={{
                    height: "70vh",
                  }}
                  data={state.items.map((item) => ({
                    ...item,
                  }))}
                  skip={state.skip}
                  take={state.take}
                  total={state.total}
                  pageable={state.pageable}
                  pageSize={state.pageSize}
                  onPageChange={pageChange}
                  dataItemKey={DATA_ITEM_KEY}
                >
                  {/* <GridColumn field="view">sldkjklf</GridColumn> */}

                  <GridColumn field="SrNo" title="SrNo" width="75" />
                  <GridColumn
                    field="search_name"
                    title="Search Name"
                    width="200"
                  />
                  <GridColumn
                    field="search_date"
                    title="Search Date"
                    width="200"
                  />
                  <GridColumn field="stone_id" title="Stone ID" width="80" />
                  <GridColumn
                    field="DiamondType"
                    title="Diamond Type"
                    width="200"
                  />
                  <GridColumn field="Shape" title="Shape" width="80" />
                  <GridColumn field="Size" title="Size" width="80" />
                  <GridColumn field="Color" title="Color" width="80" />
                  <GridColumn field="Clarity" title="Clarity" width="80" />
                  <GridColumn field="Cut" title="Cut" width="80" />
                  <GridColumn field="Polish" title="Polish" width="80" />
                  <GridColumn field="Symmentry" title="Symmentry" width="150" />
                </Grid>
              </div>
              {/* End:: Recent search card */}
            </div>
          </>
        )}
        {profileData?.diamond_type === 2 && (
          <>
            <div className="content-block bg-transparent">
              <div className="row align-items-stre">
                <div className="col-xl-8">
                  <div className="row">
                    <div className="col-xl-6 col-sm-6">
                      <div
                        onClick={() => {
                          navigate("/new-arrival", {
                            state: {
                              page: "new_arrival"
                            },
                          });
                        }}
                        className="card dashblock-card"
                      >
                        <Link className="text-dark">
                          <div className="card-body">
                            <h5 className="fw-normal text-muted mb-2">
                              New Arrival Stones
                            </h5>
                            <h2 className="fw-bold mb-0">
                              {details?.NewArrival}
                            </h2>
                            <span className="dashblock-icon">
                              <i className="ri-vip-diamond-line"></i>
                            </span>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className="col-xl-6 col-sm-6">
                      <div
                        onClick={() => {
                          navigate("/pairStone-list");
                        }}
                        className="card dashblock-card"
                      >
                        <Link className="text-dark">
                          <div className="card-body">
                            <h5 className="fw-normal text-muted mb-2">
                              Paired Stones
                            </h5>
                            <h2 className="fw-bold mb-0">
                              {details?.PairStone}
                            </h2>
                            <span className="dashblock-icon">
                              <i className="ri-scales-3-line"></i>
                            </span>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className="col-xl-6 col-sm-6">
                      <Link to="#" className="card dashblock-card">
                        <div className="card-body">
                          <h5 className="fw-normal text-muted mb-2">
                            Offer At Your Price(OAYP)
                          </h5>
                          <h2 className="fw-bold mb-0">
                            {details?.OfferAtYou}
                          </h2>
                          <span className="dashblock-icon">
                            <i className="ri-currency-line"></i>
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="col-xl-6 col-sm-6">
                      <Link to="/saved-search" className="card dashblock-card">
                        <div className="card-body">
                          <h5 className="fw-normal text-muted mb-2">
                            Saved Searches
                          </h5>
                          <h2 className="fw-bold mb-0">
                            {details?.SaveSearch}
                          </h2>
                          <span className="dashblock-icon">
                            <i className="ri-save-line"></i>
                          </span>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4">
                  <div className="card mt-0 dash-card">
                    <div className="card-body">
                      <h5 className="mb-4">Discount Meter</h5>
                      <GaugeChart
                        id="gauge-chart1"
                        nrOfLevels={2}
                        arcPadding={0.1}
                        cornerRadius={2}
                        percent={details?.DiscountMeeter}
                        colors={["#FF5F6D", "#FFC371"]}
                        textColor={"black"}
                        style={chartStyle}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Begin:: Ad banner */}
              {/* <div className="adBanner mt-4">
        <img src={imageurl.adBanner} alt="Banner" className="img-fluid" />
      </div> */}
              {!!details?.Marketing && (
                <Carousel>
                  {details?.Marketing.length !== 0 ? (
                    details?.Marketing.map((e, i) => {
                      return (
                        <Carousel.Item key={i}>
                          <img
                            className="d-block w-100"
                            src={e.image}
                            alt="First slide"
                          />
                        </Carousel.Item>
                      );
                    })
                  ) : (
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={imageurl.adBanner}
                        alt="First slide"
                      />
                    </Carousel.Item>
                  )}
                </Carousel>
              )}
              {/* End:: Ad banner */}

              <div className="mt-3">
                <Grid
                  style={{
                    height: "70vh",
                  }}
                  data={state.items.map((item) => ({
                    ...item,
                  }))}
                  skip={state.skip}
                  take={state.take}
                  total={state.total}
                  pageable={state.pageable}
                  pageSize={state.pageSize}
                  onPageChange={pageChange}
                  dataItemKey={DATA_ITEM_KEY}
                >
                  {/* <GridColumn field="view">sldkjklf</GridColumn> */}

                  <GridColumn
                    field="SrNo"
                    title="SrNo"
                    className="text-center"
                    align="center"
                    width="50"
                  />
                  <GridColumn
                    field="search_name"
                    title="Search Name"
                    width="200"
                  />
                  <GridColumn
                    field="search_date"
                    title="Search Date"
                    width="200"
                  />
                  <GridColumn field="stone_id" title="Stone ID" width="250" />
                  <GridColumn
                    field="DiamondType"
                    title="Diamond Type"
                    width="200"
                  />
                  <GridColumn field="Shape" title="Shape" width="180" />
                  <GridColumn field="Size" title="Size" width="80" />
                  <GridColumn field="Color" title="Color" width="80" />
                  <GridColumn field="Clarity" title="Clarity" width="80" />
                  <GridColumn field="Cut" title="Cut" width="80" />
                  <GridColumn field="Polish" title="Polish" width="80" />
                  <GridColumn field="Symmentry" title="Symmentry" width="150" />
                </Grid>
              </div>
              {/* End:: Recent search card */}
            </div>
          </>
        )}
      </main>
      {loading && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            position: "fixed",
            zIndex: "9999",
            top: "0px",
            left: "0px",
            right: "0px",
            bottom: "0px",
            background: "rgba(0,0,0,0.4)",
          }}
        >
          <Loader />
        </div>
      )}
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => ({
  getDashboardDetailsReducer: state.masterReducer.getDashboardDetails,
  masterListReducer: state.masterReducer.masterList,
  profileReducer: state.authReducer.profile,
  checkdiamondstypeReducer: state.authReducer.checkDiamondsType,
  starmeleeReducer: state.starmeleeReducer.starmeleeDiamondList,
  starmeleeCaratReducer: state.starmeleeReducer.starmeleeCaratList,
  starmeleePriceReducer: state.starmeleeReducer.starmeleePriceList,
  starmeleeInquairyListReducer: state.starmeleeReducer.getstarmeleeInquairyList,
});
const mapDispatchToProps = (dispatch) => ({
  getDashboardDetails: (details) => dispatch(getDashboardDetails(details)),
  getProfile: () => dispatch(getProfile()),
  getMastersList: (payload) => dispatch(getMastersList(payload)),
  checkDiamondsTypeAction: (details) =>
    dispatch(checkDiamondsTypeAction(details)),
  starmeleeinquairyListAction: () => dispatch(starmeleeinquairyListAction()),  
  ResetstarmeleeReducer: () => dispatch({ type: ADD_STARMELEE_INQUAIRY_RESET }),
  resetcheckDiamondsTypeAction: () =>
    dispatch({ type: CHECK_DIAMONDS_TYPE_RESET }),
  resetInquiry: () => dispatch({ type: ADD_STARMELEE_INQUAIRY_RESET }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
// 3043
