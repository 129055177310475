import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import { Container, Form } from "react-bootstrap";
import {
  getstarmeleeDiamondAction,
  starmeleecaratAction,
  starmeleeinquairyAction,
  starmeleepriceAction,
} from "../userStore/action/StarmeleeAction";
import { connect } from "react-redux";
import classNames from "classnames";
import { getProfile } from "../userStore/action/authAction";
import { toast } from "react-toastify";
import { ADD_STARMELEE_INQUAIRY_RESET } from "../userStore/common/constant";


const StarMelee = (props) => {
  const token = localStorage.getItem("token");
  const data = props.starmeleeCaratReducer.data;
  const fancyData = props.starmeleeReducer.data;
  const profileData = props.profileReducer.data;
  // const validEmailRegex = RegExp(
  //   /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  // );
  // const contactValidation = RegExp(/^(?!0+\s+,?$)\d{10}\s*,?$/);
  const [steps, setSteps] = useState({
    step1: true,
    step2: false,
    step3: false,
    step4: false,
    step5: false,
    step6: false,
    step7: false,
  });

  const [checkDiamondsState, setcheckDiamondsState] = useState({
    diamondType: "",
    color: "",
    clarity: "",
    carat: "",
    price: "",
    quantity: "",
    total: "",
    name: "",
    email: "",
    number: "",
    shape: "",
    userID: "",
  });

  const [errors, setErrors] = useState({
    shape_id: false,
    size_id: false,
    color_id: false,
    clarity_id: false,
    diamondType: false,
    color: false,
    clarity: false,
    carat: false,
    price: false,
    quantity: false,
    number: false,
    name: false,
    email: false,
  });

  useEffect(() => {
    if (props.starmeleePriceReducer.success) {
      const price = props.starmeleePriceReducer.data;
      setcheckDiamondsState((preve) => ({
        ...preve,
        price: parseInt(price[0]?.price),
      }));
    }
  }, [props.starmeleePriceReducer]);

  useEffect(() => {
    if (props.starmeleeCaratReducer.success) {
      // const data = props.starmeleeCaratReducer.data
    }
  }, [props.starmeleeCaratReducer]);

  useEffect(() => {
    if (props.starmeleeInquairyReducer?.success) {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  }, [props.starmeleeInquairyReducer]);
  useEffect(() => {
    props.getstarmeleeDiamondAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const nameCheck = (e) => {
    e.preventDefault();
    if (checkDiamondsState.diamondType === "") {
      setErrors((prevState) => ({
        ...prevState,
        diamondType: "Required",
      }));
    } else {
      setSteps((prevState) => ({
        ...prevState,
        step1: false,
        step2: true,
      }));
    }
  };

  const shapeCheck = (e) => {
    e.preventDefault();
    if (checkDiamondsState.shape === "") {
      toast.error("Please Selact Diamonds Shape...");
      setErrors((prevState) => ({
        ...prevState,
        shape: "Required",
      }));
    } else {
      setSteps((prevState) => ({
        ...prevState,
        step2: false,
        step3: true,
      }));
    }
  };

  const colorCheck = (e) => {
    e.preventDefault();
    if (checkDiamondsState.color === "") {
      toast.error("Please Selact Color...");
      setErrors((prevState) => ({
        ...prevState,
        color: "Required",
      }));
    } else {
      setSteps((prevState) => ({
        ...prevState,
        step2: false,
        step3: true,
      }));
    }
  };

  const fancy_colorCheck = (e) => {
    e.preventDefault();
    if (checkDiamondsState.color === "") {
      toast.error("Please Selact Color...");
      setErrors((prevState) => ({
        ...prevState,
        color: "Required",
      }));
    } else {
      setSteps((prevState) => ({
        ...prevState,
        step3: false,
        step4: true,
      }));

    }
  };

  const clarityCheck = (e) => {
    e.preventDefault();
    if (checkDiamondsState.clarity === "") {
      setErrors((prevState) => ({
        ...prevState,
        clarity: "Required",
      }));
    } else {
      setSteps((prevState) => ({
        ...prevState,
        step3: false,
        step4: true,
      }));

    }
    props.starmeleecaratAction({
      color: checkDiamondsState.color,
      clarity: checkDiamondsState.clarity,
      diamond_type: profileData.diamond_type,
      shape:
        checkDiamondsState.diamondType === 1
          ? "round"
          : checkDiamondsState.shape,
    });
  };

  const fancy_clarityCheck = (e) => {
    e.preventDefault();
    if (checkDiamondsState.clarity === "") {
      setErrors((prevState) => ({
        ...prevState,
        clarity: "Required",
      }));
    } else {
      setSteps((prevState) => ({
        ...prevState,
        step4: false,
        step5: true,
      }));

    }
    props.starmeleecaratAction({
      color: checkDiamondsState.color,
      clarity: checkDiamondsState.clarity,
      diamond_type: profileData.diamond_type,
      shape:
        checkDiamondsState.diamondType === 1
          ? "round"
          : checkDiamondsState.shape,
    });
  };

  const caratCheck = (e) => {
    e.preventDefault();
    if (checkDiamondsState.carat === "") {
      setErrors((prevState) => ({
        ...prevState,
        carat: "Required",
      }));
    } else {
      setSteps((prevState) => ({
        ...prevState,
        step4: false,
        step5: true,
      }));

    }
    if (checkDiamondsState.carat !== "") {
      props.starmeleepriceAction({
        color: checkDiamondsState.color,
        clarity: checkDiamondsState.clarity,
        carat: checkDiamondsState.carat,
        diamond_type: profileData.diamond_type,
        shape:
          checkDiamondsState.diamondType === 1
            ? "round"
            : checkDiamondsState?.shape,
      });
    }
  };

  const fancy_caratCheck = (e) => {
    e.preventDefault();
    if (checkDiamondsState.carat === "") {
      setErrors((prevState) => ({
        ...prevState,
        carat: "Required",
      }));
    } else {
      setSteps((prevState) => ({
        ...prevState,
        step5: false,
        step6: true,
      }));

    }
    if (checkDiamondsState.carat !== "") {
      props.starmeleepriceAction({
        color: checkDiamondsState.color,
        clarity: checkDiamondsState.clarity,
        carat: checkDiamondsState.carat,
        diamond_type: profileData.diamond_type,
        shape:
          checkDiamondsState.diamondType === 1
            ? "round"
            : checkDiamondsState?.shape,
      });
    }
  };
  const step2Back = () => {
    setSteps((prevState) => ({
      ...prevState,
      step2: false,
      step1: true,
    }));
  };
  const step3Back = () => {
    setSteps((prevState) => ({
      ...prevState,
      step3: false,
      step2: true,
    }));
  };
  const step4Back = () => {
    setSteps((prevState) => ({
      ...prevState,
      step4: false,
      step3: true,
    }));
  };
  const step5Back = () => {
    setSteps((prevState) => ({
      ...prevState,
      step5: false,
      step4: true,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "round") {
      setcheckDiamondsState((preve) => ({
        ...preve,
        diamondType: "1",
      }));
    } else if (name === "fancy") {
      setcheckDiamondsState((preve) => ({
        ...preve,
        diamondType: "2",
      }));
    } else if (name === "DEF") {
      setcheckDiamondsState((preve) => ({
        ...preve,
        color: "DEF",
      }));
    } else if (name === "FG") {
      setcheckDiamondsState((preve) => ({
        ...preve,
        color: "FG",
      }));
    } else if (name === "pink") {
      setcheckDiamondsState((preve) => ({
        ...preve,
        color: "pink",
      }));
    } else if (name === "yellow") {
      setcheckDiamondsState((preve) => ({
        ...preve,
        color: "yellow",
      }));
    } else if (name === "blue") {
      setcheckDiamondsState((preve) => ({
        ...preve,
        color: "blue",
      }));
    } else if (name === "vvs_vs") {
      setcheckDiamondsState((preve) => ({
        ...preve,
        clarity: "vvs_vs",
      }));
    } else if (name === "vs_si") {
      setcheckDiamondsState((preve) => ({
        ...preve,
        clarity: "vs_si",
      }));
    } else if (name === "vvs_vs") {
      setcheckDiamondsState((preve) => ({
        ...preve,
        clarity: "vvs_vs",
      }));
    } else if (name === "vs_si") {
      setcheckDiamondsState((preve) => ({
        ...preve,
        clarity: "vs_si",
      }));
    } else if (name === "vvs_vs_si1") {
      setcheckDiamondsState((preve) => ({
        ...preve,
        clarity: "vvs_vs_si1",
      }));
    } else if (name === "carat") {
      setcheckDiamondsState((preve) => ({
        ...preve,
        carat: value,
      }));
    } else if (name === "quantity") {
      setcheckDiamondsState((preve) => ({
        ...preve,
        quantity: value === "" ? "" : value,
        total:
          value !== ""
            ? parseInt(checkDiamondsState.price) * parseInt(value)
            : parseInt(checkDiamondsState.price),
      }));
    } else {
      setcheckDiamondsState((preve) => ({
        ...preve,
        [name]: value,
      }));
    }

    switch (name) {
      case "diamondType":
        if (value === "") {
          setErrors((prevState) => ({
            ...prevState,
            diamondType: "Requaired",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            diamondType: false,
          }));
        }
        break;

      case "color":
        if (value === "") {
          setErrors((prevState) => ({
            ...prevState,
            color: "Requaired",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            color: false,
          }));
        }
        break;

      case "clarity":
        if (value === "") {
          setErrors((prevState) => ({
            ...prevState,
            clarity: "Requaired",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            clarity: false,
          }));
        }
        break;

      case "carat":
        if (value === "") {
          setErrors((prevState) => ({
            ...prevState,
            carat: "Requaired",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            carat: false,
          }));
        }
        break;

      case "name":
        if (value === "") {
          setErrors((prevState) => ({
            ...prevState,
            name: "Requaired",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            name: false,
          }));
        }
        break;

      case "email":
        if (value === "") {
          setErrors((prevState) => ({
            ...prevState,
            email: "Requaired",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            email: false,
          }));
        }
        break;

      case "number":
        if (value === "") {
          setErrors((prevState) => ({
            ...prevState,
            number: "Requaired",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            number: false,
          }));
        }
        break;

      case "quantity":
        if (value === "" || value === 0) {
          setErrors((prevState) => ({
            ...prevState,
            quantity: "Requaired",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            quantity: false,
          }));
        }
        break;

      default:
        break;
    }
  };

  const handleSubmit = (e) => {
    if (checkDiamondsState.name === "") {
      setErrors((prevState) => ({
        ...prevState,
        name: "Requaired",
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        name: false,
      }));
    }

    if (checkDiamondsState.email === "") {
      setErrors((prevState) => ({
        ...prevState,
        email: "Requaired",
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        email: false,
      }));
    }

    if (checkDiamondsState.number === "") {
      setErrors((prevState) => ({
        ...prevState,
        number: "Requaired",
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        number: false,
      }));
    }

    if (checkDiamondsState.quantity === "" || checkDiamondsState.quantity === 0) {
      setErrors((prevState) => ({
        ...prevState,
        quantity: "Requaired",
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        quantity: false,
      }));
    }

    if (
      checkDiamondsState?.quantity !== "" &&
      checkDiamondsState?.quantity !== "0"
    ) {
      if (
        token ||
        (checkDiamondsState.name !== "" &&
          checkDiamondsState.email !== "" &&
          checkDiamondsState.number !== "")
      ) {
        props.starmeleeinquairyAction({
          diamond_type: profileData.diamond_type,
          color: checkDiamondsState.color,
          clarity: checkDiamondsState.clarity,
          carat: checkDiamondsState.carat,
          price: checkDiamondsState.total,
          qty: checkDiamondsState.quantity,
          name: checkDiamondsState.name,
          email: checkDiamondsState.email,
          mobile: checkDiamondsState.number,
          ...(token && { user_id: profileData.id }),
          shape:
            checkDiamondsState.diamondType === 1
              ? "round"
              : checkDiamondsState?.shape,
        });
      }
    } else {
      setErrors((preve) => ({
        ...preve,
        quantity: "required",
      }));
    }
  };

  return (
    <>
      <Header />
      <Container fluid className="banner">
        <Container>
          <h2 className="pageName" data-aos="zoom-out" data-aos-duration="1000">
            Star Melee
          </h2>
        </Container>
      </Container>
      <section className="container">
        <Container className="position-relative partnership_open leadForm pt-0 mt-0">
          <h4 className="text-center fw-bold mb-5">Let's Get Inquiry</h4>
          <div className="box mx-auto inquiry-form">
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              {/* Step 1 */}
              {steps.step1 && (
                <>
                  {/* <Dropdown className="mb-5 d-flex justify-content-center">
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          Select Diamponds
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <div>
                            <Dropdown.Item
                              value="0"
                              name="round"
                              // onClick={() => {
                              //   setcheckDiamondsState({
                              //     diamondType: "1",
                              //   });
                              // }}
                              onClick={handleChange}
                            >
                              RoundDiamond
                            </Dropdown.Item>
                          </div>
                          <div>
                            <Dropdown.Item
                              value="1"
                              name="fancy"
                              // onClick={() => {
                              //   setcheckDiamondsState({
                              //     diamondType: "2",
                              //   });
                              // }}
                              onClick={handleChange}
                            >
                              Fancy Diamond
                            </Dropdown.Item>
                          </div>
                         
                          </div>
                        </Dropdown.Menu>
                      </Dropdown> */}
                  <label className="form-label mb-1">Diamond Type</label>
                  <Form.Select
                    name="diamondType"
                    onClick={handleChange}
                    className={classNames(
                      "starmelee-formselect mb-5",
                      {
                        requireField: errors.diamondType,
                      }
                    )}
                  >
                    <>
                      <option value="" name="">
                        Select Diamonds Type
                      </option>
                      <option value="1" name="round">
                        Round
                      </option>
                      <option value="2" name="fancy">
                        Fancy Layout
                      </option>
                    </>
                  </Form.Select>
                  {/* {errors?.diamondType && <span className="d-flex justify-content-center">{errors?.diamondType}</span>} */}

                  <div className="text-end">
                    <button
                      className="talk_btn btn-next"
                      type="submit"
                      onClick={nameCheck}
                    >
                      <span className="pe-2">Next</span>
                      <Icon icon="bi:arrow-right-circle" color="white" />
                    </button>
                  </div>
                </>
              )}
              {steps.step2 && (
                <>
                  {checkDiamondsState.diamondType === "1" && (
                    <>
                      <div className="row">
                        <div className="col-lg-3">
                          <Form.Group className="mb-3">
                            <Form.Label className="mb-1">
                              Diamond type
                            </Form.Label>
                            <Form.Control
                              disabled
                              value={
                                checkDiamondsState?.diamondType === "1"
                                  ? "Round"
                                  : "Fancy Layout"
                              }
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <Form.Label className="mb-1">
                        Diamond Color
                      </Form.Label>
                      <ul className="search-items-list mb-5">
                        <button
                          type="radio"
                          name="DEF"
                          value="DEF"
                          cursor="pointer"
                          onClick={handleChange}
                          className={classNames(
                            "btn starmelee_checkbox",
                            {
                              "btn-primary text-white":
                                checkDiamondsState.color === "DEF",
                            }
                          )}
                        >
                          DEF
                        </button>
                        <button
                          type="radio"
                          name="FG"
                          value="FG"
                          cursor="pointer"
                          onClick={handleChange}
                          className={classNames(
                            "btn starmelee_checkbox",
                            {
                              "btn-primary text-white":
                                checkDiamondsState.color === "FG",
                            }
                          )}
                        >
                          FG
                        </button>
                        <button
                          type="radio"
                          name="pink"
                          cursor="pointer"
                          value="pink"

                          onClick={handleChange}
                          className={classNames(
                            "btn starmelee_checkbox",
                            {
                              "btn-primary text-light":
                                checkDiamondsState.color === "pink",
                            }
                          )}
                        >
                          Pink
                        </button>
                        <button
                          type="radio"
                          name="yellow"
                          cursor="pointer"
                          value="yellow"
                          onClick={handleChange}
                          className={classNames(
                            "btn starmelee_checkbox",
                            {
                              "btn-primary text-light":
                                checkDiamondsState.color === "yellow",
                            }
                          )}
                        >
                          yellow
                        </button>
                        <button type="radio" name="blue"
                          cursor="pointer"
                          value="blue"
                          onClick={handleChange}
                          className={classNames(
                            "btn starmelee_checkbox",
                            {
                              "btn-primary text-light":
                                checkDiamondsState.color === "blue",
                            }
                          )}
                        >
                          Blue
                        </button>
                      </ul>
                      <div className="d-flex justify-content-between">
                        <button
                          type="button" className="btn btn-back" onClick={step2Back}>
                          <i className="fa-solid fa-arrow-left me-2"></i>
                          Back
                        </button>
                        <button
                          className="talk_btn btn-next"
                          type="submit"
                          onClick={colorCheck}
                        >
                          <span className="pe-2">Next</span>
                          <Icon
                            icon="bi:arrow-right-circle"
                            color="white"
                          />
                        </button>
                      </div>
                    </>
                  )}
                </>
              )}
              {steps.step3 && (
                <>
                  {checkDiamondsState.diamondType === "1" && (
                    <>
                      <div className="row gx-2">
                        <div className="col-lg-3">
                          <Form.Group className="mb-3">
                            <Form.Label className="mb-1">
                              Diamond type
                            </Form.Label>
                            <Form.Control
                              disabled
                              value={
                                checkDiamondsState?.diamondType === "1"
                                  ? "Round"
                                  : "Fancy Layout"
                              }
                            />
                          </Form.Group>
                        </div>
                        <div className="col-lg-3">
                          <Form.Group className="mb-3">
                            <Form.Label className="mb-1">
                              Diamond Color
                            </Form.Label>
                            <Form.Control
                              disabled
                              value={checkDiamondsState?.color}
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <Form.Label className="mb-1">
                        Diamond Clarity
                      </Form.Label>
                      <Form.Select
                        name="clarity"
                        onClick={handleChange}
                        className={classNames(
                          "mb-5 starmelee-formselect",
                          {
                            requireField: errors.clarity,
                          }
                        )}
                      >
                        <>
                          {checkDiamondsState.color === "DEF" && (
                            <>
                              <option value="" name="">
                                Select Clarity
                              </option>
                              <option value="vvs_vs" name="vvs_vs">
                                VVS_VS
                              </option>
                              <option value="vs_si" name="vs_si">
                                VS_SI
                              </option>
                            </>
                          )}
                          {checkDiamondsState.color === "FG" && (
                            <>
                              <option
                                value="vvs_vs"
                                name="vvs_vs"
                                disabled
                              >
                                Select Clarity
                              </option>
                              <option value="vvs_vs" name="vvs_vs">
                                VVS_VS
                              </option>
                              <option value="vs_si" name="vs_si">
                                VS_SI
                              </option>
                            </>
                          )}

                          {checkDiamondsState.color === "pink" && (
                            <>
                              <option
                                value="vvs_vs"
                                name="vvs_vs"
                                disabled
                              >
                                Select Clarity
                              </option>
                              <option
                                value="vvs_vs_si1"
                                name="vvs_vs_si1"
                              >
                                VVS-VS-SI1
                              </option>
                            </>
                          )}
                          {checkDiamondsState.color === "yellow" && (
                            <>
                              <option value="vvs_vs" name="vvs_vs">
                                Select Clarity
                              </option>
                              <option
                                value="vvs_vs_si1"
                                name="vvs_vs_si1"
                              >
                                VVS-VS-SI1
                              </option>
                            </>
                          )}
                          {checkDiamondsState.color === "blue" && (
                            <>
                              <option
                                value="vvs_vs"
                                name="vvs_vs"
                                disabled
                              >
                                Select Clarity
                              </option>
                              <option
                                value="vvs_vs_si1"
                                name="vvs_vs_si1"
                              >
                                VVS-VS-SI1
                              </option>
                            </>
                          )}
                        </>
                      </Form.Select>
                      <div className="d-flex justify-content-between">
                        <button
                          type="button"
                          className="btn btn-back"
                          onClick={step3Back}
                        >
                          <i className="fa-solid fa-arrow-left me-2"></i>
                          Back
                        </button>
                        <button
                          className="talk_btn btn-next"
                          type="submit"
                          onClick={clarityCheck}
                        >
                          <span className="pe-2">Next</span>
                          <Icon
                            icon="bi:arrow-right-circle"
                            color="white"
                          />
                        </button>
                      </div>
                    </>
                  )}
                </>
              )}
              {steps.step4 && (
                <>
                  {checkDiamondsState.diamondType === "1" && (
                    <>
                      <div className="row gx-2">
                        <div className="col-lg-3">
                          <Form.Group className="mb-3">
                            <Form.Label className="mb-1">
                              Diamond type
                            </Form.Label>
                            <Form.Control
                              disabled
                              value={
                                checkDiamondsState?.diamondType === "1"
                                  ? "Round"
                                  : "Fancy Layout"
                              }
                            />
                          </Form.Group>
                        </div>
                        <div className="col-lg-3">
                          <Form.Group className="mb-3">
                            <Form.Label className="mb-1">
                              Diamond Color
                            </Form.Label>
                            <Form.Control
                              disabled
                              value={checkDiamondsState?.color}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-lg-3">
                          <Form.Group className="mb-3">
                            <Form.Label className="mb-1">Diamond Clarity</Form.Label>
                            <Form.Control disabled value={checkDiamondsState?.clarity} />
                          </Form.Group>
                        </div>
                      </div>
                      <Form.Label className="mb-1">
                        Diamond Clarity
                      </Form.Label>
                      <Form.Select
                        name="carat"
                        onChange={handleChange}
                        className={classNames(
                          "mb-5 starmelee-formselect",
                          {
                            requireField: errors.carat,
                          }
                        )}
                      >
                        <option value="">Select Carat</option>
                        {data &&
                          data?.map((v, i) => {
                            return (
                              <>
                                <option value={v?.carat}>
                                  {v?.carat}
                                </option>
                              </>
                            );
                          })}
                      </Form.Select>

                      <div className="d-flex justify-content-between">
                        <button
                          type="button"
                          className="btn btn-back"
                          onClick={step4Back}
                        >
                          <i className="fa-solid fa-arrow-left me-2"></i>
                          Back
                        </button>
                        <button
                          className="talk_btn btn-next"
                          type="submit"
                          onClick={caratCheck}
                        >
                          <span className="pe-2">Next</span>
                          <Icon
                            icon="bi:arrow-right-circle"
                            color="white"
                          />
                        </button>
                      </div>
                    </>
                  )}
                </>
              )}
              {steps.step5 && (
                <>
                  {checkDiamondsState.diamondType === "1" && (
                    <>
                      {props.starmeleeInquairyReducer?.success ? (
                        <>
                          <div
                            className="d-flex justify-content-center text-success"
                            style={{ fontWeight: "bold", fontSize: "20px" }}
                          >
                            Inquairy Send Successfully...
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="row gx-2">
                            <div className="col-lg-3">
                              <Form.Group className="mb-3">
                                <Form.Label className="mb-1">Diamond type</Form.Label>
                                <Form.Control
                                  disabled
                                  value={
                                    checkDiamondsState?.diamondType === "1"
                                      ? "Round"
                                      : "Fancy Layout"
                                  }
                                />
                              </Form.Group>
                            </div>
                            <div className="col-lg-3">
                              <Form.Group className="mb-3">
                                <Form.Label className="mb-1">
                                  Diamond color
                                </Form.Label>
                                <Form.Control
                                  disabled
                                  value={checkDiamondsState?.color}
                                />
                              </Form.Group>
                            </div>
                            <div className="col-lg-3">
                              <Form.Group className="mb-3">
                                <Form.Label className="mb-1">Diamond Clarity</Form.Label>
                                <Form.Control
                                  disabled
                                  value={checkDiamondsState?.clarity}
                                />
                              </Form.Group>
                            </div>
                            <div className="col-lg-3">
                              <Form.Group className="mb-3">
                                <Form.Label className="mb-1">
                                  Diamond Carat
                                </Form.Label>
                                <Form.Control
                                  disabled
                                  value={checkDiamondsState?.carat}
                                />
                              </Form.Group>
                            </div>
                          </div>
                          <div className="d-flex justify-content-center mb-5">
                            <Form.Group className="mb-3">
                              <Form.Label className="mb-1">
                                Diamond Price
                              </Form.Label>
                              <Form.Control
                                disabled
                                value={checkDiamondsState?.price}
                              />
                            </Form.Group>
                            <div className="mx-3">
                              <Form.Label className="mb-1 invisible">1</Form.Label>
                              <h3 className="mt-2">*</h3>
                            </div>
                            <Form.Group className="">
                              <Form.Label className="mb-1">
                                Diamond Quantity
                              </Form.Label>
                              <Form.Control
                                type="number"
                                value={checkDiamondsState?.quantity}
                                name="quantity"
                                placeholder="Enter quantity"
                                onChange={handleChange}
                                className={classNames({
                                  requireField: errors.quantity,
                                })}
                              />
                            </Form.Group>
                            <div className="mx-3">
                              <Form.Label className="mb-1 invisible">1</Form.Label>
                              <h3 className="my-0">=</h3>
                            </div>

                            <div className="">
                              <Form.Group className="">
                                <Form.Label className="mb-1">
                                  Total
                                </Form.Label>
                                <Form.Control
                                  name="total"
                                  disabled
                                  value={
                                    checkDiamondsState.quantity === ""
                                      ? checkDiamondsState?.price
                                      : checkDiamondsState?.total
                                  }
                                />
                              </Form.Group>
                            </div>
                          </div>
                          {token ? (
                            <>
                              {/* <div className="">
                                <div className="mb-2">User ID</div>
                                <input
                                  placeholder="Enter UserID"
                                  name="userID"
                                  // value={checkDiamondsState.name}
                                  type="text"
                                  onChange={handleChange}
                                />
                              </div> */}
                            </>
                          ) : (
                            <>
                              <div className="d-flex justify-content-center mb-5">
                                <div className="me-3">
                                  <Form.Group >
                                    <Form.Label className="mb-2">
                                      Name
                                    </Form.Label>
                                    <Form.Control
                                      placeholder="Enter Name"
                                      name="name"
                                      value={checkDiamondsState.name}
                                      className={classNames({
                                        requireField: errors.name,
                                      })}
                                      type="text"
                                      onChange={handleChange}
                                    />
                                  </Form.Group>
                                </div>

                                <div className="me-3">
                                  <Form.Group >
                                    <Form.Label className="mb-2">
                                      Email
                                    </Form.Label>
                                    <Form.Control
                                      placeholder="Enter Email"
                                      name="email"
                                      value={checkDiamondsState.email}
                                      className={classNames({
                                        requireField: errors.email,
                                      })}
                                      type="text"
                                      onChange={handleChange}
                                    />
                                  </Form.Group>
                                </div>
                                <div className="me-3">
                                  <Form.Group className="">
                                    <Form.Label className="mb-2">
                                      Mo. Number
                                    </Form.Label>
                                    <Form.Control
                                      placeholder="Enter Number"
                                      name="number"
                                      value={checkDiamondsState.number}
                                      className={classNames({
                                        requireField: errors.number,
                                      })}
                                      type="number"
                                      onChange={handleChange}
                                    />
                                  </Form.Group>
                                </div>
                              </div>
                            </>
                          )}
                          <div className="d-flex justify-content-center">
                            <button
                              className="talk_btn w-50 btn-next mb-5"
                              type="submit"
                              onClick={handleSubmit}
                            >
                              <span className="pe-2">Submit</span>
                              {/* <Icon
                                    icon="bi:arrow-right-circle"
                                    color="white"
                                  /> */}
                            </button>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </>
              )}

              {/* ...................................................  fancy Diamond Section  ............................................. */}

              {steps.step2 && (
                <>
                  {checkDiamondsState.diamondType === "2" && (
                    <>
                      <div className="row">
                        <div className="col-lg-3">
                          <Form.Group className="mb-3">
                            <Form.Label className="mb-2">
                              Diamond type
                            </Form.Label>
                            <Form.Control
                              disabled
                              value={
                                checkDiamondsState?.diamondType === "1"
                                  ? "Round"
                                  : "Fancy Layout"
                              }
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <Form.Label className="mb-1">
                        Diamond shape
                      </Form.Label>
                      <ul className="search-items-list mb-5">
                        {fancyData?.map((v, i) => {
                          return (
                            <button
                              type="radio"
                              name="shape"
                              value={v?.shape}
                              cursor="pointer"
                              onClick={handleChange}
                              className={classNames(
                                "btn starmelee_checkbox",
                                {
                                  "btn-primary text-light": v.shape === checkDiamondsState.shape,
                                }
                              )}
                            >
                              {v.shape}
                            </button>
                          );
                        })}
                      </ul>
                      <div className="d-flex justify-content-between">
                        <button
                          type="button"
                          className="btn btn-back"
                          onClick={step2Back}
                        >
                          <i className="fa-solid fa-arrow-left me-2"></i>
                          Back
                        </button>
                        <button
                          className="talk_btn btn-next "
                          type="submit"
                          onClick={shapeCheck}
                        >
                          <span className="pe-2">Next</span>
                          <Icon
                            icon="bi:arrow-right-circle"
                            color="white"
                          />
                        </button>
                      </div>

                    </>
                  )}
                </>
              )}
              {steps.step3 && (
                <>
                  {checkDiamondsState.diamondType === "2" && (
                    <>
                      <div className="row gx-2">
                        <div className="col-lg-3">
                          <Form.Group className="mb-3" >
                            <Form.Label className="mb-1">
                              Diamond type
                            </Form.Label>
                            <Form.Control
                              disabled
                              value={
                                checkDiamondsState?.diamondType === "1"
                                  ? "Round"
                                  : "Fancy Layout"
                              }
                            />
                          </Form.Group>
                        </div>
                        <div className="col-lg-3">
                          <Form.Group className="mb-3">
                            <Form.Label className="mb-1">
                              Diamond Shape
                            </Form.Label>
                            <Form.Control
                              disabled
                              value={checkDiamondsState?.shape}
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <Form.Label className="mb-1">
                        Diamond Color
                      </Form.Label>
                      <ul className="search-items-list mb-5">
                        <button
                          type="radio"
                          name="DEF"
                          cursor="pointer"
                          onClick={handleChange}
                          className={classNames(
                            "btn starmelee_checkbox",
                            {
                              "btn-primary text-light":
                                checkDiamondsState.color === "DEF",
                            }
                          )}
                        >
                          DEF
                        </button>
                        <button
                          type="radio"
                          name="FG"
                          cursor="pointer"
                          onClick={handleChange}
                          className={classNames(
                            "btn starmelee_checkbox",
                            {
                              "btn-primary text-light":
                                checkDiamondsState.color === "FG",
                            }
                          )}
                        >
                          FG
                        </button>
                        <button
                          type="radio"
                          name="pink"
                          cursor="pointer"
                          onClick={handleChange}
                          className={classNames(
                            "btn starmelee_checkbox",
                            {
                              "btn-primary text-light":
                                checkDiamondsState.color === "pink",
                            }
                          )}
                        >
                          Pink
                        </button>
                        <button
                          type="radio"
                          name="yellow"
                          cursor="pointer"
                          onClick={handleChange}
                          className={classNames(
                            "btn starmelee_checkbox",
                            {
                              "btn-primary text-light":
                                checkDiamondsState.color === "yellow",
                            }
                          )}
                        >
                          yellow
                        </button>
                        <button
                          type="radio"
                          name="blue"
                          cursor="pointer"
                          onClick={handleChange}
                          className={classNames(
                            "btn starmelee_checkbox",
                            {
                              "btn-primary text-light":
                                checkDiamondsState.color === "blue",
                            }
                          )}
                        >
                          Blue
                        </button>
                      </ul>
                      <div className="d-flex justify-content-between">
                        <button
                          type="button"
                          className="btn btn-back"
                          onClick={step3Back}
                        >
                          <i className="fa-solid fa-arrow-left me-2"></i>
                          Back
                        </button>
                        <button
                          className="talk_btn btn-next"
                          type="submit"
                          onClick={fancy_colorCheck}
                        >
                          <span className="pe-2">Next</span>
                          <Icon
                            icon="bi:arrow-right-circle"
                            color="white"
                          />
                        </button>
                      </div>
                    </>
                  )}
                </>
              )}
              {steps.step4 && (
                <>
                  {checkDiamondsState.diamondType === "2" && (
                    <>
                      <div className="row gx-2">
                        <div className="col-lg-3 col-md-4 col-6">
                          <Form.Group className="mb-3">
                            <Form.Label className="mb-1">
                              Diamond type
                            </Form.Label>
                            <Form.Control
                              disabled
                              value={
                                checkDiamondsState?.diamondType === "1"
                                  ? "Round"
                                  : "Fancy Layout"
                              }
                            />
                          </Form.Group>
                        </div>
                        <div className="col-lg-3 col-md-4 col-6">
                          <Form.Group>
                            <Form.Label className="mb-1">
                              Diamond Shape
                            </Form.Label>
                            <Form.Control
                              disabled
                              value={checkDiamondsState?.shape}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-lg-3 col-md-4 col-6">
                          <Form.Group>
                            <Form.Label className="mb-1">
                              Diamond Color
                            </Form.Label>
                            <Form.Control
                              disabled
                              value={checkDiamondsState?.color}
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <div className="">
                        <Form.Label className="mb-1">Clarity</Form.Label>
                        <Form.Select
                          name="clarity"
                          onClick={handleChange}
                          className={classNames(
                            "mb-5 starmelee-formselect",
                            {
                              requireField: errors.clarity,
                            }
                          )}
                        >
                          <>
                            {checkDiamondsState.color === "DEF" && (
                              <>
                                <option value="" name="">
                                  Select Clarity
                                </option>
                                <option value="vvs_vs" name="vvs_vs">
                                  VVS_VS
                                </option>
                                <option value="vs_si" name="vs_si">
                                  VS_SI
                                </option>
                              </>
                            )}
                            {checkDiamondsState.color === "FG" && (
                              <>
                                <option
                                  value="vvs_vs"
                                  name="vvs_vs"
                                  disabled
                                >
                                  Select Clarity
                                </option>
                                <option value="vvs_vs" name="vvs_vs">
                                  VVS_VS
                                </option>
                                <option value="vs_si" name="vs_si">
                                  VS_SI
                                </option>
                              </>
                            )}

                            {checkDiamondsState.color === "pink" && (
                              <>
                                <option
                                  value="vvs_vs"
                                  name="vvs_vs"
                                  disabled
                                >
                                  Select Clarity
                                </option>
                                <option
                                  value="vvs_vs_si1"
                                  name="vvs_vs_si1"
                                >
                                  VVS-VS-SI1
                                </option>
                              </>
                            )}
                            {checkDiamondsState.color === "yellow" && (
                              <>
                                <option value="vvs_vs" name="vvs_vs">
                                  Select Clarity
                                </option>
                                <option
                                  value="vvs_vs_si1"
                                  name="vvs_vs_si1"
                                >
                                  VVS-VS-SI1
                                </option>
                              </>
                            )}
                            {checkDiamondsState.color === "blue" && (
                              <>
                                <option
                                  value="vvs_vs"
                                  name="vvs_vs"
                                  disabled
                                >
                                  Select Clarity
                                </option>
                                <option
                                  value="vvs_vs_si1"
                                  name="vvs_vs_si1"
                                >
                                  VVS-VS-SI1
                                </option>
                              </>
                            )}
                          </>
                        </Form.Select>

                      </div>
                      <div className="d-flex justify-content-between">
                        <button
                          type="button"
                          className="btn btn-back"
                          onClick={step4Back}
                        >
                          <i className="fa-solid fa-arrow-left me-2"></i>
                          Back
                        </button>
                        <button
                          className="talk_btn btn-next"
                          type="submit"
                          onClick={fancy_clarityCheck}
                        >
                          <span className="pe-2">Next</span>
                          <Icon
                            icon="bi:arrow-right-circle"
                            color="white"
                          />
                        </button>
                      </div>
                    </>
                  )}
                </>
              )}
              {steps.step5 && (
                <>
                  {checkDiamondsState.diamondType === "2" && (
                    <>
                      <div className="row gx-2">
                        <div className="col-lg-3 col-md-4 col-6">
                          <Form.Group className="mb-3">
                            <Form.Label className="mb-2">
                              Diamond type
                            </Form.Label>
                            <Form.Control
                              disabled
                              value={
                                checkDiamondsState?.diamondType === "1"
                                  ? "Round"
                                  : "Fancy Layout"
                              }
                            />
                          </Form.Group>
                        </div>
                        <div className="col-lg-3 col-md-4 col-6">
                          <Form.Group className="mb-3">
                            <Form.Label className="mb-2">
                              Diamond Shape
                            </Form.Label>
                            <Form.Control
                              disabled
                              value={checkDiamondsState?.shape}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-lg-3 col-md-4 col-6">
                          <Form.Group className="mb-3">
                            <Form.Label className="mb-2">
                              Diamond Color
                            </Form.Label>
                            <Form.Control
                              disabled
                              value={checkDiamondsState?.color}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-lg-3 col-md-4 col-6">
                          <Form.Group className="mb-3">
                            <Form.Label className="mb-2">
                              Diamond Clarity
                            </Form.Label>
                            <Form.Control
                              disabled
                              value={checkDiamondsState?.clarity}
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <Form.Label className="mb-1">
                        Carat
                      </Form.Label>
                      <Form.Select
                        name="carat"
                        onChange={handleChange}
                        className={classNames(
                          "mb-5 starmelee-formselect",
                          {
                            requireField: errors.carat,
                          }
                        )}
                      >
                        <option value="">Select carat</option>
                        {data &&
                          data?.map((v, i) => {
                            return (
                              <>
                                <option value={v?.carat}>
                                  {v?.carat}
                                </option>
                              </>
                            );
                          })}
                      </Form.Select>
                      <div className="d-flex justify-content-between">
                        <button
                          type="button"
                          className="btn btn-back"
                          onClick={step5Back}
                        >
                          <i className="fa-solid fa-arrow-left me-2"></i>
                          Back
                        </button>
                        <button
                          className="talk_btn btn-next"
                          type="submit"
                          onClick={fancy_caratCheck}
                        >
                          <span className="pe-2">Next</span>
                          <Icon
                            icon="bi:arrow-right-circle"
                            color="white"
                          />
                        </button>
                      </div>
                    </>
                  )}
                </>
              )}
              {steps.step6 && (
                <>
                  {checkDiamondsState.diamondType === "2" && (
                    <>
                      {props.starmeleeInquairyReducer?.success ? (
                        <div
                          className="d-flex justify-content-center text-success"
                          style={{ fontWeight: "bold", fontSize: "20px" }}
                        >
                          Inquairy Send Successfully...
                        </div>
                      ) : (
                        <>
                          <div className="row gx-2">
                            <div className="col-lg-3 col-md-4 col-6">
                              <Form.Group className="mb-3">
                                <Form.Label className="mb-1">
                                  Diamond type
                                </Form.Label>
                                <Form.Control
                                  disabled
                                  value={
                                    checkDiamondsState?.diamondType === "1"
                                      ? "Round"
                                      : "Fancy Layout"
                                  }
                                />
                              </Form.Group>
                            </div>
                            <div className="col-lg-3 col-md-4 col-6">
                              <Form.Group className="mb-3">
                                <Form.Label className="mb-1">
                                  Diamond Shape
                                </Form.Label>
                                <Form.Control
                                  disabled
                                  value={checkDiamondsState?.shape}
                                />
                              </Form.Group>
                            </div>
                            <div className="col-lg-3 col-md-4 col-6">
                              <Form.Group className="mb-3">
                                <Form.Label className="mb-1">
                                  Diamond Color
                                </Form.Label>
                                <Form.Control
                                  disabled
                                  value={checkDiamondsState?.color}
                                />
                              </Form.Group>
                            </div>
                            <div className="col-lg-3 col-md-4 col-6">
                              <Form.Group className="mb-3">
                                <Form.Label className="mb-1">
                                  Diamond Clarity
                                </Form.Label>
                                <Form.Control
                                  disabled
                                  value={checkDiamondsState?.clarity}
                                />
                              </Form.Group>
                            </div>
                            <div className="col-lg-6 col-md-6 col-12">
                              <Form.Group className="mb-3">
                                <Form.Label className="mb-1">
                                  Diamond Carat
                                </Form.Label>
                                <Form.Control
                                  disabled
                                  value={checkDiamondsState?.carat}
                                />
                              </Form.Group>
                            </div>
                          </div>
                          <div className="d-flex justify-content-center mb-5">
                            <div className="">
                              <Form.Group className="">
                                <Form.Label className="mb-1">
                                  Price
                                </Form.Label>
                                <Form.Control
                                  disabled
                                  value={checkDiamondsState?.price}
                                />
                              </Form.Group>
                            </div>
                            <div className="mx-3">
                              <Form.Label className="mb-1 invisible">1</Form.Label>
                              <h3 className="my-0 mt-2">*</h3>
                            </div>

                            <div className="">
                              <div>
                                <Form.Group className="">
                                  <Form.Label className="mb-1">
                                    Diamond Quantity
                                  </Form.Label>
                                  <Form.Control
                                    type="number"
                                    value={checkDiamondsState?.quantity}
                                    name="quantity"
                                    placeholder="Enter quantity"
                                    onChange={handleChange}
                                  />
                                </Form.Group>
                              </div>
                            </div>
                            <div className="mx-3">
                              <Form.Label className="mb-1 invisible">1</Form.Label>
                              <h3>=</h3>
                            </div>

                            <div className="me-5">
                              <Form.Group className="">
                                <Form.Label className="mb-2">
                                  Total
                                </Form.Label>
                                <Form.Control
                                  name="total"
                                  disabled
                                  value={
                                    checkDiamondsState.quantity === ""
                                      ? checkDiamondsState?.price
                                      : checkDiamondsState?.total
                                  }
                                />
                              </Form.Group>
                            </div>
                          </div>
                          {token ? (
                            <>
                              {/* <div className="">
                              <div className="mb-2">User ID</div>
                              <input
                                placeholder="Enter UserID"
                                name="userID"
                                // value={checkDiamondsState.name}
                                type="text"
                                onChange={handleChange}
                              />
                            </div> */}
                            </>
                          ) : (
                            <>
                              <div className="row mb-5">
                                <div className="col-md-4">
                                  <Form.Group className="mb-3">
                                    <Form.Label className="mb-1">
                                      Name
                                    </Form.Label>
                                    <Form.Control
                                      placeholder="Enter Name"
                                      name="name"
                                      value={checkDiamondsState.name}
                                      type="text"
                                      onChange={handleChange}
                                      className={classNames({
                                        requireField: errors.name,
                                      })}
                                    />
                                  </Form.Group>
                                </div>

                                <div className="col-md-4">
                                  <div className="mb-1">Email</div>
                                  <Form.Control
                                    placeholder="Enter Email"
                                    name="email"
                                    value={checkDiamondsState.email}
                                    type="text"
                                    onChange={handleChange}
                                    className={classNames({
                                      requireField: errors.email,
                                    })}
                                  />
                                </div>
                                <div className="col-md-4">
                                  <div className="mb-1">Mobile No.</div>
                                  <Form.Control
                                    placeholder="Enter Number"
                                    name="number"
                                    value={checkDiamondsState.number}
                                    type="number"
                                    onChange={handleChange}
                                    className={classNames({
                                      requireField: errors.number,
                                    })}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                          <div className="d-flex justify-content-center">
                            <button
                              className="talk_btn w-50 btn-next mb-5"
                              type="submit"
                              onClick={handleSubmit}
                            >
                              <span className="pe-2">Submit</span>
                              {/* <Icon
                              icon="bi:arrow-right-circle"
                              color="white"
                            /> */}
                            </button>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </form>
            {/* <div className="steps">
                  <ProgressBar now={now} label={`${now}%`} visuallyHidden />
                  <span className="ms-4 d-inline-block">{now}%</span>
                </div> */}
          </div>
        </Container>
      </section >
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => ({
  starmeleeReducer: state.starmeleeReducer.starmeleeDiamondList,
  starmeleeCaratReducer: state.starmeleeReducer.starmeleeCaratList,
  starmeleePriceReducer: state.starmeleeReducer.starmeleePriceList,
  starmeleeInquairyReducer: state.starmeleeReducer.starmeleeInquairyList,
  profileReducer: state.authReducer.profile,
});
const mapDispatchToProps = (dispatch) => ({
  getstarmeleeDiamondAction: () => dispatch(getstarmeleeDiamondAction()),
  getProfile: () => dispatch(getProfile()),
  starmeleecaratAction: (details) => dispatch(starmeleecaratAction(details)),
  starmeleepriceAction: (details) => dispatch(starmeleepriceAction(details)),
  ResetstarmeleeReducer: () => dispatch({ type: ADD_STARMELEE_INQUAIRY_RESET }),
  starmeleeinquairyAction: (details) =>
    dispatch(starmeleeinquairyAction(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StarMelee);
