import axios from "axios";
import { addAppointment_Url, deleteAppointment_Url, getAppointment_Url } from "../common/apiSheet";
import { ADD_APPOINYMENT_ERROR, ADD_APPOINYMENT_SUCCESS, DELETE_APPOINYMENT_ERROR, DELETE_APPOINYMENT_SUCCESS, GET_APPOINYMENT_ERROR, GET_APPOINYMENT_SUCCESS } from "../common/constant";

export const getAppointemnt = () => {
 return (dispatch) => {
  const requestOptions = {
   method: "GET",
   url: getAppointment_Url,
   headers: {
    'Authorization': `Bearer ${localStorage.getItem("token")}`
   }
  }
  axios(requestOptions)
   .then((resp) => {
    // localStorage.setItem('token', resp?.data.data.token)
    dispatch({
     type: GET_APPOINYMENT_SUCCESS,
     response: resp.data.data.result,
    });
   })
   .catch((error) => {
    let data = error.response;
    if (!error.response) {
     dispatch({
      type: GET_APPOINYMENT_ERROR,
      msg: error,
     });
    } else if (data.status === 422) {
     let validationError = data.data.message;
     dispatch({
      type: GET_APPOINYMENT_ERROR,
      msg: validationError,
     });
    }
   });
 };
};

export const addAppointemnt = (details) => {
 return (dispatch) => {
  const requestOptions = {
   method: "POST",
   url: addAppointment_Url,
   headers: {
    'Authorization': `Bearer ${localStorage.getItem("token")}`
   },
   data: details
  }
  axios(requestOptions)
   .then((resp) => {
    dispatch({
     type: ADD_APPOINYMENT_SUCCESS,
    });
   })
   .catch((error) => {
    let data = error.response;
    if (!error.response) {
     dispatch({
      type: ADD_APPOINYMENT_ERROR,
      msg: error,
     });
    } else if (data.status === 422) {
     let validationError = data.data.message;
     dispatch({
      type: ADD_APPOINYMENT_ERROR,
      msg: validationError,
     });
    }
   });
 };
};

export const deleteAppointemnt = (details) => {
 return (dispatch) => {
  const requestOptions = {
   method: "POST",
   url: deleteAppointment_Url,
   headers: {
    'Authorization': `Bearer ${localStorage.getItem("token")}`
   },
   data: details
  }
  axios(requestOptions)
   .then((resp) => {
    dispatch({
     type: DELETE_APPOINYMENT_SUCCESS,
    });
   })
   .catch((error) => {
    let data = error.response;
    if (!error.response) {
     dispatch({
      type: DELETE_APPOINYMENT_ERROR,
      msg: error,
     });
    } else if (data.status === 422) {
     let validationError = data.data.message;
     dispatch({
      type: DELETE_APPOINYMENT_ERROR,
      msg: validationError,
     });
    }
   });
 };
};
