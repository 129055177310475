import axios from "axios";
import { getDashboardDetails_Url, getDiamonrDetails, getNewArrivalList_Url, getSlotList_Url, masterList_Url } from "../common/apiSheet";
import { DIAMOND_DETAILS_ERROR, DIAMOND_DETAILS_RESET, DIAMOND_DETAILS_SUCCESS, GET_DASHBOARD_DETAILS_ERROR, GET_DASHBOARD_DETAILS_SUCCESS, GET_MASTERS_LIST_ERROR, GET_MASTERS_LIST_SUCCESS, GET_SLOT_ERROR, GET_SLOT_SUCCESS, NEW_ARRIVAL_LIST_ERROR, NEW_ARRIVAL_LIST_SUCCESS } from "../common/constant";

export const getMastersList = () => {
  return (dispatch) => {
    const requestOptions = {
      method: "GET",
      url: masterList_Url,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      }
    }
    axios(requestOptions)
      .then((resp) => {
        // localStorage.setItem('token', resp?.data.data.token)
        dispatch({
          type: GET_MASTERS_LIST_SUCCESS,
          response: resp.data.data.result,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_MASTERS_LIST_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: GET_MASTERS_LIST_ERROR,
            msg: validationError,
          });
        }
      });
  };
};

export const getDiamondDetails = (id) => {
  return (dispatch) => {
    const requestOptions = {
      method: "GET",
      url: `${getDiamonrDetails}/${id}`,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      }
    }
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: DIAMOND_DETAILS_SUCCESS,
          response: resp.data.data.result,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: DIAMOND_DETAILS_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: DIAMOND_DETAILS_RESET,
            msg: validationError,
          });
        }
      });
  };
};

export const getSlotDetails = () => {
  return (dispatch) => {
    const requestOptions = {
      method: "GET",
      url: `${getSlotList_Url}`,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      }
    }
    axios(requestOptions)
      .then((resp) => {
        // localStorage.setItem('token', resp?.data.data.token)
        dispatch({
          type: GET_SLOT_SUCCESS,
          response: resp.data.data.result,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_SLOT_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: GET_SLOT_ERROR,
            msg: validationError,
          });
        }
      });
  };
};

export const getNewArrivalList = (skip = 0) => {
  return (dispatch) => {
    const requestOptions = {
      method: "GET",
      url: `${getNewArrivalList_Url}?$count=true&$top=60&$skip=${skip}`,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      }
    }
    axios(requestOptions)
      .then((resp) => {
        // localStorage.setItem('token', resp?.data.data.token)
        dispatch({
          type: NEW_ARRIVAL_LIST_SUCCESS,
          response: resp.data.data.result,
          count: resp.data.data.count,
          details: {
            ProductIds: resp.data.data.ProductIds,
            Totalamount: resp.data.data.Totalamount,
            Totalcarat: resp.data.data.Totalcarat,
            Totaldiscount: resp.data.data.Totaldiscount,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: NEW_ARRIVAL_LIST_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: NEW_ARRIVAL_LIST_ERROR,
            msg: validationError,
          });
        }
      });
  };
};

export const getDashboardDetails = () => {
  return (dispatch) => {
    const requestOptions = {
      method: "GET",
      url: `${getDashboardDetails_Url}`,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      }
    }
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_DASHBOARD_DETAILS_SUCCESS,
          response: resp.data.data.result,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_DASHBOARD_DETAILS_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: GET_DASHBOARD_DETAILS_ERROR,
            msg: validationError,
          });
        }
      });
  };
};

