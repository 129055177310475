import React, { useEffect } from "react";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import { Button, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { EmailActivationAction } from "../userStore/action/EmailActivationAction";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { EMAIL_ACTIVAT_RESET } from "../userStore/common/constant";

const Activation = (props) => {
  const { id } = useParams();
  useEffect(() => {
    if (props.emailActivateReducer.success) {
      toast.success("Email Activate Successfully...");
      props.resetemailactivate();
    }
    if (props.emailActivateReducer.error) {
      toast.error(props.emailActivateReducer.errors);
      props.resetemailactivate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.emailActivateReducer]);
  
  const onSubmitInput = () => {
    props.EmailActivationAction(id);
  };

  return (
    <>
      <Header />
      <Container fluid className="banner">
        <Container>
          <h2 className="pageName" data-aos="zoom-out" data-aos-duration="1000">
            Activation
          </h2>
        </Container>
      </Container>
      <section className="container d-flex justify-content-center align-items-center">
        <Button onClick={onSubmitInput}>
          <span className=" text-light" style={{ fontWeight: "bold" }}>
            Email Activation
          </span>
        </Button>
      </section>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => ({
  emailActivateReducer: state.emailActivateReducer.emailActivate,
});
const mapDispatchToProps = (dispatch) => ({
  EmailActivationAction: (details) => dispatch(EmailActivationAction(details)),
  resetemailactivate: () => dispatch({ type: EMAIL_ACTIVAT_RESET }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Activation);
