import { combineReducers } from "redux";
import { RESET_STORE } from "../common/constant";
import authReducer from "./authReducer";
import demandReducer from "./demandReducer";
import masterReducer from "./masterReducer";
import cartReducer from "./cartReducer";
import inquiryReducer from "./inquiryReducer";
import exportFileReducer from "./exportFileReducer";
import holdStoneReducer from "./holdStoneReducer";
import confirmStoneReducer from "./confirmStoneReducer";
import viewRequestReducer from "./viewRequestReducer";
import labGrownReducer from "./labGrownReducer";
import appointmentReducer from "./appointmentReducer";
import saveSearchListReducer from "./saveSearchListReducer";
import SerachStoneReducer from "./searchStoneReducer";
import emailActivateReducer from "./EmailActivationreducer";
import starmeleeReducer from "./StarmeleeReducer";
import pairedStoneListReducer from "./pairedStoneListReducer";

// const rootReducer = combineReducers({
//       registration: RegisterReducer,
//       countrylisting: CountryReducer,
//       loginres: loginReducer,
// })

const appReducer = combineReducers({
  authReducer,
  demandReducer,
  masterReducer,
  cartReducer,
  inquiryReducer,
  exportFileReducer,
  holdStoneReducer,
  confirmStoneReducer,
  viewRequestReducer,
  appointmentReducer,
  labGrownReducer,
  saveSearchListReducer,
  SerachStoneReducer,
  emailActivateReducer,
  pairedStoneListReducer,
  starmeleeReducer
});

const rootReducer = (state, action) => {
  if (action.type === RESET_STORE) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
