import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import ForgotPassword from "./Auth/ForgotPassword";
import Login from "./Auth/Login";
import Register from "./Auth/Register";
import ResetPassword from "./Auth/ResetPassword";
import AboutUs from "./Components/AboutUs";
import ContactUs from "./Components/ContactUs";
import Knowlege from "./Components/Knowledge";
import LabGrownDiamond from "./Components/LabGrownDiamond";
import Main from "./Components/Main";
import Manufacture from "./Components/Manufacture";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import ReturnPolicy from "./Components/ReturnPolicy";
import TermsCondition from "./Components/TermsCondition";
import BookAppointment from "./user/BookAppointment";
import Cart from "./user/Cart";
import ChangePassword from "./user/ChangePassword";
import ConfirmStone from "./user/ConfirmStone";
import Dashboard from "./user/Dashboard";
import DemandList from "./user/DemandList";
import HoldedStone from "./user/HoldedStone";
import LabGrown from "./user/LabGrown";
import MyOffer from "./user/MyOffer";
import NotFound from "./user/NotFound";
import OfferAtYourPrice from "./user/OfferAtYourPrice";
import PairStone from "./user/PairStone";
import RecentSearch from "./user/RecentSearch";
import SavedSearch from "./user/SavedSearch";
import SearchResult from "./user/SearchResult";
import StoneDetail from "./user/StoneDetail";
import UserProfile from "./user/UserProfile";
import ViewRequest from "./user/ViewRequest";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { getProfile } from "./userStore/action/authAction";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { REGISTER_RESET } from "./userStore/common/constant";
import ViewSearchStone from "./Components/ViewSearchStone";
import Activation from "./Components/Activation";
import VerifyEmail from "./Components/VerifyEmail";
import Diamond_Natural from "./Components/Diamond_Natural";
import StarMelee from "./Components/StarMelee";
import VendorRegister from "./Auth/VendorRegister";
import MerchantRegister from "./Auth/MerchantRegister";
import VendorEmailActivation from "./Components/VendorEmailActivation";
import MerchantEmailActivation from "./Components/MerchantEmailActivation";
import PairedStoneList from "./user/PairedStoneList";
import StarMeleeTable from "./Components/StarMeleeTable";

localStorage.setItem("promotionPopup", false);

function App(props) {
  const token = localStorage.getItem("token");
  const location = useLocation();

  const navigate = useNavigate();
  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    if (token !== null) {
      props.getProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (props.loginReducer.success) {
      props.getProfile();
      navigate("/dashboard");
      toast.success("Login SuccessFully..");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.loginReducer]);

  useEffect(() => {
    if (props.registerReducer.success) {
      navigate("/verify-email");
      props.resetRegister();
      // toast.success('Register SuccessFully..')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.registerReducer]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (
      token === null &&
      // location.pathname !== "/login" &&
      location.pathname !== "/register" &&
      location.pathname !== "/forgotPassword" &&
      location.pathname !== "/resetPassword" &&
      location.pathname !== "/" &&
      location.pathname !== "/about" &&
      location.pathname !== "/manufacture" &&
      location.pathname !== "/LabGrownDiamond" &&
      location.pathname !== "/knowledge" &&
      location.pathname !== "/contact" &&
      location.pathname !== "/privacy-policy" &&
      location.pathname !== "/terms-condition" &&
      location.pathname !== "/return-policy" &&
      location.pathname !== "/verify-email" &&
      location.pathname !== "/diamond-natural" &&
      location.pathname !== "/star-melee" &&
      location.pathname !== "/vendor/register" &&
      location.pathname !== "/merchant/register" &&
      !location.pathname.match(/activation\/([^&]*)/)
    ) {
      navigate("/login");
    } else if (
      !!token &&
      (location.pathname === "/login" ||
        location.pathname === "/register" ||
        location.pathname === "/forgotPassword") // || location.pathname === '/'
    ) {
      navigate("/dashboard");
    } else if (token === null && location.pathname === "/") {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <div className="App">
      <Routes>
        <Route exact path="/" element={<Main />} />
        <Route exact path="/activation/:id" element={<Activation />} />
        <Route exact path="/vendor/activation/:id" element={<VendorEmailActivation />} />
        <Route exact path="/merchant/activation/:id" element={<MerchantEmailActivation />} />
        <Route exact path="/about" element={<AboutUs />} />
        <Route exact path="/manufacture" element={<Manufacture />} />
        <Route exact path="/LabGrownDiamond" element={<LabGrownDiamond />} />
        <Route exact path="/knowledge" element={<Knowlege />} />
        <Route exact path="/contact" element={<ContactUs />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/terms-condition" element={<TermsCondition />} />
        <Route exact path="/return-policy" element={<ReturnPolicy />} />
        <Route exact path="/verify-email" element={<VerifyEmail />} />
        <Route exact path="/diamond-natural" element={<Diamond_Natural />} />
        <Route exact path="/star-melee" element={<StarMelee />} />
        <Route exact path="/star-melee-dashboard" element={<StarMeleeTable />} />
        <Route exact path="/vendor/register" element={<VendorRegister />} />
        <Route exact path="/merchant/register" element={<MerchantRegister />} />
        <Route exact path="*" element={<Navigate to="/404" />} />
        {/* <Route exact path="/activation/:id" element={<Activation />} /> */}
        {token === null ? (
          <>
            {/* <Routes> */}
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/register" element={<Register />} />
            <Route exact path="/forgotPassword" element={<ForgotPassword />} />
            <Route exact path="/resetPassword" element={<ResetPassword />} />
            {/* <Route exact path="/activation/:id" element={<Activation />} /> */}
            <Route exact path="/verify-email" element={<VerifyEmail />} />
            <Route exact path="/star-melee" element={<StarMelee />} />
            {/* </Routes> */}
          </>
        ) : (
          <>
            {/* <Routes> */}
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route exact path="/labGrown/search" element={<LabGrown />} />
            <Route exact path="/natural/search" element={<LabGrown />} />
            <Route exact path="/search-result" element={<SearchResult />} />
            {/* <Route exact path='/new-arrival' element={<NewArrival />} /> */}
            <Route exact path="/new-arrival" element={<SearchResult />} />
            <Route exact path="/detail/:id" element={<StoneDetail />} />
            <Route exact path="/inquiry-list" element={<MyOffer />} />
            <Route exact path="/view-request" element={<ViewRequest />} />
            <Route exact path="/demand-list" element={<DemandList />} />
            <Route exact path="/recent-search" element={<RecentSearch />} />
            <Route exact path="/saved-search" element={<SavedSearch />} />
            <Route exact path="/viewStone" element={<ViewSearchStone />} />
            <Route exact path="/book-appointment" element={<BookAppointment />} />
            <Route exact path="/OAYP" element={<OfferAtYourPrice />} />
            <Route exact path="/pairStone-list" element={<PairedStoneList />} />
            <Route exact path="/pairStone" element={<PairStone />} />
            <Route exact path="/profile" element={<UserProfile />} />
            <Route exact path="/changePassword" element={<ChangePassword />} />
            <Route exact path="/cart" element={<Cart />} />
            <Route exact path="/confirmed" element={<ConfirmStone />} />
            <Route exact path="/holdedStone" element={<HoldedStone />} />
            <Route exact path="/404" element={<NotFound />} />
            {/* </Routes> */}
          </>
        )}
      </Routes>
    </div>
  );
}

const mapStateToProps = (state) => ({
  profileReducer: state.authReducer.profile,
  policyReducer: state.authReducer.policy,
  loginReducer: state.authReducer.login,
  registerReducer: state.authReducer.register,
});
const mapDispatchToProps = (dispatch) => ({
  getProfile: (payload) => dispatch(getProfile(payload)),
  resetRegister: (payload) => dispatch({ type: REGISTER_RESET }),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);