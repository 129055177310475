import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { addAppointemnt } from "../../userStore/action/appointmentAction";

const AppointmentModal = (props) => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    date: moment().format("YYYY-MM-DD"),
    time: moment().format("HH:mm"),
    remark: "",
  });
  const [error, setError] = useState({
    date: false,
    time: false,
    remark: false,
  });

  const handleInput = (e) => {
    const { name, value } = e.target;
    setState((pre) => ({
      ...pre,
      [name]: value,
    }));

    switch (name) {
      case "date":
        if (value === "") {
          error["date"] = "required";
        } else {
          error["date"] = false;
        }
        break;

      case "time":
        if (value === "") {
          error["time"] = "required";
        } else {
          error["time"] = false;
        }
        break;
      case "remark":
        if (value === "") {
          error["remark"] = "required";
        } else {
          error["remark"] = false;
        }
        break;
      default:
        break;
    }
  };

  const handleSubmit = () => {
    if (state.date === "") {
      error["date"] = "required";
    } else if (state.time === "") {
      error["time"] = "required";
    } else if (state.remark === "") {
      error["remark"] = "required";
    }

    if (state.date !== "" && state.time !== "" && state.remark !== "") {
      props.addAppointemnt({
        date: state.date,
        time: state.time,
        remark: state.remark,
      });
    }
  };

  useEffect(() => {
    if (props.createAppointmentReducer.success) {
      props.setShow(false);
      toast.success("Appointment booked successfully….");
    }
  }, [props.createAppointmentReducer]);

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Book Appointment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={6} className="mb-3">
            <label>Appointment Date</label>
            <input
              type="date"
              name="date"
              placeholder=""
              className="form-control"
              value={state.date}
              onChange={handleInput}
            />
            {error.date && <span className="form-error">{error.date}</span>}
          </Col>
          <Col md={6} className="mb-3">
            <label>Appointment Time</label>
            <input
              type="time"
              placeholder=""
              className="form-control"
              value={state.time}
              name="time"
              onChange={handleInput}
            />
            {error.time && <span className="form-error">{error.time}</span>}
          </Col>
          <Col md={12} className="mb-3">
            <label>Remark</label>
            <textarea
              className="form-control"
              name="remark"
              rows={4}
              maxLength={"256"}
              value={state.remark}
              onChange={handleInput}
            ></textarea>
            <div className="d-flex justify-content-between align-items-center">
              {error.remark && (
                <span className="form-error">{error.remark}</span>
              )}
              <span
                className="text-end text-muted mt-2"
                style={{ fontSize: "14px" }}
              >
                {256 - state.remark.length} characters left
              </span>
            </div>
          </Col>
        </Row>
        <Button
          className="btn btn-primary text-white ms-auto d-block"
          onClick={handleSubmit}
          type="submit"
        >
          Submit
        </Button>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  createAppointmentReducer: state.appointmentReducer.createAppointment,
});
const mapDispatchToProps = (dispatch) => ({
  addAppointemnt: (details) => dispatch(addAppointemnt(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentModal);
