import React from "react";
import { Container } from "react-bootstrap";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";

const TermsCondition = () => {
  return (
    <> 
      <Header />
      <Container fluid className="banner">
        <Container>
          <h2 className="pageName" data-aos="zoom-out" data-aos-duration="1000">
            Terms & Condition
          </h2>
        </Container>
      </Container>
      <section className="container">
        <p className="mb-5" data-aos="zoom-out-up" data-aos-duration="1000">
          We are Working On the Motto “Ethics & Honesty Having More Value than
          Money”
        </p>

        <h2 className="heading" data-aos="fade-right" data-aos-duration="1000">
          Customer Satisfaction
        </h2>

        <p className="mb-4" data-aos="zoom-out-up" data-aos-duration="1000">
          “DELIGHT DIAMONDS” is an established institution in the diamond
          business since 2021. We are members of the Surat Diamond Bourse
          Diamond, Polygon, and our sterling reputation is international. We are
          truly dedicated to satisfying our customers as best as we can with our
          prompt and reliable service and hope to make this your most
          comfortable online shopping experience.
        </p>

        <p className="mb-4" data-aos="zoom-out-up" data-aos-duration="1000">
          Upon request, we will provide a complete and accurate description
          compiled by our highly experienced gemmologists / graders for all
          purchases. We also provide Make to order and customization on as per
          requirement.
        </p>

        <p data-aos="zoom-out-up" data-aos-duration="1000">
          All diamonds, and jewelry sold by Delight Diamonds are authentically
          Sourced as specified and of a consistently high quality and value.
          Independent laboratory certificates from GIA, IGI, EGL, AGS, HRD or
          other are obtainable on request for all diamonds and colored diamonds.
        </p>
      </section>
      <Footer />
    </>
  );
};

export default TermsCondition;
