import axios from "axios";
import { emailExcel_Url } from "../common/apiSheet";
import { EMAIL_EXCEL_ERROR, EMAIL_EXCEL_SUCCESS } from "../common/constant";

export const EmailExcelAction = (ID) => {
  return (dispatch) => {
    const requestOptions = {
      method: "GET",
      url: `${emailExcel_Url}${JSON.stringify(ID)}`,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: EMAIL_EXCEL_SUCCESS,
          response: resp.data.data.result,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: EMAIL_EXCEL_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: EMAIL_EXCEL_ERROR,
            msg: validationError,
          });
        }
      });
  };
};
