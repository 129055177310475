import React, { useEffect, useState } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { GET_SEARCH_STONE_RESET, LOGOUT_RESET } from "../../userStore/common/constant";
import Sidebar from "./Sidebar";
import { checkTerms, getPolicy, logout } from "../../userStore/action/authAction";
import { serachStoneAction } from "../../userStore/action/SerachStone";
import { toast } from "react-toastify";

const Header = (props) => {
  const navigate = useNavigate();
  // const [show, setShow] = useState(false);
  const [details, setDetails] = useState(null);
  // const [loading, setLoading] = useState(false);
  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const [userName, setUserName] = useState(null);
  const [isLogout, setIsLogout] = useState(false);
  const [Terms, setTerms] = useState(false);
  const pilicyData = props.policyReducer.data;
  const [state, setState] = useState({
    stone_id: "",
  });


  const [diamondType, setDiamondType] = useState(null);

  useEffect(() => {
    if (props.profileReducer.success) {
      setDetails(props.profileReducer.data);
      setDiamondType(props.profileReducer.data.diamond_type);
      const name = props.profileReducer.data.fullname;
      const _name = name?.split(" ");
      if (_name.lenght === 1) {
        setUserName(name.slice(0, 2));
      } else {
        setUserName(`${_name[0][0]}${_name[0][1]}`);
      }
    }
    props.getPolicy();
    if (props.profileReducer.data?.terms === 0) {
      setTerms(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.profileReducer]);

  const handleLogout = () => {
    props.logout();
    navigate("/");
  };

  useEffect(() => {
    if (props.logoutReducer.success) {
      navigate("/");
      props.resetLogout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.logoutReducer]);

  useEffect(() => {
    if (props.SerachStoneReducer.success) {
      if (props.SerachStoneReducer.data.length !== 0) {
        const id = props.SerachStoneReducer.data[0]?.id;
        props.resetserchStone();
        // setLoading(false);
        navigate(`/detail/${id}`);
      } else {
        toast.error("Data Not Found ");
        props.resetserchStone();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.SerachStoneReducer]);

  const inputChange = (e) => {
    const { value } = e.target;
    setState((preve) => ({
      ...preve,
      stone_id: value,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleSubmit = () => {
    if (state.stone_id === "") {
      toast.error("Enter ID");
    }
    if (state.stone_id !== "") {
      // setLoading(true);
      props.serachStoneAction({
        stone_id: state.stone_id,
      });
    }
  };

  const handleTerms = () => {
    // setLoading(true);
    props.checkTerms({
      version: pilicyData?.version,
    });
  };

  useEffect(() => {
    if (props.CheckTermsReducer.success) {
      setTerms(false);
    }
  }, [props.CheckTermsReducer]);

  return (
    <>
      <header className="user-Header">
        <div className="d-flex align-items-center">
          <img
            style={{ cursor: "pointer" }}
            src={"/images/headerLogo.png"}
            className="img-fluid logo"
            alt="Logo"
            onClick={() => {
              navigate("/dashboard");
            }}
          />
          <form>
            <div className="globalSearch">
              <input
                type="text"
                name="stone_id"
                value={state.stone_id}
                onChange={inputChange}
                className="form-control"
                placeholder="search for Stone ID, Pair ID, Certificate"
                id="search"
              />

              <button
                className="btn btn-search"
                type="button"
                onClick={handleSubmit}
              >
                <i className="ri-search-line"></i>
              </button>
            </div>
          </form>
        </div>
        <div className="d-flex align-items-center me-3 ">
          {/* {!!diamondType && (
            <div
              className=" px-3 py-2 rounded text-white"
              style={{ background: "#C2B362" }}
            >
              {parseInt(diamondType) === 1
                ? "Natural Diamonds"
                : parseInt(diamondType) === 2
                  ? "Lab-Grown Diamonds"
                  : parseInt(diamondType) === 3
                    ? "Star Melee Diamonds"
                    : ""}
            </div>
          )} */}
          <Link to="/cart" className="btn">
            <i className="ri-shopping-cart-2-line h5 mb-0"></i>
          </Link>
          <Dropdown
            className="profile-drp notification-drp"
            drop="down-right"
            align="end"
          >
            <Dropdown.Toggle id="profile">
              <i className="fa-regular fa-bell h5 mb-0"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>
                <h5 className="mb-0">Notifications</h5>
              </Dropdown.Item>
              <Dropdown.Item className="noti-item new">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                <span className="noti-time">Now</span>
              </Dropdown.Item>
              <Dropdown.Item className="noti-item">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                <span className="noti-time">12:46 PM</span>
              </Dropdown.Item>
              <Dropdown.Item className="noti-item">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                <span className="noti-time">12:46 PM</span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown className="profile-drp">
            <Dropdown.Toggle id="profile">
              <span className="profile-icon">{!!details && userName}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href="#" onClick={() => navigate("/profile")}>
                <i className="fa-solid fa-user me-2 text-primary"></i> Change
                Profile
              </Dropdown.Item>
              <Dropdown.Item
                href="#"
                onClick={() => navigate("/changePassword")}
              >
                <i className="fa-solid fa-unlock me-2 text-primary"></i> Change
                Password
              </Dropdown.Item>
              {/* <Dropdown.Item href="#/action-3"><i className="fa-solid fa-table-columns me-2 text-primary"></i>Set Column result</Dropdown.Item> */}
              <Dropdown.Item href="/">
                <i className="fa-solid fa-address-card me-2 text-primary"></i>
                Website Profile
              </Dropdown.Item>
              <Dropdown.Item href="#" onClick={() => setIsLogout(true)}>
                <i className="fa-solid fa-arrow-right-from-bracket me-2 text-primary"></i>
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </header>

      <Sidebar setIsLogout={setIsLogout} />

      {/* logout */}
      <Modal
        show={isLogout}
        onHide={() => setIsLogout(false)}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <div className="text-center">Are you sure you want to logout?</div>
        </Modal.Body>
        <Modal.Footer className="border-top-0">
          <Button variant="secondary" onClick={() => setIsLogout(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            className="text-white"
            onClick={handleLogout}
          >
            Logout
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={Terms}
        onHide={() => setTerms(false)}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>Terms & Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>{pilicyData?.description}</div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleTerms}>Accept</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  profileReducer: state.authReducer.profile,
  policyReducer: state.authReducer.policy,
  logoutReducer: state.authReducer.logout,
  searchDiamondsReducer: state.labGrownReducer.searchDiamonds,
  getNewArrivalListReducer: state.masterReducer.getNewArrivalList,
  SerachStoneReducer: state.SerachStoneReducer.Search,
  CheckTermsReducer: state.authReducer.checkTerms,
});
const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  getPolicy: () => dispatch(getPolicy()),
  serachStoneAction: (details) => dispatch(serachStoneAction(details)),
  checkTerms: (details) => dispatch(checkTerms(details)),
  resetLogout: () => dispatch({ type: LOGOUT_RESET }),
  resetserchStone: () => dispatch({ type: GET_SEARCH_STONE_RESET }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
