import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom/dist";
import videoUrl from "../assets/production ID_5106444.mp4";
import { getCountry, register } from "../userStore/action/authAction";
import { REGISTER_RESET } from "../userStore/common/constant";
// { submitRegForm, regsubmit, countryList, countries }
const Register = (props) => {
  const navigate = useNavigate();

  const [spinner, setSpinner] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [showConPass, setShowConPass] = useState(false);
  const [countryList, setCountryList] = useState([]);

  const [state, setState] = useState({
    userName: "",
    email: "",
    password: "",
    conPassword: "",
    name: "",
    country: "",
    company: "",
    mobileNo: "",
    whatsapp: "",
    wechat: "",
    skype: "",
    proof: "",
  });

  const [upload_document, setUpload_document] = useState(null);
  const [error, setError] = useState({
    userName: false,
    email: false,
    password: false,
    conPassword: false,
    name: false,
    country: false,
    company: false,
    mobileNo: false,
    whatsapp: false,
    wechat: false,
    skype: false,
    proof: false,
  });

  const [contact, setContact] = useState(false);

  useEffect(() => {
    props.countryList();
  }, []);

  useEffect(() => {
    if (props.countryReducer.success) {
      setCountryList([...props.countryReducer.data]);
    }
  }, [props.countryReducer]);

  useEffect(() => {
    if (props.registerReducer.success) {
      setSpinner(false);
      navigate("/verify-email")
    } else if (props.registerReducer.error) {
      setSpinner(false);
    }
  }, [props.registerReducer]);

  const handleChange = (e) => {
    const { id, value, name } = e.target;
    const validEmailRegex = RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );
    const contactValidation = RegExp(/^(?!0+\s+,?$)\d{10}\s*,?$/);

    if (name === "whatsapp" || name === "mobileNo") {
      if (value.length <= 10) {
        setState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      } else return;
    } else {
      setState((prevState) => ({
        ...prevState,
        [id]: value,
      }));
    }
    if (name === "proof") {
      setUpload_document(e.target.files[0]);
    }
    switch (name) {
      case "userName":
        if (value.length === 0) {
          setError((prevState) => ({
            ...prevState,
            userName: "Required",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            userName: false,
          }));
        }
        break;
      case "email":
        if (value.length === 0) {
          setError((prevState) => ({
            ...prevState,
            email: "Required",
          }));
        } else if (!validEmailRegex.test(value)) {
          setError((prevState) => ({
            ...prevState,
            email: "Invalid Email",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            email: false,
          }));
        }
        break;
      case "password":
        if (value.length === 0) {
          setError((prevState) => ({
            ...prevState,
            password: "Required",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            password: false,
          }));
        }
        break;
      case "conPassword":
        if (value.length === 0) {
          setError((prevState) => ({
            ...prevState,
            conPassword: "Required",
          }));
        } else if (value !== state.password) {
          setError((prevState) => ({
            ...prevState,
            conPassword: "Passwords did not match",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            conPassword: false,
          }));
        }
        break;
      case "name":
        if (value.length === 0) {
          setError((prevState) => ({
            ...prevState,
            name: "Required",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            name: false,
          }));
        }
        break;
      case "country":
        if (value.length === 0) {
          setError((prevState) => ({
            ...prevState,
            country: "Required",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            country: false,
          }));
        }
        break;
      case "company":
        if (value.length === 0) {
          setError((prevState) => ({
            ...prevState,
            company: "Required",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            company: false,
          }));
        }
        break;
      case "mobileNo":
        if (value.length === 0) {
          setError((prevState) => ({
            ...prevState,
            mobileNo: "Required",
          }));
        } else if (!contactValidation.test(value)) {
          setError((prevState) => ({
            ...prevState,
            mobileNo: "Enter valid mobile no.",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            mobileNo: false,
          }));
          setState((prevState) => ({
            ...prevState,
            mobileNo: value.replace(/[^0-9 ]/gi, ""),
          }));
        }
        break;

      case "whatsapp":
        if (value.length === 0) {
          setError((prevState) => ({
            ...prevState,
            whatsapp: false,
          }));
          setContact(false);
        } else if (!contactValidation.test(value)) {
          setError((prevState) => ({
            ...prevState,
            whatsapp: "Enter valid whatsapp no.",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            whatsapp: false,
          }));
          setContact(false);
        }
        break;
      // case "wechat":
      //   if (value.length === 0) {
      //     setError((prevState) => ({
      //       ...prevState,
      //       wechat: "Required",
      //     }));
      //   } else {
      //     setError((prevState) => ({
      //       ...prevState,
      //       wechat: false,
      //     }));
      //     setContact(false);
      //   }
      //   break;
      // case "skype":
      //   if (value.length === 0) {
      //     setError((prevState) => ({
      //       ...prevState,
      //       skype: "Required",
      //     }));
      //   } else {
      //     setError((prevState) => ({
      //       ...prevState,
      //       skype: false,
      //     }));
      //     setContact(false);
      //   }
      //   break;
      case "proof":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            proof: "Required",
          }));
        } else if (
          !(
            e.target.files[0]?.type === "application/msword" ||
            e.target.files[0]?.type === "application/pdf"
          )
        ) {
          setError((prevState) => ({
            ...prevState,
            proof: "Only Accept word and pdf file",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            proof: false,
          }));
        }
        break;
      default:
        break;
    }
  };

  const submitForm = () => {
    if (state.userName === "") {
      setError((prevState) => ({
        ...prevState,
        userName: "Required",
      }));
    }
    if (state.email === "") {
      setError((prevState) => ({
        ...prevState,
        email: "Required",
      }));
    }
    if (state.password === "") {
      setError((prevState) => ({
        ...prevState,
        password: "Required",
      }));
    }
    if (state.conPassword === "") {
      setError((prevState) => ({
        ...prevState,
        conPassword: "Required",
      }));
    } else if (state.password !== state.conPassword) {
      setError((prevState) => ({
        ...prevState,
        conPassword: "Passwords did not match",
      }));
    }
    if (state.name === "") {
      setError((prevState) => ({
        ...prevState,
        name: "Required",
      }));
    }
    if (state.country === "") {
      setError((prevState) => ({
        ...prevState,
        country: "Required",
      }));
    }
    if (state.company === "") {
      setError((prevState) => ({
        ...prevState,
        company: "Required",
      }));
    }
    if (state.mobileNo === "") {
      setError((prevState) => ({
        ...prevState,
        mobileNo: "Required",
      }));
    }
    if (state.whatsapp === "" && state.wechat === "" && state.skype === "") {
      setContact("Fill at least one contact detail");
    }
    if (state.proof === "") {
      setError((prevState) => ({
        ...prevState,
        proof: "Required",
      }));
    }
    if (
      state.userName !== "" &&
      state.email !== "" &&
      state.password !== "" &&
      state.conPassword !== "" &&
      state.name !== "" &&
      state.country !== "" &&
      state.company !== "" &&
      state.mobileNo !== "" &&
      state.proof !== "" &&
      (state.wechat !== "" || state.whatsapp !== "" || state.skype !== "") &&
      !error.proof
    ) {
      props.submitRegForm({
        email: state.email,
        password: state.password,
        username: state.userName,
        country_id: state.country,
        fullname: state.name,
        company: state.company,
        mobile: state.mobileNo,
        whatsapp: state.whatsapp,
        wechat: state.wechat,
        skype: state.skype,
        document: upload_document,
      });
      setSpinner(true);
    }
  };

  return (
    <>
      <div className="auth-bg">
        <video src={videoUrl} autoPlay muted loop />
        <div className="auth-form">
          <h2 className="text-center">Registration</h2>
          <Form>
            {/* Login information */}
            <h3 className="mb-3">Login Information</h3>
            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Username * </Form.Label>
                  <Form.Control
                    type="text"
                    value={state.userName}
                    onChange={handleChange}
                    name="userName"
                    id="userName"
                    placeholder=""
                  />
                  {error.userName && (
                    <span className="form-error">{error.userName}</span>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Email *</Form.Label>
                  <Form.Control
                    type="email"
                    value={state.email}
                    onChange={handleChange}
                    name="email"
                    id="email"
                    placeholder=""
                  />
                  {error.email && (
                    <span className="form-error">{error.email}</span>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Password *</Form.Label>
                  <div className="position-relative">
                    <Form.Control
                      type={showPass ? "text" : "password"}
                      value={state.password}
                      onChange={handleChange}
                      name="password"
                      id="password"
                      style={{ paddingRight: "40px" }}
                      placeholder=""
                    />
                    <i
                      className={`eye-btn position-absolute fa-regular ${showPass ? "fa-eye-slash" : "fa-regular fa-eye"
                        }`}
                      onClick={() => setShowPass(!showPass)}
                      style={{ cursor: "pointer", top: "17px", right: "25px" }}
                    ></i>
                  </div>
                  {error.password && (
                    <span className="form-error">{error.password}</span>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Confirm Password *</Form.Label>
                  <div className="position-relative">
                    <Form.Control
                      type={showConPass ? "text" : "password"}
                      value={state.conPassword}
                      onChange={handleChange}
                      name="conPassword"
                      id="conPassword"
                      placeholder=""
                      style={{ paddingRight: "40px" }}
                    />
                    <i
                      className={`eye-btn position-absolute fa-regular ${showConPass ? "fa-eye-slash" : "fa-regular fa-eye"
                        }`}
                      onClick={() => setShowConPass(!showConPass)}
                      style={{ cursor: "pointer", top: "17px", right: "25px" }}
                    ></i>
                  </div>
                  {error.conPassword && (
                    <span className="form-error">{error.conPassword}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>

            {/* Personal information */}
            <h3 className="mt-5 mb-3">Personal Information</h3>
            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Full Name *</Form.Label>
                  <Form.Control
                    type="text"
                    value={state.name}
                    onChange={handleChange}
                    name="name"
                    id="name"
                    placeholder=""
                  />
                  {error.name && (
                    <span className="form-error">{error.name}</span>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Country *</Form.Label>
                  {/* <Form.Control type="text" value={state.country} onChange={handleChange} name="country" id="country" placeholder="" /> */}
                  <Form.Select
                    id="country"
                    value={state.country}
                    onChange={handleChange}
                    name="country"
                  >
                    <option>Select Country</option>
                    {countryList.length === 0 ? (
                      <option>No Country Available</option>
                    ) : (
                      countryList?.map((data, i) => {
                        return (
                          <option key={i} value={data.id}>
                            {data.name}
                          </option>
                        );
                      })
                    )}
                  </Form.Select>

                  {error.country && (
                    <span className="form-error">{error.country}</span>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Company *</Form.Label>
                  <Form.Control
                    type="text"
                    value={state.company}
                    onChange={handleChange}
                    name="company"
                    id="company"
                    placeholder=""
                  />
                  {error.company && (
                    <span className="form-error">{error.company}</span>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Mobile No. *</Form.Label>
                  <Form.Control
                    type="number"
                    maxLength={10}
                    value={state.mobileNo}
                    onChange={handleChange}
                    name="mobileNo"
                    id="mobileNo"
                    placeholder=""
                  />
                  {error.mobileNo && (
                    <span className="form-error">{error.mobileNo}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>
            {/* Contact information */}
            <h3 className="mt-5 mb-0">Contact Detail</h3>
            <small className="mb-3 d-block">
              Fill at least one contact detail
            </small>
            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Whatspp</Form.Label>
                  <Form.Control
                    type="number"
                    value={state.whatsapp}
                    onChange={handleChange}
                    name="whatsapp"
                    id="whatsapp"
                    placeholder=""
                  />
                  {error.whatsapp && (
                    <span className="form-error">{error.whatsapp}</span>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">weChat</Form.Label>
                  <Form.Control
                    type="text"
                    value={state.wechat}
                    onChange={handleChange}
                    name="wechat"
                    id="wechat"
                    placeholder=""
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Skype</Form.Label>
                  <Form.Control
                    type="text"
                    value={state.skype}
                    onChange={handleChange}
                    name="skype"
                    id="skype"
                    placeholder=""
                  />
                  {contact && <span className="form-error">{contact}</span>}
                </Form.Group>
              </Col>
            </Row>
            <div className="mb-3">
              <label htmlFor="formFile" className="form-label">
                Business Proof Document *
              </label>
              <input
                className="form-control form-control-lg w-100"
                onChange={handleChange}
                accept="application/msword, application/pdf"
                type="file"
                name="proof"
                id="proof"
              />
              {error.proof && <span className="form-error">{error.proof}</span>}
            </div>
            {/* <Form.Group className="mb-3">
                                    <Form.Check type="checkbox" label="Remember Me" />
                              </Form.Group> */}
            <Button
              type="button"
              onClick={submitForm}
              disabled={spinner}
              className="mt-5"
            >
              {spinner ? (
                <Spinner
                  as="span"
                  animation="border"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <>Submit</>
              )}
            </Button>
          </Form>
        </div>
        <div className="reg-text">
          Already have an account?{" "}
          <Link to="/login" className="text-primary ms-2 fw-semibold">
            Login
          </Link>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  registerReducer: state.authReducer.register,
  countryReducer: state.authReducer.getCountry,
  profileReducer: state.authReducer.profile,
});

const mapDispatchToProps = (dispatch) => ({
  submitRegForm: (details) => dispatch(register(details)),
  countryList: () => dispatch(getCountry()),
  resetRegister: () => dispatch({ type: REGISTER_RESET }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);