import React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "./userLayout/Footer";
import Header from "./userLayout/Header";

const OfferAtYourPrice = () => {
  return (
    <>
      <Header />
      <main className="userMain">
        <div className="d-flex justify-content-between  mt-5 mb-4 flex-wrap">
          <h2 className="heading mb-0">
            <Link to="/dashboard" className="text-primary">
              <i className="fa-solid fa-arrow-left"></i>
            </Link>{" "}
            Offer At Your Price (OAYP)
          </h2>
          <div className="text-end mt-3 mt-sm-0 ms-auto navigate-buttons">
            {/* Modify search */}
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip">Modify Search</Tooltip>}
            >
              <Button variant="secondary" className="me-sm-2 me-1">
                <i className="fa-solid fa-magnifying-glass"></i>
              </Button>
            </OverlayTrigger>

            {/* Add to cart */}
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip">Add to Cart</Tooltip>}
            >
              <Button variant="secondary" className="me-sm-2 me-1">
                <i className="fa-solid fa-cart-shopping"></i>
              </Button>
            </OverlayTrigger>

            {/* Send Inquiry */}
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip">Send Inquiry</Tooltip>}
            >
              <Button variant="secondary" className="me-sm-2 me-1">
                <i className="fa-solid fa-clipboard-question"></i>
              </Button>
            </OverlayTrigger>

            {/* Add to watch list */}
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip">Add to Watch List</Tooltip>}
            >
              <Button variant="secondary" className="me-sm-2 me-1">
                <i className="fa-solid fa-clock"></i>
              </Button>
            </OverlayTrigger>

            {/* Show selected */}
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip">Show selected</Tooltip>}
            >
              <Button variant="secondary" className="me-sm-2 me-1">
                <i className="fa-solid fa-clipboard-list"></i>
              </Button>
            </OverlayTrigger>

            {/* Stone Comparison */}
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip">Stone Comparison</Tooltip>}
            >
              <Button variant="secondary" className="me-sm-2 me-1">
                <i className="fa-solid fa-scale-balanced"></i>
              </Button>
            </OverlayTrigger>

            {/* Email Excel */}
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip">Email Excel</Tooltip>}
            >
              <Button variant="secondary" className="me-sm-2 me-1">
                <i className="fa-solid fa-envelope-open-text"></i>
              </Button>
            </OverlayTrigger>
          </div>
        </div>

        <div className="content-block">
          <ul className="search-items-list">
            <li>
              <input
                className="styled-checkbox"
                id="shRound"
                type="checkbox"
                value="Round"
              />
              <label htmlFor="shRound">
                <span className="diam-icon icon-round ab"></span>
                Round
              </label>
            </li>
            <li>
              <input
                className="styled-checkbox"
                id="shCushion"
                type="checkbox"
                value="Cushion"
              />
              <label htmlFor="shCushion">
                <span className="diam-icon icon-Single_14 ab"></span>
                Cushion
              </label>
            </li>
            <li>
              <input
                className="styled-checkbox"
                id="shOval"
                type="checkbox"
                value="Oval"
              />
              <label htmlFor="shOval">
                <span className="diam-icon icon-Single_6 ab"></span>
                Oval
              </label>
            </li>
            <li>
              <input
                className="styled-checkbox"
                id="shEmerald"
                type="checkbox"
                value="Emerald"
              />
              <label htmlFor="shEmerald">
                <span className="diam-icon icon-Single_9 ab"></span>
                Emerald
              </label>
            </li>
            <li>
              <input
                className="styled-checkbox"
                id="shHeart"
                type="checkbox"
                value="Heart"
              />
              <label htmlFor="shHeart">
                <span className="diam-icon icon-Single_8 ab"></span>
                Heart
              </label>
            </li>
            <li>
              <input
                className="styled-checkbox"
                id="shPear"
                type="checkbox"
                value="Pear"
              />
              <label htmlFor="shPear">
                <span className="diam-icon icon-Single_5 ab"></span>
                Pear
              </label>
            </li>
            <li>
              <input
                className="styled-checkbox"
                id="shPrincess"
                type="checkbox"
                value="Princess"
              />
              <label htmlFor="shPrincess">
                <span className="diam-icon icon-Single_4"></span>
                Princess
              </label>
            </li>
            <li>
              <input
                className="styled-checkbox"
                id="shMarquise"
                type="checkbox"
                value="Marquise"
              />
              <label htmlFor="shMarquise">
                <span className="diam-icon icon-Single_7"></span>Marquise
              </label>
            </li>
            <li>
              <input
                className="styled-checkbox"
                id="shSQEmerald"
                type="checkbox"
                value="SQ Emerald"
              />
              <label htmlFor="shSQEmerald">
                <span className="diam-icon icon-Single_11"></span>
                SQ Emerald
              </label>
            </li>
            <li>
              <input
                className="styled-checkbox"
                id="shRadiant"
                type="checkbox"
                value="Radiant"
              />
              <label htmlFor="shRadiant">
                <span className="diam-icon icon-Single_12"></span>
                Radiant
              </label>
            </li>
            <li>
              <input
                className="styled-checkbox"
                id="shCUBrillian"
                type="checkbox"
                value="CU Brillian"
              />
              <label htmlFor="shCUBrillian">
                <span className="diam-icon icon-Single_14"></span>
                CU Brillian
              </label>
            </li>
            <li>
              <input
                className="styled-checkbox"
                id="shLCushion"
                type="checkbox"
                value="L Cushion"
              />
              <label htmlFor="shLCushion">
                <span className="diam-icon icon-Single_14"></span>L Cushion
              </label>
            </li>
            <li>
              <input
                className="styled-checkbox"
                id="shHexagonal"
                type="checkbox"
                value="Hexagonal"
              />
              <label htmlFor="shHexagonal">
                <span className="diam-icon icon-hexagonal"></span>
                Hexagonal
              </label>
            </li>
            <li>
              <input
                className="styled-checkbox"
                id="shTrilliant"
                type="checkbox"
                value="Trilliant"
              />
              <label htmlFor="shTrilliant">
                <span className="diam-icon icon-Single_13"></span>
                Trilliant
              </label>
            </li>
          </ul>

          {/* Info blocks */}
          <div className="row g-1 g-lg-3 mb-4 mt-lg-0 mt-3">
            <div className="col-md-3 col-sm-6">
              <div className="info-block">
                <h6 className="mb-lg-3 text-primary"> Total Cts</h6>
                <h2 className="mb-0">2.250</h2>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="info-block">
                <h6 className="mb-lg-3 text-primary"> $/Carat</h6>
                <h2 className="mb-0">$1430.00</h2>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="info-block">
                <h6 className="mb-lg-3 text-primary"> Average Discount</h6>
                <h2 className="mb-0">
                  {" "}
                  93.50 <sub>%</sub>
                </h2>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="info-block ">
                <h6 className="mb-lg-3 text-primary"> Total Amount</h6>
                <h2 className="mb-0">$ 1615.90</h2>
              </div>
            </div>
          </div>
          {/* <SearchTable /> */}
        </div>
      </main>
      <Footer />
    </>
  );
};

export default OfferAtYourPrice;
