import { ADD_APPOINYMENT_ERROR, ADD_APPOINYMENT_RESET, ADD_APPOINYMENT_SUCCESS, DELETE_APPOINYMENT_ERROR, DELETE_APPOINYMENT_RESET, DELETE_APPOINYMENT_SUCCESS, GET_APPOINYMENT_ERROR, GET_APPOINYMENT_RESET, GET_APPOINYMENT_SUCCESS } from "../common/constant";

let initialState = {
 getAppointment: {
  error: false,
  data: null,
  errors: null,
  success: false,
 },
 createAppointment: {
  error: false,
  errors: null,
  success: false,
 },
 deleteAppointment: {
  error: false,
  errors: null,
  success: false,
 }
};

export default function appointmentReducer(state = initialState, action) {
 switch (action.type) {
  case GET_APPOINYMENT_SUCCESS:
   return { ...state, getAppointment: { ...state.getAppointment, success: true, error: false, errors: null, data: action.response }, };

  case GET_APPOINYMENT_ERROR:
   return { ...state, getAppointment: { ...state.getAppointment, success: false, error: true, errors: action.msg } };

  case GET_APPOINYMENT_RESET:
   return { ...state, getAppointment: { ...state.getAppointment, success: false, error: false, errors: null } };

  //  add

  case ADD_APPOINYMENT_SUCCESS:
   return { ...state, createAppointment: { ...state.createAppointment, success: true, error: false, errors: null }, };

  case ADD_APPOINYMENT_ERROR:
   return { ...state, createAppointment: { ...state.createAppointment, success: false, error: true, errors: action.msg } };

  case ADD_APPOINYMENT_RESET:
   return { ...state, createAppointment: { ...state.createAppointment, success: false, error: false, errors: null } };



  //  delete

  case DELETE_APPOINYMENT_SUCCESS:
   return { ...state, deleteAppointment: { ...state.deleteAppointment, success: true, error: false, errors: null }, };

  case DELETE_APPOINYMENT_ERROR:
   return { ...state, deleteAppointment: { ...state.deleteAppointment, success: false, error: true, errors: action.msg } };

  case DELETE_APPOINYMENT_RESET:
   return { ...state, deleteAppointment: { ...state.deleteAppointment, success: false, error: false, errors: null } };

  default:
   return state;
 }
}
