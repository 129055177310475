import { Icon } from "@iconify/react";
import classNames from "classnames";
import React, { useState } from "react";
import { Container, Form, Table } from "react-bootstrap";
import { connect } from "react-redux";
import {
  getstarmeleeDiamondAction,
  starmeleecaratAction,
  starmeleepriceAction,
  starmeleeinquairyAction,
  starmeleeinquairyListAction,
} from "../userStore/action/StarmeleeAction";
import { getProfile } from "../userStore/action/authAction";
import { toast } from "react-toastify";
import { useEffect } from "react";
import Header from "../user/userLayout/Header";
import { useLocation } from "react-router-dom";

function StarMeleeTable(props) {
  const [showForm, setShowForm] = useState(false);
  const token = localStorage.getItem("token");
  const profileData = props.profileReducer.data;
  const data = props.starmeleeCaratReducer.data;
  const fancyData = props.starmeleeReducer.data;
  const inquairyListing = props.starmeleeInquairyListReducer?.data;
  const diamondType = profileData?.diamond_type;
  const location = useLocation();
  const _type = !!location.state
    ? location.state == "/labGrown/search"
      ? "Lab-Grown Diamonds"
      : location.state == "/natural/search"
      ? "Natural Diamonds"
      : false
    : false;

  console.log("635501", location);
  const initialSteps = {
    step1: true,
    step2: false,
    step3: false,
    step4: false,
    step5: false,
    step6: false,
    step7: false,
  };
  const [steps, setSteps] = useState(initialSteps);

  const initialError = {
    shape_id: false,
    size_id: false,
    color_id: false,
    clarity_id: false,
    diamondType: false,
    color: false,
    clarity: false,
    carat: false,
    price: false,
    quantity: false,
    number: false,
    name: false,
    email: false,
  };
  const [errors, setErrors] = useState(initialError);
  const initialDiamonsState = {
    diamondType: "",
    color: "",
    clarity: "",
    carat: "",
    price: "",
    quantity: "",
    total: "",
    name: "",
    email: "",
    number: "",
    shape: "",
    userID: "",
  };
  const [checkDiamondsState, setcheckDiamondsState] =
    useState(initialDiamonsState);

  useEffect(() => {
    props.getstarmeleeDiamondAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    props.starmeleeinquairyListAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const nameCheck = (e) => {
    e.preventDefault();
    if (checkDiamondsState.diamondType === "") {
      setErrors((prevState) => ({
        ...prevState,
        diamondType: "Required",
      }));
    } else {
      setSteps((prevState) => ({
        ...prevState,
        step1: false,
        step2: true,
      }));
    }
  };

  const fancy_colorCheck = (e) => {
    e.preventDefault();
    if (checkDiamondsState.color === "") {
      toast.error("Please Selact Color...");
      setErrors((prevState) => ({
        ...prevState,
        color: "Required",
      }));
    } else {
      setSteps((prevState) => ({
        ...prevState,
        step3: false,
        step4: true,
      }));
    }
  };

  const clarityCheck = (e) => {
    e.preventDefault();
    if (checkDiamondsState.clarity === "") {
      setErrors((prevState) => ({
        ...prevState,
        clarity: "Required",
      }));
    } else {
      setSteps((prevState) => ({
        ...prevState,
        step3: false,
        step4: true,
      }));
    }
    props.starmeleecaratAction({
      color: checkDiamondsState.color,
      clarity: checkDiamondsState.clarity,
      diamond_type:
        location.state == "/labGrown/search"
          ? 1
          : location.state == "/natural/search"
          ? 0
          : profileData.diamond_type,
      shape:
        checkDiamondsState.diamondType === 1
          ? "round"
          : checkDiamondsState.shape,
    });
  };

  const fancy_clarityCheck = (e) => {
    e.preventDefault();
    if (checkDiamondsState.clarity === "") {
      setErrors((prevState) => ({
        ...prevState,
        clarity: "Required",
      }));
    } else {
      setSteps((prevState) => ({
        ...prevState,
        step4: false,
        step5: true,
      }));
    }
    props.starmeleecaratAction({
      color: checkDiamondsState.color,
      clarity: checkDiamondsState.clarity,
      diamond_type:
        location.state == "/labGrown/search"
          ? 1
          : location.state == "/natural/search"
          ? 0
          : profileData.diamond_type,
      shape:
        checkDiamondsState.diamondType === 1
          ? "round"
          : checkDiamondsState.shape,
    });
  };

  const caratCheck = (e) => {
    e.preventDefault();
    if (checkDiamondsState.carat === "") {
      setErrors((prevState) => ({
        ...prevState,
        carat: "Required",
      }));
    } else {
      setSteps((prevState) => ({
        ...prevState,
        step4: false,
        step5: true,
      }));
    }
    if (checkDiamondsState.carat !== "") {
      props.starmeleepriceAction({
        diamond_type:
          location.state == "/labGrown/search"
            ? 1
            : location.state == "/natural/search"
            ? 0
            : profileData.diamond_type,
        color: checkDiamondsState.color,
        clarity: checkDiamondsState.clarity,
        carat: data[checkDiamondsState.carat].carat,
        shape:
          checkDiamondsState.diamondType === 1
            ? "round"
            : checkDiamondsState?.shape,
      });
    }
  };

  const fancy_caratCheck = (e) => {
    e.preventDefault();
    if (checkDiamondsState.carat === "") {
      setErrors((prevState) => ({
        ...prevState,
        carat: "Required",
      }));
    } else {
      setSteps((prevState) => ({
        ...prevState,
        step5: false,
        step6: true,
      }));
    }
    if (checkDiamondsState.carat !== "") {
      props.starmeleepriceAction({
        diamond_type:
          location.state == "/labGrown/search"
            ? 1
            : location.state == "/natural/search"
            ? 0
            : profileData.diamond_type,
        color: checkDiamondsState.color,
        clarity: checkDiamondsState.clarity,
        carat: data[checkDiamondsState.carat].carat,
        shape:
          checkDiamondsState.diamondType === 1
            ? "round"
            : checkDiamondsState?.shape,
      });
    }
  };

  const shapeCheck = (e) => {
    e.preventDefault();
    if (checkDiamondsState.shape === "") {
      toast.error("Please Selact Diamonds Shape...");
      setErrors((prevState) => ({
        ...prevState,
        shape: "Required",
      }));
    } else {
      setSteps((prevState) => ({
        ...prevState,
        step2: false,
        step3: true,
      }));
    }
  };

  const colorCheck = (e) => {
    e.preventDefault();
    if (checkDiamondsState.color === "") {
      toast.error("Please Selact Color...");
      setErrors((prevState) => ({
        ...prevState,
        color: "Required",
      }));
    } else {
      setSteps((prevState) => ({
        ...prevState,
        step2: false,
        step3: true,
      }));
    }
  };

  const changeFormState = () => {
    setShowForm(true);
  };

  const step2Back = () => {
    setSteps((prevState) => ({
      ...prevState,
      step2: false,
      step1: true,
    }));
  };
  const step3Back = () => {
    setSteps((prevState) => ({
      ...prevState,
      step3: false,
      step2: true,
    }));
  };
  const step4Back = () => {
    setSteps((prevState) => ({
      ...prevState,
      step4: false,
      step3: true,
    }));
  };
  const step5Back = () => {
    setSteps((prevState) => ({
      ...prevState,
      step5: false,
      step4: true,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "round") {
      setcheckDiamondsState((preve) => ({
        ...preve,
        diamondType: "1",
      }));
    } else if (name === "fancy") {
      setcheckDiamondsState((preve) => ({
        ...preve,
        diamondType: "2",
      }));
    } else if (name === "DEF") {
      setcheckDiamondsState((preve) => ({
        ...preve,
        color: "DEF",
      }));
    } else if (name === "FG") {
      setcheckDiamondsState((preve) => ({
        ...preve,
        color: "FG",
      }));
    } else if (name === "pink") {
      setcheckDiamondsState((preve) => ({
        ...preve,
        color: "pink",
      }));
    } else if (name === "yellow") {
      setcheckDiamondsState((preve) => ({
        ...preve,
        color: "yellow",
      }));
    } else if (name === "blue") {
      setcheckDiamondsState((preve) => ({
        ...preve,
        color: "blue",
      }));
    } else if (name === "vvs_vs") {
      setcheckDiamondsState((preve) => ({
        ...preve,
        clarity: "vvs_vs",
      }));
    } else if (name === "vs_si") {
      setcheckDiamondsState((preve) => ({
        ...preve,
        clarity: "vs_si",
      }));
    } else if (name === "vvs_vs") {
      setcheckDiamondsState((preve) => ({
        ...preve,
        clarity: "vvs_vs",
      }));
    } else if (name === "vs_si") {
      setcheckDiamondsState((preve) => ({
        ...preve,
        clarity: "vs_si",
      }));
    } else if (name === "vvs_vs_si1") {
      setcheckDiamondsState((preve) => ({
        ...preve,
        clarity: "vvs_vs_si1",
      }));
    } else if (name === "carat") {
      setcheckDiamondsState((preve) => ({
        ...preve,
        carat: value,
      }));
    } else if (name === "quantity") {
      setcheckDiamondsState((preve) => ({
        ...preve,
        quantity: value === "" ? "" : value,
        total:
          value !== ""
            ? parseInt(checkDiamondsState.price) * parseInt(value)
            : parseInt(checkDiamondsState.price),
      }));
    } else {
      setcheckDiamondsState((preve) => ({
        ...preve,
        [name]: value,
      }));
    }

    switch (name) {
      case "diamondType":
        if (value === "") {
          setErrors((prevState) => ({
            ...prevState,
            diamondType: "Requaired",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            diamondType: false,
          }));
        }
        break;

      case "color":
        if (value === "") {
          setErrors((prevState) => ({
            ...prevState,
            color: "Requaired",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            color: false,
          }));
        }
        break;

      case "clarity":
        if (value === "") {
          setErrors((prevState) => ({
            ...prevState,
            clarity: "Requaired",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            clarity: false,
          }));
        }
        break;

      case "carat":
        if (value === "") {
          setErrors((prevState) => ({
            ...prevState,
            carat: "Requaired",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            carat: false,
          }));
        }
        break;

      case "name":
        if (value === "") {
          setErrors((prevState) => ({
            ...prevState,
            name: "Requaired",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            name: false,
          }));
        }
        break;

      case "email":
        if (value === "") {
          setErrors((prevState) => ({
            ...prevState,
            email: "Requaired",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            email: false,
          }));
        }
        break;

      case "number":
        if (value === "") {
          setErrors((prevState) => ({
            ...prevState,
            number: "Requaired",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            number: false,
          }));
        }
        break;

      case "quantity":
        if (value === "" || value === 0) {
          setErrors((prevState) => ({
            ...prevState,
            quantity: "Requaired",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            quantity: false,
          }));
        }
        break;

      default:
        break;
    }
  };

  const handleSubmit = (e) => {
    if (checkDiamondsState.name === "") {
      setErrors((prevState) => ({
        ...prevState,
        name: "Requaired",
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        name: false,
      }));
    }

    if (checkDiamondsState.email === "") {
      setErrors((prevState) => ({
        ...prevState,
        email: "Requaired",
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        email: false,
      }));
    }

    if (checkDiamondsState.number === "") {
      setErrors((prevState) => ({
        ...prevState,
        number: "Requaired",
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        number: false,
      }));
    }

    if (
      checkDiamondsState.quantity === "" ||
      checkDiamondsState.quantity === 0
    ) {
      setErrors((prevState) => ({
        ...prevState,
        quantity: "Requaired",
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        quantity: false,
      }));
    }

    if (
      checkDiamondsState?.quantity !== "" &&
      checkDiamondsState?.quantity !== "0"
    ) {
      if (
        token ||
        (checkDiamondsState.name !== "" &&
          checkDiamondsState.email !== "" &&
          checkDiamondsState.number !== "")
      ) {
        props.starmeleeinquairyAction({
          diamond_type:
            location.state == "/labGrown/search"
              ? 1
              : location.state == "/natural/search"
              ? 0
              : profileData.diamond_type,
          color: checkDiamondsState.color,
          clarity: checkDiamondsState.clarity,
          carat: `${
            !!data[checkDiamondsState.carat].size &&
            data[checkDiamondsState.carat].size !== ""
              ? `(${data[checkDiamondsState.carat].size})`
              : ""
          }${
            !!data[checkDiamondsState.carat].sieve &&
            data[checkDiamondsState.carat].sieve !== ""
              ? ` - (${data[checkDiamondsState.carat].sieve})`
              : ""
          }${
            !!data[checkDiamondsState.carat].carat &&
            data[checkDiamondsState.carat].carat !== ""
              ? ` - ${data[checkDiamondsState.carat].carat}`
              : ""
          }`,
          price: checkDiamondsState.price,
          qty: checkDiamondsState.quantity,
          name: checkDiamondsState.name,
          email: checkDiamondsState.email,
          mobile: checkDiamondsState.number,
          ...(token && { user_id: profileData.id }),
          shape:
            checkDiamondsState.diamondType === 1
              ? "round"
              : checkDiamondsState?.shape,
        });
      }
    } else {
      setErrors((preve) => ({
        ...preve,
        quantity: "required",
      }));
    }
  };
  return (
    <div>
      <Header />
      <main className="userMain pt-5">
        <h2 className="mb-0" style={{ color: "#c2b362" }}>
          {!!_type
            ? _type
            : diamondType == 1
            ? "Natural Diamonds"
            : "Lab-Grown Diamonds"}
        </h2>
        <>
          {showForm ? (
            <>
              <div className="content-block mt-3">
                <Container className="position-relative inquiry-form pt-0 mt-0">
                  <div className="box mx-auto ">
                    <h4 className="text-center fw-bold mb-4 pt-5">
                      Let's Get Inquiry
                    </h4>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                    >
                      {/* Step 1 */}
                      {steps.step1 && (
                        <>
                          <div className="row justify-content-center">
                            <div className="col-md-12">
                              <Form.Select
                                name="diamondType"
                                // className="mb-5 mt-5 starmelee-formselect"
                                onClick={handleChange}
                                className={classNames(
                                  "mb-5 mt-5 starmelee-formselect",
                                  {
                                    requireField: errors.diamondType,
                                  }
                                )}
                              >
                                <option value="" name="">
                                  Select Diamonds Type
                                </option>
                                <option value="1" name="round">
                                  Round
                                </option>
                                <option value="2" name="fancy">
                                  Fancy Layout
                                </option>
                              </Form.Select>
                            </div>
                          </div>
                          {/* {errors?.diamondType && <span className="d-flex justify-content-center">{errors?.diamondType}</span>} */}
                          <div className="d-flex justify-content-end">
                            <button
                              className="talk_btn btn-next"
                              type="submit"
                              onClick={nameCheck}
                            >
                              <span className="pe-2">Next</span>
                              <Icon
                                icon="bi:arrow-right-circle"
                                color="white"
                              />
                            </button>
                            {/* <button
                                type="reset"
                                className="btn btn-secondary text-white me-2 d-inline-block ms-4"
                                onClick={() => {
                                  setShowForm(false);
                                  setcheckDiamondsState(initialDiamonsState);
                                  setSteps(initialSteps);
                                  setErrors(initialError);
                                }}
                              >
                                <i className="fa-solid fa-arrow-rotate-right"></i>
                              </button> */}
                          </div>
                        </>
                      )}
                      {steps.step2 && (
                        <>
                          {checkDiamondsState.diamondType === "1" && (
                            <>
                              <div className="row mb-3">
                                <div className="col-lg-3">
                                  <Form.Group className="">
                                    <Form.Label className="mb-1">
                                      Diamond type
                                    </Form.Label>
                                    <Form.Control
                                      disabled
                                      value={
                                        checkDiamondsState?.diamondType === "1"
                                          ? "Round"
                                          : "Fancy Layout"
                                      }
                                    />
                                  </Form.Group>
                                </div>
                              </div>
                              <Form.Label className="mb-1">
                                Diamond Color{" "}
                              </Form.Label>
                              <ul className="search-items-list mb-5">
                                <button
                                  type="radio"
                                  name="DEF"
                                  value="DEF"
                                  cursor="pointer"
                                  onClick={handleChange}
                                  className={classNames(
                                    "btn me-3 starmelee_checkbox",
                                    {
                                      "btn-primary text-light":
                                        checkDiamondsState.color === "DEF",
                                    }
                                  )}
                                  style={{
                                    fontWeight: "600",
                                    border:
                                      checkDiamondsState.color !== "DEF" &&
                                      "2px solid #C2B362",
                                    color:
                                      checkDiamondsState.color !== "DEF" &&
                                      "#C2B362",
                                  }}
                                >
                                  DEF
                                </button>
                                <button
                                  type="radio"
                                  name="FG"
                                  value="FG"
                                  cursor="pointer"
                                  // onClick={() => {
                                  //   setcheckDiamondsState({
                                  //     color: "FG",
                                  //   });
                                  // }}
                                  onClick={handleChange}
                                  className={classNames(
                                    "btn me-3 starmelee_checkbox",
                                    {
                                      "btn-primary text-light":
                                        checkDiamondsState.color === "FG",
                                    }
                                  )}
                                  style={{
                                    fontWeight: "600",
                                    border:
                                      checkDiamondsState.color !== "FG" &&
                                      "2px solid #C2B362",
                                    color:
                                      checkDiamondsState.color !== "FG" &&
                                      "#C2B362",
                                  }}
                                >
                                  FG
                                </button>
                                <button
                                  type="radio"
                                  name="pink"
                                  cursor="pointer"
                                  value="pink"
                                  // onClick={() => {
                                  //   setcheckDiamondsState({
                                  //     color: "pink",
                                  //   });
                                  // }}
                                  onClick={handleChange}
                                  className={classNames(
                                    "btn me-3 starmelee_checkbox",
                                    {
                                      "btn-primary text-light":
                                        checkDiamondsState.color === "pink",
                                    }
                                  )}
                                  style={{
                                    fontWeight: "600",
                                    border:
                                      checkDiamondsState.color !== "pink" &&
                                      "2px solid #C2B362",
                                    color:
                                      checkDiamondsState.color !== "pink" &&
                                      "#C2B362",
                                  }}
                                >
                                  Pink
                                </button>
                                <button
                                  type="radio"
                                  name="yellow"
                                  cursor="pointer"
                                  value="yellow"
                                  // onClick={() => {
                                  //   setcheckDiamondsState({
                                  //     color: "yellow",
                                  //   });
                                  // }}
                                  onClick={handleChange}
                                  className={classNames(
                                    "btn me-3 starmelee_checkbox",
                                    {
                                      "btn-primary text-light":
                                        checkDiamondsState.color === "yellow",
                                    }
                                  )}
                                  style={{
                                    fontWeight: "600",
                                    border:
                                      checkDiamondsState.color !== "yellow" &&
                                      "2px solid #C2B362",
                                    color:
                                      checkDiamondsState.color !== "yellow" &&
                                      "#C2B362",
                                  }}
                                >
                                  yellow
                                </button>
                                <button
                                  type="radio"
                                  name="blue"
                                  cursor="pointer"
                                  value="blue"
                                  // onClick={() => {
                                  //   setcheckDiamondsState({
                                  //     color: "blue",
                                  //   });
                                  // }}
                                  onClick={handleChange}
                                  className={classNames(
                                    "btn me-3 starmelee_checkbox",
                                    {
                                      "btn-primary text-light":
                                        checkDiamondsState.color === "blue",
                                    }
                                  )}
                                  style={{
                                    fontWeight: "600",
                                    border:
                                      checkDiamondsState.color !== "blue" &&
                                      "2px solid #C2B362",
                                    color:
                                      checkDiamondsState.color !== "blue" &&
                                      "#C2B362",
                                  }}
                                >
                                  Blue
                                </button>
                              </ul>
                              <div className="d-flex justify-content-between">
                                <button
                                  type="button"
                                  className="btn btn-back"
                                  onClick={step2Back}
                                >
                                  <Icon icon="bi:arrow-left" className="me-3" />
                                  Back
                                </button>
                                <button
                                  className="talk_btn btn-next "
                                  type="submit"
                                  onClick={colorCheck}
                                >
                                  <span className="pe-2">Next</span>
                                  <Icon
                                    icon="bi:arrow-right-circle"
                                    color="white"
                                  />
                                </button>

                                {/* <button
                                      type="reset"
                                      className="btn btn-secondary text-white me-2 d-inline-block ms-4"
                                      onClick={() => {
                                        setShowForm(false);
                                        setcheckDiamondsState(
                                          initialDiamonsState
                                        );
                                        setSteps(initialSteps);
                                        setErrors(initialError);
                                      }}
                                    >
                                      <i className="fa-solid fa-arrow-rotate-right"></i>
                                    </button> */}
                              </div>
                            </>
                          )}
                        </>
                      )}
                      {steps.step3 && (
                        <>
                          {checkDiamondsState.diamondType === "1" && (
                            <>
                              <div className="row mb-3 gx-2">
                                <Form.Group className="col-lg-3">
                                  <Form.Label className="mb-1">
                                    Diamond type
                                  </Form.Label>
                                  <Form.Control
                                    disabled
                                    value={
                                      checkDiamondsState?.diamondType === "1"
                                        ? "Round"
                                        : "Fancy Layout"
                                    }
                                  />
                                </Form.Group>
                                <Form.Group className="col-lg-3">
                                  <Form.Label className="mb-1">
                                    Diamond Color
                                  </Form.Label>
                                  <Form.Control
                                    disabled
                                    value={checkDiamondsState?.color}
                                  />
                                </Form.Group>
                              </div>
                              <Form.Group>
                                <Form.Label className="mb-1">
                                  Diamond Clarity
                                </Form.Label>
                                <Form.Select
                                  name="clarity"
                                  onClick={handleChange}
                                  className={classNames(
                                    "mb-5 starmelee-formselect",
                                    {
                                      requireField: errors.clarity,
                                    }
                                  )}
                                >
                                  <>
                                    {checkDiamondsState.color === "DEF" && (
                                      <>
                                        <option value="" name="">
                                          Select Clarity
                                        </option>
                                        <option value="vvs_vs" name="vvs_vs">
                                          VVS_VS
                                        </option>
                                        <option value="vs_si" name="vs_si">
                                          VS_SI
                                        </option>
                                      </>
                                    )}
                                    {checkDiamondsState.color === "FG" && (
                                      <>
                                        <option
                                          value="vvs_vs"
                                          name="vvs_vs"
                                          disabled
                                        >
                                          Select Clarity
                                        </option>
                                        <option value="vvs_vs" name="vvs_vs">
                                          VVS_VS
                                        </option>
                                        <option value="vs_si" name="vs_si">
                                          VS_SI
                                        </option>
                                      </>
                                    )}

                                    {checkDiamondsState.color === "pink" && (
                                      <>
                                        <option
                                          value="vvs_vs"
                                          name="vvs_vs"
                                          disabled
                                        >
                                          Select Clarity
                                        </option>
                                        <option
                                          value="vvs_vs_si1"
                                          name="vvs_vs_si1"
                                        >
                                          VVS-VS-SI1
                                        </option>
                                      </>
                                    )}
                                    {checkDiamondsState.color === "yellow" && (
                                      <>
                                        <option value="vvs_vs" name="vvs_vs">
                                          Select Clarity
                                        </option>
                                        <option
                                          value="vvs_vs_si1"
                                          name="vvs_vs_si1"
                                        >
                                          VVS-VS-SI1
                                        </option>
                                      </>
                                    )}
                                    {checkDiamondsState.color === "blue" && (
                                      <>
                                        <option
                                          value="vvs_vs"
                                          name="vvs_vs"
                                          disabled
                                        >
                                          Select Clarity
                                        </option>
                                        <option
                                          value="vvs_vs_si1"
                                          name="vvs_vs_si1"
                                        >
                                          VVS-VS-SI1
                                        </option>
                                      </>
                                    )}
                                  </>
                                </Form.Select>
                              </Form.Group>
                              <div className="d-flex justify-content-between">
                                <button
                                  type="button"
                                  className="btn btn-back"
                                  onClick={step3Back}
                                >
                                  <Icon icon="bi:arrow-left" className="me-3" />
                                  Back
                                </button>
                                <button
                                  className="talk_btn btn-next "
                                  type="submit"
                                  onClick={clarityCheck}
                                >
                                  <span className="pe-2">Next</span>
                                  <Icon
                                    icon="bi:arrow-right-circle"
                                    color="white"
                                  />
                                </button>
                                {/* <button
                                    type="reset"
                                    className="btn btn-secondary text-white me-2 d-inline-block ms-4"
                                    onClick={() => {
                                      setShowForm(false);
                                      setcheckDiamondsState(
                                        initialDiamonsState
                                      );
                                      setSteps(initialSteps);
                                      setErrors(initialError);
                                    }}
                                  >
                                    <i className="fa-solid fa-arrow-rotate-right"></i>
                                  </button> */}
                              </div>
                            </>
                          )}
                        </>
                      )}
                      {steps.step4 && (
                        <>
                          {checkDiamondsState.diamondType === "1" && (
                            <>
                              <div className=" row gx-2">
                                <Form.Group className="col-lg-3 mb-3">
                                  <Form.Label className="mb-2">
                                    Diamond type
                                  </Form.Label>
                                  <Form.Control
                                    disabled
                                    value={
                                      checkDiamondsState?.diamondType === "1"
                                        ? "Round"
                                        : "Fancy Layout"
                                    }
                                  />
                                </Form.Group>
                                <Form.Group className="col-lg-3 mb-3">
                                  <Form.Label className="mb-2">
                                    Diamond Color
                                  </Form.Label>
                                  <Form.Control
                                    disabled
                                    value={checkDiamondsState?.color}
                                  />
                                </Form.Group>
                                <Form.Group className="col-lg-3 mb-3">
                                  <Form.Label className="mb-2">
                                    Diamond Clarity
                                  </Form.Label>
                                  <Form.Control
                                    disabled
                                    value={checkDiamondsState?.clarity}
                                  />
                                </Form.Group>
                              </div>
                              <Form.Group className="">
                                <Form.Label className="mb-1">
                                  {" "}
                                  Diamond Carat{" "}
                                </Form.Label>
                                <Form.Select
                                  name="carat"
                                  onChange={handleChange}
                                  className={classNames(
                                    "mb-5 starmelee-formselect",
                                    {
                                      requireField: errors.carat,
                                    }
                                  )}
                                >
                                  <option value="">Select Carat</option>
                                  {data &&
                                    data?.map((v, i) => {
                                      return (
                                        <>
                                          <option value={i}>
                                            {v?.size} - {v?.sieve} - {v?.carat}
                                          </option>
                                        </>
                                      );
                                    })}
                                </Form.Select>
                              </Form.Group>

                              <div className="d-flex justify-content-between">
                                <button
                                  type="button"
                                  className="btn btn-back"
                                  onClick={step4Back}
                                >
                                  <Icon icon="bi:arrow-left" className="me-3" />
                                  Back
                                </button>
                                <button
                                  className="talk_btn btn-next"
                                  type="submit"
                                  onClick={caratCheck}
                                >
                                  <span className="pe-2">Next</span>
                                  <Icon
                                    icon="bi:arrow-right-circle"
                                    color="white"
                                  />
                                </button>
                                {/* <button
                                    type="reset"
                                    className="btn btn-secondary text-white me-2 d-inline-block ms-4"
                                    onClick={() => {
                                      setShowForm(false);
                                      setcheckDiamondsState(
                                        initialDiamonsState
                                      );
                                      setSteps(initialSteps);
                                      setErrors(initialError);
                                    }}
                                  >
                                    <i className="fa-solid fa-arrow-rotate-right"></i>
                                  </button> */}
                              </div>
                            </>
                          )}
                        </>
                      )}
                      {steps.step5 && (
                        <>
                          {checkDiamondsState.diamondType === "1" && (
                            <>
                              {props.starmeleeInquairyReducer?.success ? (
                                <>
                                  <div
                                    className="d-flex justify-content-center text-success"
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "20px",
                                    }}
                                  >
                                    Inquairy Send Successfully...
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="row gx-2">
                                    <Form.Group className="col-lg-3 mb-3">
                                      <Form.Label className="mb-1">
                                        Diamond type
                                      </Form.Label>
                                      <Form.Control
                                        disabled
                                        value={
                                          checkDiamondsState?.diamondType ===
                                          "1"
                                            ? "Round"
                                            : "Fancy Layout"
                                        }
                                      />
                                    </Form.Group>
                                    <Form.Group className="col-lg-3 mb-3">
                                      <Form.Label className="mb-1">
                                        Diamond color
                                      </Form.Label>
                                      <Form.Control
                                        disabled
                                        value={checkDiamondsState?.color}
                                      />
                                    </Form.Group>
                                    <Form.Group className="col-lg-3 mb-3">
                                      <Form.Label className="mb-1">
                                        Diamond Clarity
                                      </Form.Label>
                                      <Form.Control
                                        disabled
                                        value={checkDiamondsState?.clarity}
                                      />
                                    </Form.Group>
                                    <Form.Group className="col-lg-3 mb-3">
                                      <Form.Label className="mb-1">
                                        Diamond Carat
                                      </Form.Label>
                                      <Form.Control
                                        disabled
                                        value={checkDiamondsState?.carat}
                                      />
                                    </Form.Group>
                                  </div>
                                  <div className="d-flex justify-content-center mb-5">
                                    <Form.Group className="">
                                      <Form.Label className="mb-1">
                                        Diamond Price
                                      </Form.Label>
                                      <Form.Control
                                        disabled
                                        value={checkDiamondsState?.price}
                                      />
                                    </Form.Group>
                                    <div className="mx-3">
                                      <Form.Label className="mb-1 invisible">
                                        *
                                      </Form.Label>
                                      <h3 className="mt-2">*</h3>
                                    </div>

                                    <Form.Group className="">
                                      <Form.Label className="mb-1">
                                        Diamond Quantity
                                      </Form.Label>
                                      <Form.Control
                                        type="number"
                                        value={checkDiamondsState?.quantity}
                                        name="quantity"
                                        placeholder="Enter quantity"
                                        onChange={handleChange}
                                        className={classNames({
                                          requireField: errors.quantity,
                                        })}
                                      />
                                    </Form.Group>
                                    <div className="mx-3">
                                      <Form.Label className="mb-1 invisible">
                                        *
                                      </Form.Label>
                                      <h3 className="mt-1 mb-0">=</h3>
                                    </div>

                                    <div>
                                      <Form.Group className="">
                                        <Form.Label className="mb-1">
                                          Diamond Price
                                        </Form.Label>
                                        <Form.Control
                                          name="total"
                                          disabled
                                          value={
                                            checkDiamondsState.quantity === ""
                                              ? checkDiamondsState?.price
                                              : checkDiamondsState?.total
                                          }
                                        />
                                      </Form.Group>
                                    </div>
                                  </div>
                                  {token ? (
                                    <>
                                      {/* <div className="">
                                <div className="mb-2">User ID</div>
                                <input
                                  placeholder="Enter UserID"
                                  name="userID"
                                  // value={checkDiamondsState.name}
                                  type="text"
                                  onChange={handleChange}
                                />
                              </div> */}
                                    </>
                                  ) : (
                                    <>
                                      <div className="d-flex justify-content-center mb-5">
                                        <div className="me-3">
                                          <div className="mb-2">Name</div>
                                          <Form.Control
                                            placeholder="Enter Name"
                                            name="name"
                                            value={checkDiamondsState.name}
                                            className={classNames({
                                              requireField: errors.name,
                                            })}
                                            type="text"
                                            onChange={handleChange}
                                          />
                                        </div>

                                        <div className="me-3">
                                          <div className="mb-2">Email</div>
                                          <Form.Control
                                            placeholder="Enter Email"
                                            name="email"
                                            value={checkDiamondsState.email}
                                            className={classNames({
                                              requireField: errors.email,
                                            })}
                                            type="text"
                                            onChange={handleChange}
                                          />
                                        </div>
                                        <div className="me-3">
                                          <div className="mb-2">Mobile No.</div>
                                          <Form.Control
                                            placeholder="Enter Number"
                                            name="number"
                                            value={checkDiamondsState.number}
                                            className={classNames({
                                              requireField: errors.number,
                                            })}
                                            type="number"
                                            onChange={handleChange}
                                          />
                                        </div>
                                      </div>
                                    </>
                                  )}
                                  <div className="d-flex justify-content-center">
                                    <button
                                      className="talk_btn w-50 btn-next"
                                      type="submit"
                                      onClick={handleSubmit}
                                    >
                                      <span className="pe-2">Submit</span>
                                      {/* <Icon
                                    icon="bi:arrow-right-circle"
                                    color="white"
                                  /> */}
                                    </button>
                                    {/* <button
                                        type="reset"
                                        className="btn btn-secondary text-white me-2 d-inline-block ms-4"
                                        onClick={() => {
                                          setShowForm(false);
                                          setcheckDiamondsState(
                                            initialDiamonsState
                                          );
                                          setSteps(initialSteps);
                                          setErrors(initialError);
                                        }}
                                      >
                                        <i className="fa-solid fa-arrow-rotate-right"></i>
                                      </button> */}
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}

                      {/* ...................................................  fancy Diamond Section  ............................................. */}

                      {steps.step2 && (
                        <>
                          {checkDiamondsState.diamondType === "2" && (
                            <>
                              <div className="row gx-2">
                                <Form.Group className="col-lg-3 mb-3">
                                  <Form.Label className="mb-1">
                                    Diamond type
                                  </Form.Label>
                                  <Form.Control
                                    disabled
                                    value={
                                      checkDiamondsState?.diamondType === "1"
                                        ? "Round"
                                        : "Fancy Layout"
                                    }
                                  />
                                </Form.Group>
                              </div>
                              <Form.Label className="mb-1">
                                Diamond Shape
                              </Form.Label>
                              <ul className="search-items-list mb-5">
                                {fancyData?.map((v, i) => {
                                  return (
                                    <button
                                      type="radio"
                                      name="shape"
                                      value={v?.shape}
                                      cursor="pointer"
                                      onClick={handleChange}
                                      className={classNames(
                                        "btn me-3 mt-1 starmelee_checkbox",
                                        {
                                          "btn-primary text-light":
                                            v.shape ===
                                            checkDiamondsState.shape,
                                          // "Oval",
                                        }
                                      )}
                                      style={{
                                        fontWeight: "600",
                                        border:
                                          v.shape !==
                                            checkDiamondsState.shape &&
                                          "2px solid #C2B362",
                                        color:
                                          v.shape !==
                                            checkDiamondsState.shape &&
                                          "#C2B362",
                                      }}
                                    >
                                      {v.shape}
                                    </button>
                                  );
                                })}
                              </ul>
                              <div className="d-flex justify-content-between">
                                <button
                                  type="button"
                                  className="btn btn-back"
                                  onClick={step2Back}
                                >
                                  <Icon icon="bi:arrow-left" className="me-3" />
                                  Back
                                </button>
                                <button
                                  className="talk_btn btn-next"
                                  type="submit"
                                  onClick={shapeCheck}
                                >
                                  <span className="pe-2">Next</span>
                                  <Icon
                                    icon="bi:arrow-right-circle"
                                    color="white"
                                  />
                                </button>
                                {/* <button
                                      type="reset"
                                      className="btn btn-secondary text-white me-2 d-inline-block ms-4"
                                      onClick={() => {
                                        setShowForm(false);
                                        setcheckDiamondsState(
                                          initialDiamonsState
                                        );
                                        setSteps(initialSteps);
                                        setErrors(initialError);
                                      }}
                                    >
                                      <i className="fa-solid fa-arrow-rotate-right"></i>
                                    </button> */}
                              </div>
                            </>
                          )}
                        </>
                      )}
                      {steps.step3 && (
                        <>
                          {checkDiamondsState.diamondType === "2" && (
                            <>
                              <div className="row gx-2">
                                <Form.Group className="col-lg-3 mb-3">
                                  <Form.Label className="mb-2">
                                    Diamond type
                                  </Form.Label>
                                  <Form.Control
                                    disabled
                                    value={
                                      checkDiamondsState?.diamondType === "1"
                                        ? "Round"
                                        : "Fancy Layout"
                                    }
                                  />
                                </Form.Group>
                                <Form.Group className="col-lg-3 mb-3">
                                  <Form.Label className="mb-2">
                                    Diamond Shape
                                  </Form.Label>
                                  <Form.Control
                                    disabled
                                    value={checkDiamondsState?.shape}
                                  />
                                </Form.Group>
                              </div>

                              <Form.Label className="mb-1">
                                {" "}
                                Diamond Color{" "}
                              </Form.Label>
                              <ul className="search-items-list mb-5">
                                <button
                                  type="radio"
                                  name="DEF"
                                  cursor="pointer"
                                  onClick={handleChange}
                                  className={classNames(
                                    "btn me-3 starmelee_checkbox",
                                    {
                                      "btn-primary text-light":
                                        checkDiamondsState.color === "DEF",
                                    }
                                  )}
                                  style={{
                                    fontWeight: "600",
                                    border:
                                      checkDiamondsState.color !== "DEF" &&
                                      "2px solid #C2B362",
                                    color:
                                      checkDiamondsState.color !== "DEF" &&
                                      "#C2B362",
                                  }}
                                >
                                  DEF
                                </button>
                                <button
                                  type="radio"
                                  name="FG"
                                  cursor="pointer"
                                  // onClick={() => {
                                  //   setcheckDiamondsState({
                                  //     color: "FG",
                                  //   });
                                  // }}
                                  onClick={handleChange}
                                  className={classNames(
                                    "btn me-3 starmelee_checkbox",
                                    {
                                      "btn-primary text-light":
                                        checkDiamondsState.color === "FG",
                                    }
                                  )}
                                  style={{
                                    fontWeight: "600",
                                    border:
                                      checkDiamondsState.color !== "FG" &&
                                      "2px solid #C2B362",
                                    color:
                                      checkDiamondsState.color !== "FG" &&
                                      "#C2B362",
                                  }}
                                >
                                  FG
                                </button>
                                <button
                                  type="radio"
                                  name="pink"
                                  cursor="pointer"
                                  // onClick={() => {
                                  //   setcheckDiamondsState({
                                  //     color: "pink",
                                  //   });
                                  // }}
                                  onClick={handleChange}
                                  className={classNames(
                                    "btn me-3 starmelee_checkbox",
                                    {
                                      "btn-primary text-light":
                                        checkDiamondsState.color === "pink",
                                    }
                                  )}
                                  style={{
                                    fontWeight: "600",
                                    border:
                                      checkDiamondsState.color !== "pink" &&
                                      "2px solid #C2B362",
                                    color:
                                      checkDiamondsState.color !== "pink" &&
                                      "#C2B362",
                                  }}
                                >
                                  Pink
                                </button>
                                <button
                                  type="radio"
                                  name="yellow"
                                  cursor="pointer"
                                  // onClick={() => {
                                  //   setcheckDiamondsState({
                                  //     color: "yellow",
                                  //   });
                                  // }}
                                  onClick={handleChange}
                                  className={classNames(
                                    "btn me-3 starmelee_checkbox",
                                    {
                                      "btn-primary text-light":
                                        checkDiamondsState.color === "yellow",
                                    }
                                  )}
                                  style={{
                                    fontWeight: "600",
                                    border:
                                      checkDiamondsState.color !== "yellow" &&
                                      "2px solid #C2B362",
                                    color:
                                      checkDiamondsState.color !== "yellow" &&
                                      "#C2B362",
                                  }}
                                >
                                  yellow
                                </button>
                                <button
                                  type="radio"
                                  name="blue"
                                  cursor="pointer"
                                  // onClick={() => {
                                  //   setcheckDiamondsState({
                                  //     color: "blue",
                                  //   });
                                  // }}
                                  onClick={handleChange}
                                  className={classNames(
                                    "btn me-3 starmelee_checkbox",
                                    {
                                      "btn-primary text-light":
                                        checkDiamondsState.color === "blue",
                                    }
                                  )}
                                  style={{
                                    fontWeight: "600",
                                    border:
                                      checkDiamondsState.color !== "blue" &&
                                      "2px solid #C2B362",
                                    color:
                                      checkDiamondsState.color !== "blue" &&
                                      "#C2B362",
                                  }}
                                >
                                  Blue
                                </button>
                              </ul>
                              <div className="d-flex justify-content-between">
                                <button
                                  type="button"
                                  className="btn btn-back"
                                  onClick={step3Back}
                                >
                                  <Icon icon="bi:arrow-left" className="me-3" />
                                  Back
                                </button>
                                <button
                                  className="talk_btn btn-next"
                                  type="submit"
                                  onClick={fancy_colorCheck}
                                >
                                  <span className="pe-2">Next</span>
                                  <Icon
                                    icon="bi:arrow-right-circle"
                                    color="white"
                                  />
                                </button>
                                {/* <button
                                      type="reset"
                                      className="btn btn-secondary text-white me-2 d-inline-block ms-4"
                                      onClick={() => {
                                        setShowForm(false);
                                        setcheckDiamondsState(
                                          initialDiamonsState
                                        );
                                        setSteps(initialSteps);
                                        setErrors(initialError);
                                      }}
                                    >
                                      <i className="fa-solid fa-arrow-rotate-right"></i>
                                    </button> */}
                              </div>
                            </>
                          )}
                        </>
                      )}
                      {steps.step4 && (
                        <>
                          {checkDiamondsState.diamondType === "2" && (
                            <>
                              <div className="row gx-2">
                                <Form.Group className="col-lg-3 mb-3">
                                  <Form.Label className="mb-2">
                                    Diamond type
                                  </Form.Label>
                                  <Form.Control
                                    disabled
                                    value={
                                      checkDiamondsState?.diamondType === "1"
                                        ? "Round"
                                        : "Fancy Layout"
                                    }
                                  />
                                </Form.Group>
                                <Form.Group className="col-lg-3 mb-3">
                                  <Form.Label className="mb-2">
                                    Diamond Shape
                                  </Form.Label>
                                  <Form.Control
                                    disabled
                                    value={checkDiamondsState?.shape}
                                  />
                                </Form.Group>
                                <Form.Group className="col-lg-3 mb-3">
                                  <Form.Label className="mb-2">
                                    Diamond Color
                                  </Form.Label>
                                  <Form.Control
                                    disabled
                                    value={checkDiamondsState?.color}
                                  />
                                </Form.Group>
                              </div>
                              <Form.Group>
                                <Form.Label className="mb-2">
                                  Diamond Clarity
                                </Form.Label>
                                <Form.Select
                                  name="clarity"
                                  // className="mb-5 mt-5 starmelee-formselect"
                                  onClick={handleChange}
                                  className={classNames(
                                    "mb-5 starmelee-formselect",
                                    {
                                      requireField: errors.clarity,
                                    }
                                  )}
                                >
                                  <>
                                    {checkDiamondsState.color === "DEF" && (
                                      <>
                                        <option value="" name="">
                                          Select Clarity
                                        </option>
                                        <option value="vvs_vs" name="vvs_vs">
                                          VVS_VS
                                        </option>
                                        <option value="vs_si" name="vs_si">
                                          VS_SI
                                        </option>
                                      </>
                                    )}
                                    {checkDiamondsState.color === "FG" && (
                                      <>
                                        <option
                                          value="vvs_vs"
                                          name="vvs_vs"
                                          disabled
                                        >
                                          Select Clarity
                                        </option>
                                        <option value="vvs_vs" name="vvs_vs">
                                          VVS_VS
                                        </option>
                                        <option value="vs_si" name="vs_si">
                                          VS_SI
                                        </option>
                                      </>
                                    )}

                                    {checkDiamondsState.color === "pink" && (
                                      <>
                                        <option
                                          value="vvs_vs"
                                          name="vvs_vs"
                                          disabled
                                        >
                                          Select Clarity
                                        </option>
                                        <option
                                          value="vvs_vs_si1"
                                          name="vvs_vs_si1"
                                        >
                                          VVS-VS-SI1
                                        </option>
                                      </>
                                    )}
                                    {checkDiamondsState.color === "yellow" && (
                                      <>
                                        <option value="vvs_vs" name="vvs_vs">
                                          Select Clarity
                                        </option>
                                        <option
                                          value="vvs_vs_si1"
                                          name="vvs_vs_si1"
                                        >
                                          VVS-VS-SI1
                                        </option>
                                      </>
                                    )}
                                    {checkDiamondsState.color === "blue" && (
                                      <>
                                        <option
                                          value="vvs_vs"
                                          name="vvs_vs"
                                          disabled
                                        >
                                          Select Clarity
                                        </option>
                                        <option
                                          value="vvs_vs_si1"
                                          name="vvs_vs_si1"
                                        >
                                          VVS-VS-SI1
                                        </option>
                                      </>
                                    )}
                                  </>
                                </Form.Select>
                              </Form.Group>
                              <div className="d-flex justify-content-between">
                                <button
                                  type="button"
                                  className="btn btn-back"
                                  onClick={step4Back}
                                >
                                  <Icon icon="bi:arrow-left" className="me-3" />
                                  Back
                                </button>
                                <button
                                  className="talk_btn btn-next "
                                  type="submit"
                                  onClick={fancy_clarityCheck}
                                >
                                  <span className="pe-2">Next</span>
                                  <Icon
                                    icon="bi:arrow-right-circle"
                                    color="white"
                                  />
                                </button>
                                {/* <button
                                    type="reset"
                                    className="btn btn-secondary text-white me-2 d-inline-block ms-4"
                                    onClick={() => {
                                      setShowForm(false);
                                      setcheckDiamondsState(
                                        initialDiamonsState
                                      );
                                      setSteps(initialSteps);
                                      setErrors(initialError);
                                    }}
                                  >
                                    <i className="fa-solid fa-arrow-rotate-right"></i>
                                  </button> */}
                              </div>
                            </>
                          )}
                        </>
                      )}
                      {steps.step5 && (
                        <>
                          {checkDiamondsState.diamondType === "2" && (
                            <>
                              <div className="row gx-2">
                                <Form.Group className="col-lg-3 mb-3">
                                  <Form.Label className="mb-1">
                                    Diamond type
                                  </Form.Label>
                                  <Form.Control
                                    disabled
                                    value={
                                      checkDiamondsState?.diamondType === "1"
                                        ? "Round"
                                        : "Fancy Layout"
                                    }
                                  />
                                </Form.Group>
                                <Form.Group className="col-lg-3 mb-3">
                                  <Form.Label className="mb-1">
                                    Diamond Shape
                                  </Form.Label>
                                  <Form.Control
                                    disabled
                                    value={checkDiamondsState?.shape}
                                  />
                                </Form.Group>
                                <Form.Group className="col-lg-3 mb-3">
                                  <Form.Label className="mb-1">
                                    Diamond Color
                                  </Form.Label>
                                  <Form.Control
                                    disabled
                                    value={checkDiamondsState?.color}
                                  />
                                </Form.Group>
                                <Form.Group className="col-lg-3 mb-3">
                                  <Form.Label className="mb-1">
                                    Diamond Clarity
                                  </Form.Label>
                                  <Form.Control
                                    disabled
                                    value={checkDiamondsState?.clarity}
                                  />
                                </Form.Group>
                              </div>
                              <Form.Group className="">
                                <Form.Label className="mb-1">
                                  Diamond Carat
                                </Form.Label>
                                <Form.Select
                                  name="carat"
                                  onChange={handleChange}
                                  className={classNames(
                                    "mb-5 starmelee-formselect",
                                    {
                                      requireField: errors.carat,
                                    }
                                  )}
                                >
                                  <option value="">Select carat</option>
                                  {data &&
                                    data?.map((v, i) => {
                                      return (
                                        <>
                                          <option value={i}>
                                            {v?.size} - {v?.sieve} - {v?.carat}
                                          </option>
                                        </>
                                      );
                                    })}
                                </Form.Select>
                              </Form.Group>
                              <div className="d-flex justify-content-between">
                                <button
                                  type="button"
                                  className="btn btn-back"
                                  onClick={step5Back}
                                >
                                  <Icon icon="bi:arrow-left" className="me-3" />
                                  Back
                                </button>
                                <button
                                  className="talk_btn btn-next"
                                  type="submit"
                                  onClick={fancy_caratCheck}
                                >
                                  <span className="pe-2">Next</span>
                                  <Icon
                                    icon="bi:arrow-right-circle"
                                    color="white"
                                  />
                                </button>
                                {/* <button
                                    type="reset"
                                    className="btn btn-secondary text-white me-2 d-inline-block ms-4"
                                    onClick={() => {
                                      setShowForm(false);
                                      setcheckDiamondsState(
                                        initialDiamonsState
                                      );
                                      setSteps(initialSteps);
                                      setErrors(initialError);
                                    }}
                                  >
                                    <i className="fa-solid fa-arrow-rotate-right"></i>
                                  </button> */}
                              </div>
                            </>
                          )}
                        </>
                      )}
                      {steps.step6 && (
                        <>
                          {checkDiamondsState.diamondType === "2" && (
                            <>
                              {props.starmeleeInquairyReducer?.success ? (
                                <div
                                  className="d-flex justify-content-center text-success"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "20px",
                                  }}
                                >
                                  Inquairy Send Successfully...
                                </div>
                              ) : (
                                <>
                                  <div className="row gx-2">
                                    <Form.Group className="col-lg-3 mb-3">
                                      <Form.Label className="mb-2">
                                        Diamond type
                                      </Form.Label>
                                      <Form.Control
                                        disabled
                                        value={
                                          checkDiamondsState?.diamondType ===
                                          "1"
                                            ? "Round"
                                            : "Fancy Layout"
                                        }
                                      />
                                    </Form.Group>
                                    <Form.Group className="col-lg-3 mb-3">
                                      <Form.Label className="mb-2">
                                        Diamond Shape
                                      </Form.Label>
                                      <Form.Control
                                        disabled
                                        value={checkDiamondsState?.shape}
                                      />
                                    </Form.Group>
                                    <Form.Group className="col-lg-3 mb-3">
                                      <Form.Label className="mb-2">
                                        Diamond Color
                                      </Form.Label>
                                      <Form.Control
                                        disabled
                                        value={checkDiamondsState?.color}
                                      />
                                    </Form.Group>
                                    <Form.Group className="col-lg-3 mb-3">
                                      <Form.Label className="mb-2">
                                        Diamond Clarity
                                      </Form.Label>
                                      <Form.Control
                                        disabled
                                        value={checkDiamondsState?.clarity}
                                      />
                                    </Form.Group>
                                    <Form.Group className="col-lg-3 mb-3">
                                      <Form.Label className="mb-2">
                                        Diamond Carat
                                      </Form.Label>
                                      <Form.Control
                                        disabled
                                        value={checkDiamondsState?.carat}
                                      />
                                    </Form.Group>
                                  </div>
                                  <div className="d-flex justify-content-center mb-5">
                                    <Form.Group className="">
                                      <Form.Label className="mb-1">
                                        Price
                                      </Form.Label>
                                      <Form.Control
                                        disabled
                                        value={checkDiamondsState?.price}
                                      />
                                    </Form.Group>
                                    <div className="mx-3">
                                      <Form.Label className="mb-1 invisible">
                                        *
                                      </Form.Label>
                                      <h3 className="mt-2">*</h3>
                                    </div>
                                    <Form.Group className="">
                                      <Form.Label className="mb-1">
                                        Diamond Quantity
                                      </Form.Label>
                                      <Form.Control
                                        type="number"
                                        value={checkDiamondsState?.quantity}
                                        name="quantity"
                                        placeholder="Enter quantity"
                                        onChange={handleChange}
                                        className={classNames(
                                          "mb-5 starmelee-formselect",
                                          {
                                            requireField: errors.quantity,
                                          }
                                        )}
                                      />
                                    </Form.Group>
                                    <div className="mx-3">
                                      <Form.Label className="mb-1 invisible">
                                        =
                                      </Form.Label>
                                      <h3 className="mt-1">=</h3>
                                    </div>

                                    <div className="">
                                      <Form.Group className="">
                                        <Form.Label className="mb-1">
                                          Total
                                        </Form.Label>
                                        <Form.Control
                                          name="total"
                                          disabled
                                          value={
                                            checkDiamondsState.quantity === ""
                                              ? checkDiamondsState?.price
                                              : checkDiamondsState?.total
                                          }
                                        />
                                      </Form.Group>
                                    </div>
                                  </div>
                                  {token ? (
                                    <>
                                      {/* <div className="">
                              <div className="mb-2">User ID</div>
                              <input
                                placeholder="Enter UserID"
                                name="userID"
                                // value={checkDiamondsState.name}
                                type="text"
                                onChange={handleChange}
                              />
                            </div> */}
                                    </>
                                  ) : (
                                    <>
                                      <div className="d-flex justify-content-center mb-5">
                                        <div className="me-3">
                                          <div className="mb-2">Name</div>
                                          <Form.Control
                                            placeholder="Enter Name"
                                            name="name"
                                            value={checkDiamondsState.name}
                                            type="text"
                                            onChange={handleChange}
                                            className={classNames({
                                              requireField: errors.name,
                                            })}
                                          />
                                        </div>

                                        <div className="me-3">
                                          <div className="mb-2">Email</div>
                                          <Form.Control
                                            placeholder="Enter Email"
                                            name="email"
                                            value={checkDiamondsState.email}
                                            type="text"
                                            onChange={handleChange}
                                            className={classNames({
                                              requireField: errors.email,
                                            })}
                                          />
                                        </div>
                                        <div className="me-3">
                                          <div className="mb-2">Mobile No.</div>
                                          <Form.Control
                                            placeholder="Enter Number"
                                            name="number"
                                            value={checkDiamondsState.number}
                                            type="number"
                                            onChange={handleChange}
                                            className={classNames({
                                              requireField: errors.number,
                                            })}
                                          />
                                        </div>
                                      </div>
                                    </>
                                  )}
                                  <div className="d-flex justify-content-center">
                                    <button
                                      className="talk_btn w-50 btn-next"
                                      type="submit"
                                      onClick={handleSubmit}
                                    >
                                      <span className="pe-2">Submit</span>
                                      {/* <Icon
                              icon="bi:arrow-right-circle"
                              color="white"
                            /> */}
                                    </button>
                                    {/* <button
                                        type="reset"
                                        className="btn btn-secondary text-white me-2 d-inline-block ms-4"
                                        onClick={() => {
                                          setShowForm(false);
                                          setcheckDiamondsState(
                                            initialDiamonsState
                                          );
                                          setSteps(initialSteps);
                                          setErrors(initialError);
                                        }}
                                      >
                                        <i className="fa-solid fa-arrow-rotate-right"></i>
                                      </button> */}
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </form>
                    {/* <div className="steps">
                  <ProgressBar now={now} label={`${now}%`} visuallyHidden />
                  <span className="ms-4 d-inline-block">{now}%</span>
                </div> */}
                  </div>
                </Container>
              </div>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-end mt-3">
                <button
                  className="talk_btn"
                  type="button"
                  onClick={changeFormState}
                >
                  <span className="pe-2">Let’s Get Inquairy</span>
                  <Icon icon="bi:arrow-right-circle" color="white" />
                </button>
              </div>
            </>
          )}
          <div className="content-block mt-5 mb-5">
            <Table responsive="sm">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Shape</th>
                  <th>color</th>
                  <th>clarity</th>
                  <th>carat</th>
                  <th>price</th>
                  <th>Quantity</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {inquairyListing?.map((v, i) => {
                  return (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{v?.shape ? v?.shape : "N/A"}</td>
                      <td>{v?.color ? v?.color : "N/A"}</td>
                      <td>{v?.clarity ? v?.clarity : "N/A"}</td>
                      <td>{v?.carat ? v?.carat : "N/A"}</td>
                      <td>{v?.price ? v?.price : "N/A"}</td>
                      <td>{v?.qty ? v?.qty : "N/A"}</td>
                      <td>{v?.price ? v?.price * v?.qty : "N/A"}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </>
      </main>
    </div>
  );
}
const mapStateToProps = (state) => ({
  // getDashboardDetailsReducer: state.masterReducer.getDashboardDetails,
  // masterListReducer: state.masterReducer.masterList,
  profileReducer: state.authReducer.profile,
  // checkdiamondstypeReducer: state.authReducer.checkDiamondsType,
  starmeleeReducer: state.starmeleeReducer.starmeleeDiamondList,
  starmeleeCaratReducer: state.starmeleeReducer.starmeleeCaratList,
  // starmeleePriceReducer: state.starmeleeReducer.starmeleePriceList,
  starmeleeInquairyReducer: state.starmeleeReducer.starmeleeInquairyList,
  starmeleeInquairyListReducer: state.starmeleeReducer.getstarmeleeInquairyList,
});
const mapDispatchToProps = (dispatch) => ({
  // getDashboardDetails: (details) => dispatch(getDashboardDetails(details)),
  getstarmeleeDiamondAction: () => dispatch(getstarmeleeDiamondAction()),
  getProfile: () => dispatch(getProfile()),
  // getMastersList: (payload) => dispatch(getMastersList(payload)),
  // checkDiamondsTypeAction: (details) =>
  //   dispatch(checkDiamondsTypeAction(details)),
  starmeleeinquairyListAction: () => dispatch(starmeleeinquairyListAction()),
  starmeleecaratAction: (details) => dispatch(starmeleecaratAction(details)),
  starmeleepriceAction: (details) => dispatch(starmeleepriceAction(details)),
  // ResetstarmeleeReducer: () => dispatch({ type: ADD_STARMELEE_INQUAIRY_RESET }),
  starmeleeinquairyAction: (details) =>
    dispatch(starmeleeinquairyAction(details)),
  // resetcheckDiamondsTypeAction: () =>
  //   dispatch({ type: CHECK_DIAMONDS_TYPE_RESET }),
  // resetInquiry: () => dispatch({ type: ADD_STARMELEE_INQUAIRY_RESET }),
});

export default connect(mapStateToProps, mapDispatchToProps)(StarMeleeTable);
// 3043
