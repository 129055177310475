import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { changePassword } from "../userStore/action/authAction";
import { CHANGE_PASSWORD_RESET } from "../userStore/common/constant";
import Footer from "./userLayout/Footer";
import Header from "./userLayout/Header";

const ChangePassword = (props) => {
  const navigate = useNavigate();
  const [isPassword, setIsPassword] = useState(false);
  const [isNewPassword, setIsNewPassword] = useState(false);
  const [isConPassword, setIsConPassword] = useState(false);
  const [state, setState] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });
  const [error, setError] = useState({
    old_password: false,
    new_password: false,
    confirm_password: false,
  });

  const handleInput = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    switch (name) {
      case "old_password":
        if (value === "") {
          error["old_password"] = "Required";
        } else {
          error["old_password"] = false;
        }
        break;

      case "new_password":
        if (value === "") {
          error["new_password"] = "Required";
        } else {
          error["new_password"] = false;
        }
        break;
      case "confirm_password":
        if (value === "") {
          error["confirm_password"] = "Required";
        } else if (value !== state.new_password) {
          error["confirm_password"] = "Password did not matched";
        } else {
          error["confirm_password"] = false;
        }
        break;
      default:
        break;
    }
  };

  const handleChangePassWord = (e) => {
    e.preventDefault();
    if (state.old_password === "") {
      setError((pre) => ({
        ...pre,
        old_password: "Required",
      }));
    } else if (state.new_password === "") {
      setError((pre) => ({
        ...pre,
        new_password: "Required",
      }));
    } else if (state.confirm_password === "") {
      setError((pre) => ({
        ...pre,
        confirm_password: "Required",
      }));
    }

    if (
      state.old_password !== "" &&
      state.new_password !== "" &&
      state.confirm_password !== "" &&
      state.new_password === state.confirm_password
    ) {
      props.changePassword({
        old_password: state.old_password,
        new_password: state.new_password,
      });
    }
  };
  useEffect(() => {
    if (props.changePassReducer.success) {
      navigate("/dashboard");
      toast.success("Password Changed successfully..");
      props.resetChangePass();
    } else if (props.changePassReducer.error) {
      toast.error(props.changePassReducer.errors);
      setError((prev) => ({
        ...prev,
        old_password: props.changePassReducer.errors,
      }));
      props.resetChangePass();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.changePassReducer]);
  return (
    <>
      <Header />
      <main className="userMain pt-5">
        <div className="d-flex justify-content-between   mb-4 flex-wrap">
          <h2 className="heading mb-0">Change Password</h2>
        </div>
        <div className="content-block mb-5">
          <div className="col-md-4">
            <Form onSubmit={handleChangePassWord}>
              <Form.Group className="mb-3">
                <Form.Label className="mb-0">Old Password</Form.Label>
                <div className="position-relative">
                  <Form.Control
                    type={isPassword ? "text" : "password"}
                    placeholder="Old Password"
                    name="old_password"
                    value={state.old_password}
                    onChange={handleInput}
                    style={{ paddingRight: "48px" }}
                  />
                  <i
                    className={`eye-btn position-absolute fa-regular ${
                      isPassword ? "fa-eye-slash" : "fa-regular fa-eye"
                    }`}
                    onClick={() => setIsPassword(!isPassword)}
                    style={{ cursor: "pointer", top: "12px", right: "20px" }}
                  ></i>
                </div>
                {error.old_password && (
                  <span className="form-error">{error.old_password}</span>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="mb-0">New Password</Form.Label>
                <div className="position-relative">
                  <Form.Control
                    type={isNewPassword ? "text" : "password"}
                    placeholder="New Password"
                    name="new_password"
                    value={state.new_password}
                    onChange={handleInput}
                    style={{ paddingRight: "48px" }}
                  />
                  <i
                    className={`eye-btn position-absolute fa-regular ${
                      isNewPassword ? "fa-eye-slash" : "fa-regular fa-eye"
                    }`}
                    onClick={() => setIsNewPassword(!isNewPassword)}
                    style={{ cursor: "pointer", top: "12px", right: "20px" }}
                  ></i>
                </div>
                {error.new_password && (
                  <span className="form-error">{error.new_password}</span>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="mb-0">Confirm Password</Form.Label>

                <div className="position-relative">
                  <Form.Control
                    type={isConPassword ? "text" : "password"}
                    placeholder="Confirm Password"
                    name="confirm_password"
                    value={state.confirm_password}
                    onChange={handleInput}
                  />
                  <i
                    className={`eye-btn position-absolute fa-regular ${
                      isConPassword ? "fa-eye-slash" : "fa-regular fa-eye"
                    }`}
                    onClick={() => setIsConPassword(!isConPassword)}
                    style={{ cursor: "pointer", top: "12px", right: "20px" }}
                  ></i>
                </div>
                {error.confirm_password && (
                  <span className="form-error">{error.confirm_password}</span>
                )}
              </Form.Group>
              <Button type="submit" className="mt-5 text-white">
                Change Password
              </Button>
            </Form>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => ({
  changePassReducer: state.authReducer.changePass,
});
const mapDispatchToProps = (dispatch) => ({
  changePassword: (details) => dispatch(changePassword(details)),
  resetChangePass: () => dispatch({ type: CHANGE_PASSWORD_RESET }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
