import React from "react";
import { Container } from "react-bootstrap";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";

const Manufacture = () => {
      return(
            <>
                  <Header/>
                  <Container fluid className="banner">
                        <Container>
                              <h2 className="pageName">Our Manufacture</h2>
                        </Container>
                  </Container>

                  <main>
                        <section className="container">
                              <h2 className="heading mb-4">Manufacturing Setup</h2>
                              <p>We take the utmost pride in the infrastructure unit that we managed to build which is avant-garde and technically equipped. We have over 1000 personnel working with us constantly keep providing diamonds and keeping up with the demands. With a high dependency on cutting-edge technology, we invest in the best of machines which strengthens our infrastructure. The laser cutting and polishing machines are of the latest standards that comply with presentday requirements.</p>
                              <p>We follow an extremely well-curated process which ensures that the diamonds remain unharmed and perfect. Following standard guidelines and proper techniques, our manufacturing process consists of innovation, accurate craftsmanship and sustainable technology. This is one of the main areas where Delight Diamonds stands apart from the rest of their competition.</p>
                              <p>The entire process of making diamonds is done in-house where the accurate environment is created for their formation and everlasting diamonds.</p>
                        </section>
                  </main>
                  <Footer />
            </>
      )
}

export default Manufacture;