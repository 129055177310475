import { ADD_DEMAND_LIST_ERROR, ADD_DEMAND_LIST_RESET, ADD_DEMAND_LIST_SUCCESS, DELETE_DEMAND_LIST_ERROR, DELETE_DEMAND_LIST_RESET, DELETE_DEMAND_LIST_SUCCESS, GET_DEMAND_LIST_ERROR, GET_DEMAND_LIST_RESET, GET_DEMAND_LIST_SUCCESS } from "../common/constant";

let initialState = {
  demandList: {
    error: false,
    data: null,
    errors: null,
    success: false,
  },
  addDemand: {
    error: false,
    errors: null,
    success: false,
  },
  deleteDemand: {
    error: false,
    errors: null,
    success: false,
  }
};

export default function demandReducer(state = initialState, action) {
  switch (action.type) {
    case GET_DEMAND_LIST_SUCCESS:
      return { ...state, demandList: { ...state.demandList, success: true, error: false, errors: null, data: action.response }, };

    case GET_DEMAND_LIST_ERROR:
      return { ...state, demandList: { ...state.demandList, success: false, error: true, errors: action.msg } };

    case GET_DEMAND_LIST_RESET:
      return { ...state, demandList: { ...state.demandList, success: false, error: false, errors: null } };

    //  add

    case ADD_DEMAND_LIST_SUCCESS:
      return { ...state, addDemand: { ...state.addDemand, success: true, error: false, errors: null }, };

    case ADD_DEMAND_LIST_ERROR:
      return { ...state, addDemand: { ...state.addDemand, success: false, error: true, errors: action.msg } };

    case ADD_DEMAND_LIST_RESET:
      return { ...state, addDemand: { ...state.addDemand, success: false, error: false, errors: null } };



    //  delete

    case DELETE_DEMAND_LIST_SUCCESS:
      return { ...state, deleteDemand: { ...state.deleteDemand, success: true, error: false, errors: null }, };

    case DELETE_DEMAND_LIST_ERROR:
      return { ...state, deleteDemand: { ...state.deleteDemand, success: false, error: true, errors: action.msg } };

    case DELETE_DEMAND_LIST_RESET:
      return { ...state, deleteDemand: { ...state.deleteDemand, success: false, error: false, errors: null } };

    default:
      return state;
  }
}