import { EXPORT_EXCEL_ERROR, EXPORT_EXCEL_RESET, EXPORT_EXCEL_SUCCESS, EXPORT_PDF_ERROR, EXPORT_PDF_RESET, EXPORT_PDF_SUCCESS } from "../common/constant";

let initialState = {
 exportPdf: {
  error: false,
  errors: null,
  success: false,
 },
 exportExcel: {
  error: false,
  errors: null,
  success: false,
 }
};

export default function exportFileReducer(state = initialState, action) {
 switch (action.type) {
  case EXPORT_PDF_SUCCESS:
   return { ...state, exportPdf: { ...state.exportPdf, success: true, error: false, errors: null}, };

  case EXPORT_PDF_ERROR:
   return { ...state, exportPdf: { ...state.exportPdf, success: false, error: true, errors: action.msg } };

  case EXPORT_PDF_RESET:
   return { ...state, exportPdf: { ...state.exportPdf, success: false, error: false, errors: null } };

  //  add

  case EXPORT_EXCEL_SUCCESS:
   return { ...state, exportExcel: { ...state.exportExcel, success: true, error: false, errors: null }, };

  case EXPORT_EXCEL_ERROR:
   return { ...state, exportExcel: { ...state.exportExcel, success: false, error: true, errors: action.msg } };

  case EXPORT_EXCEL_RESET:
   return { ...state, exportExcel: { ...state.exportExcel, success: false, error: false, errors: null } };

  default:
   return state;
 }
}