import React from 'react'
import { connect } from 'react-redux';

const ViewSearchStone = () => {
  return (
    <div>
      ViewSearchStone
    </div>
  )
}

const mapStateToProps = (state) => ({
    SerachStoneReducer: state.SerachStoneReducer.Search,
  });
  const mapDispatchToProps = (dispatch) => ({
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(ViewSearchStone);
