import React from "react";
import { Modal } from "react-bootstrap";

const PdfModal = (props) => {
  return (
    <Modal show={props.show} centered onHide={props.onHide} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Certificate</Modal.Title>
      </Modal.Header>
      {!!props.details && props.details !== "" && props.details !== "N/A" ? (
        <>
          <Modal.Body className="text-center">
            <iframe
              src={props.details}
              title="Cerificate"
              width="100%"
              height="600"
            ></iframe>
          </Modal.Body>
        </>
      ) : (
        <>
          <Modal.Body className="text-center">
            <h4 className="py-5">No Cerificate Available!!!</h4>
          </Modal.Body>
        </>
      )}
    </Modal>
  );
};

export default PdfModal;
