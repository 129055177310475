import axios from "axios";
import { addDemand_Url, deleteDemand_Url, demandList_Url } from "../common/apiSheet";
import { ADD_DEMAND_LIST_ERROR, ADD_DEMAND_LIST_SUCCESS, DELETE_DEMAND_LIST_ERROR, DELETE_DEMAND_LIST_RESET, DELETE_DEMAND_LIST_SUCCESS, GET_DEMAND_LIST_ERROR, GET_DEMAND_LIST_SUCCESS } from "../common/constant";

export const getDemandList = () => {
  return (dispatch) => {
    const requestOptions = {
      method: "GET",
      url: demandList_Url,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      }
    }
    axios(requestOptions)
      .then((resp) => {
        // localStorage.setItem('token', resp?.data.data.token)
        dispatch({
          type: GET_DEMAND_LIST_SUCCESS,
          response: resp.data.data.result,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_DEMAND_LIST_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: GET_DEMAND_LIST_ERROR,
            msg: validationError,
          });
        }
      });
  };
};

export const addDemandList = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      url: addDemand_Url,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
      data: details
    }
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: ADD_DEMAND_LIST_SUCCESS,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: ADD_DEMAND_LIST_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: ADD_DEMAND_LIST_ERROR,
            msg: validationError,
          });
        }
      });
  };
};

export const deleteDemandList = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      url: deleteDemand_Url,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
      data: details
    }
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: DELETE_DEMAND_LIST_SUCCESS,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: DELETE_DEMAND_LIST_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: DELETE_DEMAND_LIST_ERROR,
            msg: validationError,
          });
        }
      });
  };
};